import { PremiseStatus } from "@common/enums/premise.enum";
import { IdType } from "@common/types/apiTypes";
import { PremiseListItemType } from "@common/types/premiseTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { ReactComponent as ChevronDown } from "@design-system/icons/ChevronDown.svg";
import { selectedDevicePremiseAtom } from "@portal-account/components/DevicePremiseSelector/DevicePremiseSelector.atoms";
import { devicePremiseSelectorTranslations } from "@portal-account/components/DevicePremiseSelector/DevicePremiseSelector.en.i18n";
import {
  DevicePremiseSelectorWrapper,
  StyledDevicePremiseMenuItem,
} from "@portal-account/components/DevicePremiseSelector/DevicePremiseSelector.styled";
import { DevicePremiseListItem } from "@portal-account/components/DevicePremiseSelector/DevicePremiseSelectorListItem";
import {
  StyledChevronIconContainer,
  StyledPortalSelectorContainer,
  StyledPortalSelectorMenu,
  StyledPortalSelectorMenuContainer,
  StyledPortalSelectorMenuItem,
  StyledSelectedItemShown,
} from "@portal-account/components/PortalSelector/PortalSelector.styled";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { useClickOutsideViewToggle } from "@portal-account/hooks/useClickOutsideViewToggle";
import { DeviceType } from "@portal-account/types/devicesTypes";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { useAtom } from "jotai";
import React from "react";

interface DeviceSwapPremiseSelectorProps {
  device: DeviceType;
  label?: string;
  premises: PremiseListItemType[];
}

export const DeviceSwapPremiseSelector = (
  props: DeviceSwapPremiseSelectorProps
) => {
  const { premise: currentPremise } = usePremiseFromContext();
  const { device, label, premises } = props;
  const { deviceId } = device;
  const { translate } = useTranslations();
  const [currentSelectedDevicePremise, setSelectedDevicePremise] = useAtom(
    selectedDevicePremiseAtom
  );
  const { ref, isOpen, setIsOpen } =
    useClickOutsideViewToggle<HTMLDivElement>();
  const selectorId = `DeviceSwapPremiseSelector-${deviceId}`;
  const menuId = `DeviceSwapPremiseSelectorMenu-${deviceId}`;

  const { tDevicePremiseSelectorSelectAddress } = translate(
    devicePremiseSelectorTranslations
  );

  const selectedPremise = premises?.find(
    (premise) => premise.id === currentSelectedDevicePremise.premiseId
  );

  const toggleDevicePremiseSelector = () => {
    setIsOpen((prev) => !prev);
  };

  const allPremiesBesidesCurrent = premises
    .filter((prem) => prem.status !== PremiseStatus.INACTIVE)
    .filter((prem) => prem.id !== currentPremise.id);

  const handleSelectedPremise = (premiseId: IdType) => {
    setSelectedDevicePremise({ premiseId });

    return toggleDevicePremiseSelector();
  };

  return (
    <DevicePremiseSelectorWrapper>
      {label ? (
        <RhTypography variant="body1" fontWeight={FontWeight.Semibold}>
          {label}
        </RhTypography>
      ) : null}
      <StyledPortalSelectorContainer $minWidth="auto" ref={ref}>
        <StyledSelectedItemShown
          $hasMenuItems
          $minWidth="auto"
          aria-controls={menuId}
          aria-haspopup="true"
          aria-label={`Select address for ${device.deviceBrand} device ${device.deviceName}`}
          data-selected={Boolean(selectedPremise)}
          id={selectorId}
          onClick={toggleDevicePremiseSelector}
        >
          {selectedPremise ? (
            <DevicePremiseListItem premise={selectedPremise} />
          ) : (
            <div>{tDevicePremiseSelectorSelectAddress}</div>
          )}
          <StyledChevronIconContainer
            $isOpen={isOpen}
            data-testid="DevicePremiseSelector__ChevronDownIcon"
          >
            <ChevronDown height="10px" width="10px" />
          </StyledChevronIconContainer>
        </StyledSelectedItemShown>
        {isOpen ? (
          <StyledPortalSelectorMenuContainer
            id={menuId}
            aria-hidden={!isOpen}
            $minWidth="auto"
          >
            <StyledPortalSelectorMenu
              role="menu"
              aria-label={`premiseMenu-${deviceId}`}
            >
              {allPremiesBesidesCurrent.map((premise) => (
                <StyledPortalSelectorMenuItem
                  key={premise.id}
                  role="menuitem"
                  $isSelected={selectedPremise?.id === premise.id}
                >
                  <StyledDevicePremiseMenuItem
                    type="button"
                    aria-label={premise.serviceAddress?.deliveryLine1}
                    onClick={() => handleSelectedPremise(premise.id)}
                  >
                    <DevicePremiseListItem
                      premise={premise}
                      isSelectedPremise={
                        selectedPremise?.id
                          ? premise.id === selectedPremise.id
                          : false
                      }
                      isInMenu
                    />
                  </StyledDevicePremiseMenuItem>
                </StyledPortalSelectorMenuItem>
              ))}
            </StyledPortalSelectorMenu>
          </StyledPortalSelectorMenuContainer>
        ) : null}
      </StyledPortalSelectorContainer>
    </DevicePremiseSelectorWrapper>
  );
};

import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { LOGGED_OUT_FIELD_SPACING } from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout.constants";
import styled from "styled-components";

export const StyledCreatePasswordPageCaption = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${CSSVariables.COLOR_GREY_400};
    display: block;
    margin-top: ${rhSpacingPx(LOGGED_OUT_FIELD_SPACING)};
  }
`;

export const StyledCreatePasswordPurpleSection = styled.div`
  background-color: ${CSSVariables.COLOR_PRIMARY_LIGHT};
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  justify-content: center;
  margin-bottom: ${rhSpacingPx(5)};
  padding: ${rhSpacingPx(4)};
  text-align: center;
  width: 100vw;

  svg {
    height: 120px;
  }

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    flex-direction: row;
    gap: ${rhSpacingPx(5)};
    text-align: start;
  }
`;

export const StyledCreatePasswordPurpleSectionTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    width: 410px;
  }
`;

export const StyledCreatePasswordPurpleSectionSubtitle = styled(RhTypography)`
  &.MuiTypography-root {
    color: ${CSSVariables.COLOR_GREY_600};
  }
`;

export const StyledCreatePasswordPurpleSectionCustomerInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledCreatePasswordPurpleSectionText = styled(RhTypography).attrs(
  { fontWeight: "Bold", variant: "body2" }
)`
  &.MuiTypography-root {
    color: ${CSSVariables.COLOR_GREY_500};
  }
`;

import { ajax } from "@common/services/ajax";
import { PaginationEndpointResponseType } from "@common/types/apiResponseTypes";
import { IdType, UUIDType } from "@common/types/apiTypes";
import type {
  ClaimOptionType,
  CreateProductContractResponse,
  ProductContractCancelRequestType,
  ProductContractClaimOptionsRequestType,
  ProductContractClaimRequestType,
  ProductContractType,
} from "@portal-account/types/productContractTypes";
import { ProductContractStatusFilter } from "@portal-account/types/productContractTypes";

interface ProductContractListQueryParams {
  contractIds?: UUIDType[];
  status: ProductContractStatusFilter;
}

const basePath = (premiseId: IdType) => {
  return `/api/portal/premises/${premiseId}/product-contracts`;
};

export const premiseProductContractApi = {
  cancel: ({
    premiseId,
    contractId,
    endDate,
  }: ProductContractCancelRequestType): Promise<null> =>
    ajax.patch(`${basePath(premiseId)}/cancel`, {
      contractId,
      endDate,
    }),
  claims: {
    create: ({
      premiseId,
      contractId,
      claimFile,
    }: ProductContractClaimRequestType): Promise<null> => {
      const formData = new FormData();

      formData.append("contract_id", contractId);
      formData.append("file", claimFile);
      return ajax.postFile(`${basePath(premiseId)}/claims`, formData);
    },
    listOptions: ({
      premiseId,
      productContractId,
    }: ProductContractClaimOptionsRequestType): Promise<ClaimOptionType[]> =>
      ajax.get(`${basePath(premiseId)}/${productContractId}/claim-options`),
  },
  create: ({
    premiseId,
    priceId,
  }: {
    premiseId: IdType;
    priceId: UUIDType;
  }): Promise<CreateProductContractResponse> =>
    ajax.post<CreateProductContractResponse>(`${basePath(premiseId)}`, {
      priceId,
    }),
  list: (premiseId: IdType, params: ProductContractListQueryParams) => {
    const { status = ProductContractStatusFilter.ACTIVE } = params;

    return ajax.get<PaginationEndpointResponseType<ProductContractType>>(
      `${basePath(premiseId)}`,
      {
        params: {
          ...params,
          status,
        },
      }
    );
  },
};

import { IdType } from "@common/types/apiTypes";
import { invoiceApi } from "@portal-account/api/invoiceApi";
import { invoiceQueryKeys } from "@portal-account/queryKeys/invoiceQueryKeys";
import { useQuery } from "@tanstack/react-query";

interface UseInvoiceKwhUsageQueryOptions {
  invoiceId: IdType;
}

export const useInvoiceKwhUsageQuery = (
  options: UseInvoiceKwhUsageQueryOptions
) => {
  const { invoiceId } = options;

  const query = useQuery({
    queryFn: () => invoiceApi.usage(invoiceId),
    queryKey: invoiceQueryKeys.usage(invoiceId),
  });

  return query;
};

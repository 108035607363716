import { IdType } from "@common/types/apiTypes";
import { useCustomerFromContext } from "@portal-account/components/CustomerProvider/useCustomerFromContext";
import { SESSION_STORAGE_PREMISE_ID_KEY } from "@portal-account/components/PremiseIdChooser/PremiseIdChooser.constants";
import { customerPreferencesPremiseUpdated } from "@portal-account/slices/customerPreferencesSlice";
import React, { PropsWithChildren, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";

interface PremiseIdChooserProps {}

export const PremiseIdChooser = (
  props: PropsWithChildren<PremiseIdChooserProps>
) => {
  const { children } = props;
  const dispatch = useDispatch();
  const customer = useCustomerFromContext();
  const [params] = useSearchParams();
  const [finalPremiseId, setFinalPremiseId] = useState<IdType | null>(null);
  const [premiseIdInSessionStorage, setPremiseIdInSessionStorage] =
    useSessionStorage<string | null>(SESSION_STORAGE_PREMISE_ID_KEY, null);

  const premiseIdFromSearchParams = params.get("premise");

  useEffect(() => {
    if (premiseIdFromSearchParams) {
      const possiblePremise = customer.premises.find(
        (premise) => premise.id === premiseIdFromSearchParams
      );

      if (possiblePremise) {
        setFinalPremiseId(possiblePremise.id);
        return;
      }
    }

    if (premiseIdInSessionStorage) {
      const possiblePremise = customer.premises.find(
        (premise) => premise.id === String(premiseIdInSessionStorage)
      );

      if (possiblePremise) {
        setFinalPremiseId(possiblePremise.id);
        return;
      }
    }

    setFinalPremiseId(customer.premises[0].id);
  }, [customer.premises, premiseIdFromSearchParams, premiseIdInSessionStorage]);

  useEffect(() => {
    if (finalPremiseId) {
      dispatch(customerPreferencesPremiseUpdated(finalPremiseId));
      setPremiseIdInSessionStorage(finalPremiseId);
    }
  }, [finalPremiseId]);

  if (!finalPremiseId) {
    return null;
  }

  return <>{children}</>;
};

import { IdType } from "@common/types/apiTypes";
import { invoiceApi } from "@portal-account/api/invoiceApi";
import { invoiceQueryKeys } from "@portal-account/queryKeys/invoiceQueryKeys";
import { useQuery } from "@tanstack/react-query";

interface UseInvoiceQueryProps {
  invoiceId: IdType;
}

export const useInvoiceQuery = (props: UseInvoiceQueryProps) => {
  const { invoiceId } = props;

  return useQuery({
    queryFn: () => invoiceApi.retrieve(invoiceId),
    queryKey: invoiceQueryKeys.retrieve(invoiceId),
  });
};

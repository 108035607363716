import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { paymentHistoryTranslations } from "@portal-account/components/PaymentHistory/PaymentHistory.en.i18n";
import { StyledPaymentsLoading } from "@portal-account/components/PaymentHistory/PaymentHistory.styled";
import { PaymentHistoryTable } from "@portal-account/components/PaymentHistoryTable/PaymentHistoryTable";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { CustomerHomeSectionInfoMessage } from "@portal-account/pages/CustomerHomePage/CustomerHomeSectionInfoMessage/CustomerHomeSectionInfoMessage";
import { CustomerHomeSectionInfoMessageContainer } from "@portal-account/pages/CustomerHomePage/CustomerHomeSectionInfoMessage/CustomerHomeSectionInfoMessage.styled";
import { usePaymentHistoryQuery } from "@portal-account/queries/usePaymentHistoryQuery";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

export const PaymentHistory = () => {
  const { translate } = useTranslations();
  const flash = useRhFlash();
  const { premise } = usePremiseFromContext();
  const { tPaymentHistoryNoPayments, tPaymentHistoryError } = translate(
    paymentHistoryTranslations
  );

  const {
    data: paymentHistoryData,
    isError,
    isPending,
  } = usePaymentHistoryQuery({
    premiseId: premise.id,
  });

  if (isPending) {
    return (
      <StyledPaymentsLoading>
        <RhCircularProgress size={24} />
      </StyledPaymentsLoading>
    );
  }

  if (isError) {
    flash.error(tPaymentHistoryError);

    return (
      <CustomerHomeSectionInfoMessageContainer>
        <CustomerHomeSectionInfoMessage>
          {tPaymentHistoryError}
        </CustomerHomeSectionInfoMessage>
      </CustomerHomeSectionInfoMessageContainer>
    );
  }

  if (!paymentHistoryData.results.length) {
    return (
      <CustomerHomeSectionInfoMessageContainer>
        <CustomerHomeSectionInfoMessage>
          {tPaymentHistoryNoPayments}
        </CustomerHomeSectionInfoMessage>
      </CustomerHomeSectionInfoMessageContainer>
    );
  }

  return <PaymentHistoryTable paymentHistory={paymentHistoryData.results} />;
};

import { IdType } from "@common/types/apiTypes";

export const invoiceQueryKeys = {
  BASE: ["invoices"],
  compare: (invoiceId: IdType) => [
    ...invoiceQueryKeys.BASE,
    invoiceId,
    "bill-explanation",
    "compare",
  ],
  factors: (invoiceId: IdType) => [
    ...invoiceQueryKeys.BASE,
    invoiceId,
    "bill-explanation",
    "factors",
  ],
  orders: (invoiceId: IdType) => [
    ...invoiceQueryKeys.BASE,
    invoiceId,
    "orders",
  ],
  retrieve: (invoiceId: IdType) => [...invoiceQueryKeys.BASE, invoiceId],
  temperature: (invoiceId: IdType) => [
    ...invoiceQueryKeys.BASE,
    invoiceId,
    "bill-explaination",
    "temperature",
  ],
  usage: (invoiceId: IdType) => [
    ...invoiceQueryKeys.BASE,
    invoiceId,
    "bill-explaination",
    "usage",
  ],
};

import { useDebounce } from "@common/hooks/useDebounce";
import { PremiseSourceType } from "@common/types/premiseTypes";
import { rhWindow } from "@common/utils/rhWindow";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { AtAGlanceCarouselTranslations } from "@portal-account/components/AtAGlanceCarousel/AtAGlanceCarousel.en.i18n";
import { AtAGlanceCarouselContainer } from "@portal-account/components/AtAGlanceCarousel/AtAGlanceCarousel.styled";
import { AtAGlanceItem } from "@portal-account/components/AtAGlanceCarousel/AtAGlanceItem";
import { useTrackMyAccountEvents } from "@portal-account/hooks/useTrackMyAccountEvents";
import { myAccountPath } from "@portal-account/routes/routePaths";
import {
  ActionType,
  MyAccountEvents,
} from "@portal-account/services/segment.service";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import React, { useEffect, useRef } from "react";

const DELAY_MS = 300;
const WINDOW_RIGHT_PADDING = 25;

interface AtAGlanceCarouselProps {
  containerWidth?: number | null;
  isSolarBuybackPlan?: boolean;
  onScrollEnd?: (atEnd: boolean) => void;
  optedInEBill: boolean;
  pickedBillingDueDate: boolean;
  source: PremiseSourceType;
}

export const AtAGlanceCarousel = ({
  pickedBillingDueDate,
  optedInEBill,
  onScrollEnd,
  containerWidth,
  source,
  isSolarBuybackPlan = false,
}: AtAGlanceCarouselProps) => {
  const { translate } = useTranslations();
  const carouselRef = useRef<HTMLDivElement | null>(null);
  const lastItemRef = useRef<HTMLDivElement | null>(null);
  const track = useTrackMyAccountEvents();

  const {
    tAtAGlanceCarouselCtaChangeBillingDate,
    tAtAGlanceCarouselCtaFaq,
    tAtAGlanceCarouselCtaOptIn,
    tAtAGlanceCarouselCtaSolarSetupBillingUsage,
    tAtAGlanceCarouselDueDatePicked,
    tAtAGlanceCarouselEnergySavingTips,
    tAtAGlanceCarouselOptInToPaperless,
    tAtAGlanceCarouselPickADueDate,
    tAtAGlanceCarouselSignedUpForPaperless,
    tAtAGlanceCarouselSolarSetupBillingUsage,
  } = translate(AtAGlanceCarouselTranslations);

  const handleScroll = () => {
    if (lastItemRef.current !== null && onScrollEnd !== undefined) {
      const { right } = lastItemRef.current.getBoundingClientRect();
      const { innerWidth } = rhWindow;
      const horizontalOffset = (innerWidth - (containerWidth || 0)) / 2;

      if (right < innerWidth - (horizontalOffset + WINDOW_RIGHT_PADDING)) {
        onScrollEnd(true);
      } else {
        onScrollEnd(false);
      }
    }
  };

  const debouncedHandleScroll = useDebounce(handleScroll, DELAY_MS);

  useEffect(() => {
    if (
      Boolean(onScrollEnd) &&
      carouselRef !== null &&
      carouselRef.current !== null
    ) {
      const currentRef = carouselRef.current;

      currentRef.addEventListener("scroll", debouncedHandleScroll);
      return () =>
        currentRef.removeEventListener("scroll", debouncedHandleScroll);
    }
  }, [carouselRef, onScrollEnd, debouncedHandleScroll]);

  const rhythmPremise: boolean = PremiseSourceType.RHYTHM === source;

  const onReadMoreClick = () => {
    track({
      action: ActionType.click,
      event: MyAccountEvents.readMoreEnergySaving,
      label: "Clicked Read more energy saving tips",
    });
  };

  return (
    // MUI BoxProps type doesn't allow for `ref`.  This is a workaround
    // (https://github.com/mui-org/material-ui/issues/17010#issuecomment-584223410)
    <AtAGlanceCarouselContainer ref={carouselRef}>
      {rhythmPremise && (
        <RhFlexBox justifyContent="flex-start" pr={1}>
          <AtAGlanceItem
            title={tAtAGlanceCarouselPickADueDate}
            disabledTitle={tAtAGlanceCarouselDueDatePicked}
            cta={{
              linkTo: {
                pathname: myAccountPath(),
                state: { anchorId: "billingPreferences" },
              },
              text: tAtAGlanceCarouselCtaChangeBillingDate,
            }}
            disabled={pickedBillingDueDate}
          />
        </RhFlexBox>
      )}
      <RhFlexBox
        justifyContent="center"
        pr={2}
        pl={2}
        {...(rhythmPremise ? { position: "relative" } : {})}
      >
        <AtAGlanceItem
          title={tAtAGlanceCarouselOptInToPaperless}
          disabledTitle={tAtAGlanceCarouselSignedUpForPaperless}
          cta={{
            linkTo: {
              pathname: myAccountPath(),
              state: { anchorId: "billingPreferences" },
            },
            text: tAtAGlanceCarouselCtaOptIn,
          }}
          disabled={optedInEBill}
        />
      </RhFlexBox>
      <RhFlexBox justifyContent="flex-end" pl={2} pr={1} position="relative">
        {isSolarBuybackPlan ? (
          <AtAGlanceItem
            ref={lastItemRef}
            title={tAtAGlanceCarouselSolarSetupBillingUsage}
            cta={{
              external: true,
              linkTo: brandInformation.urlsFaqsSolarBuyBack,
              text: tAtAGlanceCarouselCtaSolarSetupBillingUsage,
            }}
            disableBorder
          />
        ) : (
          <AtAGlanceItem
            ref={lastItemRef}
            title={tAtAGlanceCarouselEnergySavingTips}
            cta={{
              external: true,
              linkTo: brandInformation.urlsBlogPostsEnergySavingTips,
              onClick: onReadMoreClick,
              text: tAtAGlanceCarouselCtaFaq,
              useButton: true,
            }}
            disableBorder
          />
        )}
      </RhFlexBox>
    </AtAGlanceCarouselContainer>
  );
};

import { UUIDType } from "@common/types/apiTypes";
import { KeysMatch } from "@common/types/KeysMatch";
import { formatCurrency } from "@common/utils/dataFormatters";
import {
  ProductContractFrequency,
  ProductContractType,
} from "@portal-account/types/productContractTypes";
import { ProductPriceTermPeriod } from "@portal-shared/types/productTypes";

export class ProductContract {
  endDate: string;
  frequency: ProductContractFrequency;
  id: UUIDType;
  number: string;
  productName: string;
  rate: string;
  startDate: string;
  termLength: number;
  termPeriod: ProductPriceTermPeriod;
  tierDescription: string;
  tierName: string;
  tosUrl: string;

  constructor(productContract: ProductContractType) {
    this.endDate = productContract.endDate;
    this.frequency = productContract.frequency;
    this.id = productContract.id;
    this.number = productContract.number;
    this.productName = productContract.productName;
    this.rate = productContract.rate;
    this.startDate = productContract.startDate;
    this.termLength = productContract.termLength;
    this.termPeriod = productContract.termPeriod;
    this.tierDescription = productContract.tierDescription;
    this.tierName = productContract.tierName;
    this.tosUrl = productContract.tosUrl;
  }

  getFormattedPricePerFrequency(termPeriod: string) {
    return `${formatCurrency(this.rate)} / ${termPeriod}`;
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummyKeysCheckDoNotDeleteOrChange: KeysMatch<
  ProductContractType,
  ProductContract
> = undefined;

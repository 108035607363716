import { IdType } from "@common/types/apiTypes";
import { premiseApi } from "@portal-account/api/premiseApi";
import { premiseQueryKeys } from "@portal-account/queryKeys/premiseQueryKeys";
import { useQuery } from "@tanstack/react-query";

interface UsePaymentExtensionQualifiedQueryProps {
  premiseId: IdType;
}
export const usePaymentExtensionQualifiedQuery = (
  props: UsePaymentExtensionQualifiedQueryProps
) => {
  const { premiseId } = props;

  return useQuery({
    queryFn: () => premiseApi.paymentExtensionsQualified(premiseId),
    queryKey: premiseQueryKeys.paymentExtensionsQualified(premiseId),
  });
};

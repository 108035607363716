import { noop } from "@common/utils/genericFunctions";
import { RhSubmitButton } from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { myAccountSubmitButtonTranslations } from "@portal-account/components/MyAccountSubmitButton/MyAccountSubmitButton.en.i18n";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { SubmissionErrors, ValidationErrors } from "final-form";
import React from "react";

interface MyAccountSubmitButtonProps {
  isMobile: boolean;
  onError?: (submitErrors: SubmissionErrors | ValidationErrors) => void;
  text?: string;
}

export const MyAccountSubmitButton = ({
  onError,
  isMobile,
  text,
}: MyAccountSubmitButtonProps) => {
  const { translate } = useTranslations();

  const { tMyAccountSubmitButtonTranslationsButtonCta } = translate(
    myAccountSubmitButtonTranslations
  );
  const buttonText = text ?? tMyAccountSubmitButtonTranslationsButtonCta;

  return (
    <RhSubmitButton
      data-tracking-click={{ event: "" }}
      disallowPristineSubmit
      restartOnSuccess
      onError={onError || noop}
      fullWidth={isMobile}
    >
      {buttonText}
    </RhSubmitButton>
  );
};

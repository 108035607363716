import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhRadioInput } from "@design-system/components/RhRadioInput/RhRadioInput";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RadioGroup } from "@mui/material";
import { AmountType } from "@portal-account/enums/AmountType.enum";
import { CustomPaymentField } from "@portal-account/pages/PayBillPage/CustomPaymentField/CustomPaymentField";
import { PayBillPageFormValues } from "@portal-account/pages/PayBillPage/PayBillPageFormValuesType";
import { translations } from "@portal-account/pages/PayBillPage/PaymentAmount/PaymentAmount.en.i18n";
import {
  StyledPaymentAmountFormControl,
  StyledPaymentAmountFormControlLabel,
  StyledPaymentAmountWrapper,
} from "@portal-account/pages/PayBillPage/PaymentAmount/PaymentAmount.styled";
import { RemainingBillBalance } from "@portal-account/pages/PayBillPage/RemainingBillBalance/RemainingBillBalance";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";
import { useField, useFormState } from "react-final-form";

export const PaymentAmount = () => {
  const { translate } = useTranslations();

  const {
    tPaymentAmountSelectAmount,
    tPaymentAmountPayTotalAmount,
    tPaymentAmountPayPastDue,
    tPaymentAmountPayCustomAmount,
  } = translate(translations);

  const { values } = useFormState<PayBillPageFormValues>({
    subscription: { values: true },
  });
  const { input: totalInput } = useField<AmountType>("amountType", {
    type: "radio",
    value: AmountType.Total,
  });
  const { input: customInput } = useField<AmountType>("amountType", {
    type: "radio",
    value: AmountType.Custom,
  });
  const { input: pastDueInput } = useField<AmountType>("amountType", {
    type: "radio",
    value: AmountType.PastDue,
  });

  return (
    <StyledPaymentAmountWrapper>
      <StyledPaymentAmountFormControl component="fieldset">
        <RadioGroup aria-label={tPaymentAmountSelectAmount}>
          <StyledPaymentAmountFormControlLabel
            disabled={values.totalAmount <= 0}
            control={<RhRadioInput {...totalInput} />}
            label={tPaymentAmountPayTotalAmount}
            $marginBottom={rhSpacingPx(1.5)}
          />
          <StyledPaymentAmountFormControlLabel
            disabled={values.pastDueAmount <= 0}
            control={<RhRadioInput {...pastDueInput} />}
            label={tPaymentAmountPayPastDue}
            $marginBottom={rhSpacingPx(1.5)}
          />
          <RhFlexBox
            justifyContent="space-between"
            marginBottom={values.amountType === AmountType.Custom ? 2 : 0}
          >
            <StyledPaymentAmountFormControlLabel
              id="custom-amount-label"
              value={AmountType.Custom}
              control={<RhRadioInput {...customInput} />}
              label={tPaymentAmountPayCustomAmount}
            />
            <CustomPaymentField labelled-by="custom-amount-label" />
          </RhFlexBox>
          <RemainingBillBalance />
        </RadioGroup>
      </StyledPaymentAmountFormControl>
    </StyledPaymentAmountWrapper>
  );
};

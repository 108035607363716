import { useElementWidth } from "@common/hooks/useElementWidth";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { AtAGlanceCarousel } from "@portal-account/components/AtAGlanceCarousel/AtAGlanceCarousel";
import { AtAGlanceSectionTranslations } from "@portal-account/components/AtAGlanceSection/AtAGlanceSection.en.i18n";
import {
  StyledContainer,
  StyledFade,
} from "@portal-account/components/AtAGlanceSection/AtAGlanceSection.styled";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React, { useRef, useState } from "react";

export const AtAGlanceSection = () => {
  const { translate } = useTranslations();
  const { premise } = usePremiseFromContext();
  const [scrollEnd, setScrollEnd] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.MD));
  const cardRef = useRef<HTMLDivElement | null>(null);
  const { refWidth: cardWidth } = useElementWidth(cardRef);
  const onScrollEnd = isMobile ? { onScrollEnd: setScrollEnd } : {};

  const { tAtAGlanceSectionAtAGlance } = translate(
    AtAGlanceSectionTranslations
  );

  if (!premise) {
    return null;
  }

  return (
    <StyledContainer ref={cardRef} title={tAtAGlanceSectionAtAGlance}>
      <AtAGlanceCarousel
        pickedBillingDueDate={Boolean(premise.pickedBillingDueDay)}
        optedInEBill={premise.invoiceByEmailOnly}
        {...onScrollEnd}
        containerWidth={cardWidth}
        source={premise.source}
        isSolarBuybackPlan={premise.currentOrder?.isSolarBuybackPlan}
      />
      {isMobile && !scrollEnd && <StyledFade />}
    </StyledContainer>
  );
};

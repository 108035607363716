import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as LightbublIcon } from "@design-system/icons/bill-factors/Lightbubl.svg";
import { billFactorsTranslations } from "@portal-account/components/BillFactors/BillFactors.en.i18n";
import { StyledBillFactor } from "@portal-account/components/BillFactors/BillFactors.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

interface BillFactorUsageProps {
  differenceInKwh: number;
}

export const BillFactorUsage = ({ differenceInKwh }: BillFactorUsageProps) => {
  const absDifference = Math.abs(Number(differenceInKwh));

  const { translateJsx, translate } = useTranslations();
  const { tBillFactorsUsage } = translate(billFactorsTranslations);
  const { tBillFactorsUsageLess, tBillFactorsUsageMore } = translateJsx({
    tBillFactorsUsageLess: { usage: absDifference },
    tBillFactorsUsageMore: {
      usage: absDifference,
    },
  });

  return (
    <StyledBillFactor>
      <LightbublIcon />
      <RhTypography fontWeight="Bold">{tBillFactorsUsage}</RhTypography>
      <RhTypography variant="body2">
        {Number(differenceInKwh) > 0
          ? tBillFactorsUsageMore
          : tBillFactorsUsageLess}
      </RhTypography>
    </StyledBillFactor>
  );
};

import { customerApi } from "@portal-account/api/customerApi";
import { customerQueryKeys } from "@portal-account/queryKeys/customerQuerykeys";
import { DeviceBrands } from "@portal-account/types/devicesTypes";
import { useQuery } from "@tanstack/react-query";

interface UseGetResideoOauthUrlProps {
  deviceBrand: DeviceBrands | null;
  queryOptions?: {
    enabled?: boolean;
  };
}

export const useGetResideoOauthUrlQuery = (
  props: UseGetResideoOauthUrlProps
) => {
  const { deviceBrand, queryOptions: { enabled = true } = {} } = props;

  const query = useQuery({
    enabled,
    queryFn: () => customerApi.getResideoOauthUrl(deviceBrand),
    queryKey: customerQueryKeys.getResideoOauthUrl(deviceBrand),
  });

  return query;
};

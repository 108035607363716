import { PremiseContext } from "@portal-account/components/PremiseProvider/PremiseContext";
import { useContext } from "react";

export const usePremiseFromContext = () => {
  const premise = useContext(PremiseContext);

  if (!premise) {
    throw new Error("PremiseContext: No value provided");
  }

  return { premise };
};

import React from "react";
import { Helmet } from "react-helmet-async";

interface AwsConnectHelmetProps {}

export const AwsLoggedOutConnectHelmet = (props: AwsConnectHelmetProps) => {
  return (
    <Helmet>
      <link
        rel="stylesheet"
        type="text/css"
        href="/assets/styles/awsConnectWrapper.css"
      />
      <script
        type="text/javascript"
        src="assets/js/loggedOutAwsConnectChat.js"
      />
    </Helmet>
  );
};

import { RequestMonitor } from "@common/models/RequestMonitor.model";
import { selectRewards } from "@portal-account/selectors/rewardsSelectors";
import { rewardsRefetch } from "@portal-account/slices/rewardsSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useGetCustomerRewards = () => {
  const { rewards, requestStatus, error } = useSelector(selectRewards);
  const dispatch = useDispatch();

  useEffect(() => {
    // pessimistically refetch rewards
    dispatch(rewardsRefetch());
  }, [dispatch]);

  return {
    error,
    requestMonitor: new RequestMonitor(requestStatus),
    rewards,
  };
};

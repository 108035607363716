import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { EFLLink } from "@portal-shared/components/EFLLink/EFLLink";
import {
  IconContainer,
  LegalDocumentsContainer,
  StyledDescription,
  StyledPdfIcon,
  StyledPdfIconContainer,
} from "@portal-shared/components/LegalDocuments/LegalDocuments.styled";
import { TOSLink } from "@portal-shared/components/TOSLink/TOSLink";
import { YRACLink } from "@portal-shared/components/YRACLink/YRACLink";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { TranslationKey, useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import React from "react";

export interface LegalDocumentsProps {
  flexDirection?: "row" | "column";
  inCart?: boolean;
  offerSnapshot: OfferSnapshot;
}

export const LegalDocuments = ({
  offerSnapshot,
  flexDirection = "row",
  inCart = false,
}: LegalDocumentsProps) => {
  const { t } = useRhIntl();
  const [locale] = useLocaleAtom();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.SM));

  const formattedMessage = (name: string) =>
    t(`LegalDocuments.${name}` as TranslationKey);

  const yracTitle = formattedMessage("yrac");
  const tosTitle = formattedMessage("tos");
  const eflTitle = formattedMessage("efl");

  return (
    <>
      <Box pt={inCart ? 1 : 3}>
        <RhTypography
          variant={inCart ? "h3" : "h2"}
          fontWeight={inCart ? FontWeight.Semibold : FontWeight.Bold}
        >
          {formattedMessage("contractDocuments")}
        </RhTypography>
      </Box>

      <Box pt={2}>
        <LegalDocumentsContainer
          flexDirection={isMobile ? "column" : flexDirection}
        >
          <RhFlexBox flexDirection="row">
            <IconContainer>
              <EFLLink
                dataTrackingClick={{
                  location: "Legal Documents Section",
                }}
                offerId={offerSnapshot.id}
                locale={locale}
              >
                <StyledPdfIcon title={eflTitle} />
              </EFLLink>
            </IconContainer>
            <Box>
              <EFLLink
                dataTrackingClick={{
                  location: "Legal Documents Section",
                }}
                offerId={offerSnapshot.id}
                locale={locale}
              >
                <RhTypography variant="body1">{eflTitle}</RhTypography>
              </EFLLink>
              <StyledDescription variant="body1" color="textSecondary">
                {formattedMessage("eflDescription")}
              </StyledDescription>
            </Box>
          </RhFlexBox>

          {!inCart && (
            <>
              <RhFlexBox flexDirection="row">
                <StyledPdfIconContainer>
                  <TOSLink
                    dataTrackingClick={{
                      location: "Legal Documents Section",
                    }}
                    tosUrl={offerSnapshot.termsOfServiceLink(locale)}
                  >
                    <StyledPdfIcon title={tosTitle} />
                  </TOSLink>
                </StyledPdfIconContainer>
                <Box>
                  <TOSLink
                    dataTrackingClick={{
                      location: "Legal Documents Section",
                    }}
                    tosUrl={offerSnapshot.termsOfServiceLink(locale)}
                  >
                    <RhTypography variant="body1">{tosTitle}</RhTypography>
                  </TOSLink>
                  <StyledDescription variant="body1" color="textSecondary">
                    {formattedMessage("tosDescription")}
                  </StyledDescription>
                </Box>
              </RhFlexBox>

              <RhFlexBox flexDirection="row">
                <IconContainer>
                  <YRACLink
                    dataTrackingClick={{
                      location: "Legal Documents Section",
                    }}
                    locale={locale}
                  >
                    <StyledPdfIcon title={yracTitle} />
                  </YRACLink>
                </IconContainer>
                <Box mb={2}>
                  <YRACLink
                    dataTrackingClick={{
                      location: "Legal Documents Section",
                    }}
                    locale={locale}
                  >
                    <RhTypography variant="body1">{yracTitle}</RhTypography>
                  </YRACLink>
                  <StyledDescription variant="body1" color="textSecondary">
                    {formattedMessage("yracDescription")}
                  </StyledDescription>
                </Box>
              </RhFlexBox>
            </>
          )}
        </LegalDocumentsContainer>
      </Box>
    </>
  );
};

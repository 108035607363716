import { useCustomerFromContext } from "@portal-account/components/CustomerProvider/useCustomerFromContext";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { useTrackMyAccountEvents } from "@portal-account/hooks/useTrackMyAccountEvents";
import { ReactComponent as RenewalWelcomeImage } from "@portal-account/images/RenewalWelcomeImage.svg";
import { customerHomeRenewalSectionTranslations } from "@portal-account/pages/CustomerHomePage/CustomerHomeRenewalSection/CustomerHomeRenewalSection.en.i18n";
import {
  StyledBody,
  StyledBoldText,
  StyledCustomerHomeRenewalSection,
  StyledHeader,
  StyledRenewButton,
  StyledSubheader,
  StyledWelcomeImageContainer,
} from "@portal-account/pages/CustomerHomePage/CustomerHomeRenewalSection/CustomerHomeRenewalSection.styled";
import { accountRenewalsPath } from "@portal-account/routes/routePaths";
import {
  ActionType,
  CategoryType,
  EventType,
  LabelType,
} from "@portal-account/services/segment.service";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import dayjs from "dayjs";
import React from "react";
import { Link } from "react-router-dom";

export const CustomerHomeRenewalSection = () => {
  const track = useTrackMyAccountEvents();
  const { translate, translateJsx } = useTranslations();

  const { premise } = usePremiseFromContext();
  const customer = useCustomerFromContext();

  const now = dayjs();
  const expiration = dayjs(premise.currentOrder?.endDate);
  const daysToExpiration = expiration.diff(now, "days");
  const isFixedRateExpiredContract =
    premise.currentOrder?.isMonthToMonth &&
    (!premise.futureOrder || premise.futureOrder?.isMonthToMonth);

  const {
    tCustomerHomeRenewalSectionRenewNow,
    tCustomerHomeRenewalSectionSubtitleExpireToday,
    tCustomerHomeRenewalSectionSwitchToFixedRate,
    tCustomerHomeRenewalSectionRenewalWelcomeImageAltText,
  } = translate(customerHomeRenewalSectionTranslations);

  const {
    tCustomerHomeRenewalSectionWelcomeMessageRenewal,
    tCustomerHomeRenewalSectionWelcomeMessageRenewalToday,
    tCustomerHomeRenewalSectionWelcomeMessageRenewalExpired,
    tCustomerHomeRenewalSectionSubtitleExpires,
    tCustomerHomeRenewalSectionSubtitleExpired,
  } = translateJsx({
    tCustomerHomeRenewalSectionSubtitleExpired: {},
    tCustomerHomeRenewalSectionSubtitleExpires: {
      bold: (message: string) => <StyledBoldText>{message}</StyledBoldText>,
      days: daysToExpiration,
    },
    tCustomerHomeRenewalSectionWelcomeMessageRenewal: {
      name: customer.fullName,
    },
    tCustomerHomeRenewalSectionWelcomeMessageRenewalExpired: {
      name: customer.fullName,
    },
    tCustomerHomeRenewalSectionWelcomeMessageRenewalToday: {
      name: customer.fullName,
    },
  });

  let welcomeGreeting = tCustomerHomeRenewalSectionWelcomeMessageRenewal;

  if (daysToExpiration === 0) {
    welcomeGreeting = tCustomerHomeRenewalSectionWelcomeMessageRenewalToday;
  } else if (daysToExpiration < 0 || isFixedRateExpiredContract) {
    welcomeGreeting = tCustomerHomeRenewalSectionWelcomeMessageRenewalExpired;
  }

  const getSubtitleText = () => {
    if (daysToExpiration < 0 || isFixedRateExpiredContract) {
      return tCustomerHomeRenewalSectionSubtitleExpired;
    } else if (daysToExpiration === 0) {
      return tCustomerHomeRenewalSectionSubtitleExpireToday;
    }

    return tCustomerHomeRenewalSectionSubtitleExpires;
  };

  const getRenewalButtonLabel = () => {
    if (daysToExpiration < 0 || isFixedRateExpiredContract) {
      return tCustomerHomeRenewalSectionSwitchToFixedRate;
    }
    return tCustomerHomeRenewalSectionRenewNow;
  };

  const isButtonRed = daysToExpiration <= 30 || isFixedRateExpiredContract;

  const trackRenewalCtaClick = () =>
    track({
      action: ActionType.click,
      buttonText: getRenewalButtonLabel(),
      category: CategoryType.Renewals,
      event: EventType.renewalClick,
      label: LabelType.ClickedRenewalsCta,
    });

  return (
    <StyledCustomerHomeRenewalSection>
      <StyledWelcomeImageContainer>
        <RenewalWelcomeImage
          title={tCustomerHomeRenewalSectionRenewalWelcomeImageAltText}
        />
      </StyledWelcomeImageContainer>
      <StyledBody>
        <StyledHeader variant="h1">{welcomeGreeting}</StyledHeader>
        <StyledSubheader variant="subtitle1">
          {getSubtitleText()}
        </StyledSubheader>
        <Link to={accountRenewalsPath()} onClick={trackRenewalCtaClick}>
          <StyledRenewButton
            data-tracking-click={{
              event: "Customer attemping to renew contract",
            }}
            color="primary"
            $isRed={isButtonRed}
            variant={isButtonRed ? "outlined" : "contained"}
          >
            {getRenewalButtonLabel()}
          </StyledRenewButton>
        </Link>
      </StyledBody>
    </StyledCustomerHomeRenewalSection>
  );
};

import { HttpStatusCodes } from "@common/types/apiResponseTypes";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { SESSION_STORAGE_PREMISE_ID_KEY } from "@portal-account/components/PremiseIdChooser/PremiseIdChooser.constants";
import { PremiseContext } from "@portal-account/components/PremiseProvider/PremiseContext";
import { usePremiseQuery } from "@portal-account/queries/usePremiseQuery";
import {
  accountCreationPendingPath,
  signOutPath,
} from "@portal-account/routes/routePaths";
import { Error500Page } from "@portal-shared/pages/Error500Page/Error500Page";
import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
interface PremiseProviderProps {}

export const PremiseProvider = ({
  children,
}: PropsWithChildren<PremiseProviderProps>) => {
  const [premiseIdInSessionStorage] = useSessionStorage<string | null>(
    SESSION_STORAGE_PREMISE_ID_KEY,
    null
  );

  const premiseQuery = usePremiseQuery({
    premiseId: premiseIdInSessionStorage || "",
    queryOptions: { enabled: Boolean(premiseIdInSessionStorage) },
  });

  if (premiseQuery.isPending) {
    return <RhCircularProgress />;
  }

  if (premiseQuery.isError) {
    if (premiseQuery.error.status === HttpStatusCodes.HTTP_403_FORBIDDEN) {
      return <Navigate to={signOutPath()} />;
    }

    return <Error500Page />;
  }

  const premise = premiseQuery.data;

  if (!premise.billingAccountId) {
    return <Navigate to={accountCreationPendingPath()} />;
  }

  return (
    <PremiseContext.Provider value={premise}>
      {children}
    </PremiseContext.Provider>
  );
};

import { IdType } from "@common/types/apiTypes";
import { premiseApi } from "@portal-account/api/premiseApi";
import { premiseQueryKeys } from "@portal-account/queryKeys/premiseQueryKeys";
import { useQuery } from "@tanstack/react-query";

interface UsePremiseQueryProps {
  premiseId: IdType;
  queryOptions?: {
    enabled?: boolean;
  };
}
export const usePremiseQuery = (props: UsePremiseQueryProps) => {
  const { premiseId, queryOptions: { enabled = true } = {} } = props;

  return useQuery({
    enabled,
    queryFn: () => premiseApi.retrieve(premiseId),
    queryKey: premiseQueryKeys.retrieve(premiseId),
  });
};

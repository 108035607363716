import { useDebounce } from "@common/hooks/useDebounce";
import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { zuora } from "@common/utils/zuora.util";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ZouraCardForm } from "@portal-account/components/ZuoraCardForm/ZouraCardForm.styled";
import { useUpdatePremisePaymentMethodMutation } from "@portal-account/mutations/useUpdatePremisePaymentMethodMutation";
import { selectCustomerPremisePreference } from "@portal-account/selectors/customerPreferencesSelectors";
import { PaymentMethodOptionsType } from "@portal-shared/components/PaymentMethodOptions/PaymentMethodOptions";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import { useFetchRSASignatureQuery } from "@portal-shared/queries/useFetchRSASignatureQuery";
import {
  CreditCardFormError,
  ZuoraInlineFormError,
} from "@portal-shared/services/ZuoraInlineFormError.service";
import { getZuoraParams } from "@portal-shared/utils/baseZuoraParams.util";
import dayjs from "dayjs";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";

interface ZuoraCardFormProps {
  onFailure?(error: string): void;
  onSuccess(zuoraResponse: ZuoraAddPaymentResponseType): void;
  paymentOptions?: PaymentMethodOptionsType;
}

export const ZuoraCardForm = ({
  onSuccess,
  onFailure,
  paymentOptions = {
    isOneTimePayment: false,
    setDefaultPaymentMethod: false,
  },
}: ZuoraCardFormProps) => {
  const flash = useRhFlash();
  const { t } = useRhIntl();
  const [isZuoraFormLoading, setZuoraFormLoading] = useState<boolean>(true);

  const premiseId = useSelector(selectCustomerPremisePreference);
  const zuoraCardForm = useRef<HTMLDivElement>(null);
  const updatePaymentMethodMutation = useUpdatePremisePaymentMethodMutation();

  const zuoraParams = getZuoraParams(dayjs().locale());
  const fetchRSASignatureQuery = useFetchRSASignatureQuery(zuoraParams.id);

  /**
   * Zuora can return multiple empty error messages (like when a customer hits submit without filling cc form)
   * We are just debouncing this so at most only one flash error is shown. If form is partially filled
   * then another message will be shown
   */
  const debouncedClientErrorMessageCallback = useDebounce(
    (errorType: string, errorCode: string, errorDescription: string) => {
      const zuoraInlineFormErrorService = new ZuoraInlineFormError(
        errorType,
        errorCode,
        errorDescription
      );

      const { error } = zuoraInlineFormErrorService;

      const errorMessage = t(`ZuoraCardForm.inlineFormError.${error}`);

      if (error === CreditCardFormError.tooManyTries) {
        onFailure?.(errorMessage);
      } else {
        flash.error(errorMessage);
      }
    },
    50
  );

  useEffect(() => {
    if (fetchRSASignatureQuery.isPending) {
      return;
    }

    zuora.setEventHandler("onloadCallback", () => {
      setZuoraFormLoading(false);
    });

    zuora.renderWithErrorHandler(
      {
        ...zuoraParams,
        ...fetchRSASignatureQuery.data,
      },
      {},
      (response: ZuoraAddPaymentResponseType) => {
        const errorMessage = t("ZuoraCardForm.errorAddingCard");

        if (!response.success) {
          onFailure?.(errorMessage);

          return;
        }

        if (premiseId) {
          return updatePaymentMethodMutation.mutate(
            {
              paymentMethodId: response.refId,
              premiseId,
              ...paymentOptions,
            },
            {
              onError: () => {
                onFailure?.(errorMessage);
              },
              onSuccess: () => {
                onSuccess(response);
              },
            }
          );
        }

        // Everything went well
        onSuccess(response);
      },
      debouncedClientErrorMessageCallback
    );
  }, [fetchRSASignatureQuery.isPending]);

  if (fetchRSASignatureQuery.isError) {
    onFailure?.(t("ZuoraCardForm.authorizationFailed"));
    return null;
  }

  return (
    <>
      {isZuoraFormLoading && <RhCircularProgress marginBottom={4} />}
      <div ref={zuoraCardForm}>
        <ZouraCardForm id="zuora_payment" data-testid="zuoraPaymentForm" />
      </div>
    </>
  );
};

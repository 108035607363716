import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import {
  LOGGED_OUT_FORM_WIDTH,
  RhythmBreakpoints,
} from "@design-system/theme/style.constant";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { expiredPasswordTokenPageTranslations } from "@portal-account/pages/ExpiredPasswordTokenPage/ExpiredPasswordTokenPage.en.i18n";
import { forgotPasswordPath } from "@portal-account/routes/routePaths";
import { LOGGED_OUT_CONTAINER_SPACING } from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout.constants";
import { LoggedOutPageHeader } from "@portal-shared/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { LoggedOutPageLayout } from "@portal-shared/components/LoggedOutPageLayout/LoggedOutPageLayout";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

export interface ExpiredPasswordTokenFormValues {
  id: string;
}

export const ExpiredPasswordTokenPage = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));

  const { translate } = useTranslations();

  const {
    tExpiredPasswordTokenPageExpired,
    tExpiredPasswordTokenPageResetCTA,
  } = translate(expiredPasswordTokenPageTranslations);

  return (
    <LoggedOutPageLayout>
      <LoggedOutPageHeader headerText={tExpiredPasswordTokenPageExpired} />
      <Box width={LOGGED_OUT_FORM_WIDTH} my={LOGGED_OUT_CONTAINER_SPACING}>
        <RhLinkButton
          data-tracking-click={{
            event: "Customer going to forgot password page",
          }}
          color="primary"
          variant="contained"
          size={isMobile ? "medium" : "large"}
          fullWidth
          href={forgotPasswordPath()}
        >
          {tExpiredPasswordTokenPageResetCTA}
        </RhLinkButton>
      </Box>
    </LoggedOutPageLayout>
  );
};

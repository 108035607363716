import {
  formatAsLocalTime,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { convertTemperature } from "@common/utils/stringFormatters";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { deviceDemandResponseActivityTableTranslations } from "@portal-account/components/DeviceDemandResponseActivityTable/DeviceDemandResponseActivityTable.en.i18n";
import {
  StyledPhaseContainer,
  StyledStatusAndDate,
  StyledStatusText,
} from "@portal-account/components/DeviceDemandResponseActivityTable/DeviceDemandResponseActivityTable.styled";
import { useDeviceDemandResponseActivityEvent } from "@portal-account/components/DeviceDemandResponseActivityTable/useDeviceDemandResponseActivityEvents";
import { MyAccountTable } from "@portal-account/components/MyAccountTable/MyAccountTable";
import {
  DeviceDemandResponseActivityEventStatusTypes,
  DeviceDemandResponseActivityPhaseType,
  DeviceDemandResponseActivityType,
} from "@portal-account/types/devicesTypes";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { convertTimeToHoursAndMinutes } from "@portal-shared/utils/timeHelpers";
import { createColumnHelper } from "@tanstack/react-table";
import React, { Dispatch, SetStateAction } from "react";
import { useMediaQuery } from "usehooks-ts";

interface DeviceDemandResponseActivityTableProps {
  demandResponseActivity: DeviceDemandResponseActivityType[];
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  setPagination: Dispatch<
    SetStateAction<{ pageIndex: number; pageSize: number }>
  >;
}

export const DeviceDemandResponseActivityTable = (
  props: DeviceDemandResponseActivityTableProps
) => {
  const {
    demandResponseActivity,
    pageCount,
    pageIndex,
    pageSize,
    setPagination,
  } = props;
  const isMobile = useMediaQuery(`(max-width: ${RhythmBreakpoints.MD}px)`);
  const [locale] = useLocaleAtom();
  const { translate } = useTranslations();
  const {
    tDeviceDemandResponseActivityTableStatus,
    tDeviceDemandResponseActivityTableStartTime,
    tDeviceDemandResponseActivityTableThermostatAdjustments,
    tDeviceDemandResponseActivityTableDuration,
    tDeviceDemandResponseActivityTableStatusAndDate,
  } = translate(deviceDemandResponseActivityTableTranslations);
  const demandResponseActivityEventStatus =
    useDeviceDemandResponseActivityEvent();

  const columnHelper = createColumnHelper<DeviceDemandResponseActivityType>();

  const sortPhaseByIndex = (phases: DeviceDemandResponseActivityPhaseType[]) =>
    phases.sort((a, b) => a.index - b.index);

  const columns = [
    columnHelper.display({
      cell: ({ row }) => (
        <StyledStatusAndDate>
          <div>
            <b>{formatMonthDayYear(row.original.startAt)}</b>
            <br />
            {formatAsLocalTime(row.original.startAt)}
          </div>
          <StyledStatusText
            $optOut={
              row.original.status ===
              DeviceDemandResponseActivityEventStatusTypes.OptedOut
            }
          >
            {demandResponseActivityEventStatus[row.original.status]}
          </StyledStatusText>
        </StyledStatusAndDate>
      ),
      header: tDeviceDemandResponseActivityTableStatusAndDate,
      id: "statusAndDate",
      meta: { classList: ["tableCellCenter"] },
    }),
    columnHelper.display({
      cell: ({ row }) => (
        <StyledStatusText
          $optOut={
            row.original.status ===
            DeviceDemandResponseActivityEventStatusTypes.OptedOut
          }
        >
          {demandResponseActivityEventStatus[row.original.status]}
        </StyledStatusText>
      ),
      header: tDeviceDemandResponseActivityTableStatus,
      id: "status",
      meta: { classList: ["tableCellCenter"] },
    }),
    columnHelper.display({
      cell: ({ row }) => (
        <div>
          <b>{formatMonthDayYear(row.original.startAt)}</b>
          <br />
          {formatAsLocalTime(row.original.startAt)}
        </div>
      ),
      header: tDeviceDemandResponseActivityTableStartTime,
      id: "startAt",
      meta: { classList: ["tableCellCenter"] },
    }),
    columnHelper.display({
      cell: ({ row }) => (
        <StyledPhaseContainer>
          {sortPhaseByIndex(row.original.phases).map((phase) => (
            <div key={phase.index}>{convertTemperature(phase.value)}</div>
          ))}
        </StyledPhaseContainer>
      ),
      header: tDeviceDemandResponseActivityTableThermostatAdjustments,
      id: "temperatureAdjustment",
      meta: { classList: ["noPadding", "tableCellCenter"] },
    }),
    columnHelper.display({
      cell: ({ row }) => (
        <StyledPhaseContainer>
          {sortPhaseByIndex(row.original.phases).map((phase) => (
            <div key={phase.index}>
              {convertTimeToHoursAndMinutes(phase.duration, locale)}
            </div>
          ))}
        </StyledPhaseContainer>
      ),
      header: tDeviceDemandResponseActivityTableDuration,
      id: "duration",
      meta: { classList: ["noPadding", "tableCellCenter"] },
    }),
  ];

  const columnVisibility = isMobile
    ? {
        duration: true,
        startAt: false,
        status: false,
        statusAndDate: true,
        temperatureAdjustment: true,
      }
    : {
        duration: true,
        startAt: true,
        status: true,
        statusAndDate: false,
        temperatureAdjustment: true,
      };

  return (
    <MyAccountTable<DeviceDemandResponseActivityType>
      columnVisibility={columnVisibility}
      columns={columns}
      data={demandResponseActivity}
      pageCount={pageCount}
      pageIndex={pageIndex}
      pageSize={pageSize}
      pagination
      rowId="startAt"
      setPagination={setPagination}
    />
  );
};

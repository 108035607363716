import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { selectedDevicesWithPremiseAtom } from "@portal-account/components/DevicePremiseSelector/DevicePremiseSelector.atoms";
import { chooseYourDeviceBrandStepAtom } from "@portal-account/components/DevicesEnrollChooseBrandForm/DevicesEnrollChooseBrandForm.atoms";
import { devicesEnrollChooseBrandFormTranslations } from "@portal-account/components/DevicesEnrollChooseBrandForm/DevicesEnrollChooseBrandForm.en.i18n";
import {
  StyledContainer,
  StyledRadioContainer,
} from "@portal-account/components/DevicesEnrollChooseBrandForm/DevicesEnrollChooseBrandForm.styled";
import { PortalIconRadioField } from "@portal-account/components/PortalIconRadioField/PortalIconRadioField";
import { useTrackMyAccountEvents } from "@portal-account/hooks/useTrackMyAccountEvents";
import { devicesEnrollStepPath } from "@portal-account/routes/routePaths";
import {
  ActionType,
  EventType,
} from "@portal-account/services/segment.service";
import { DeviceBrands } from "@portal-account/types/devicesTypes";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { ReactComponent as Amazon } from "@portal-shared/images/amazon.svg";
import { ReactComponent as Honeywell } from "@portal-shared/images/honeywell.svg";
import { useAtom } from "jotai";
import { useResetAtom } from "jotai/utils";
import React, { useCallback } from "react";

export const DevicesEnrollChooseBrandForm = () => {
  const { featureFlagClient } = useFeatureFlagClient();
  const { translate } = useTranslations();
  const [deviceBrand, setDeviceBrand] = useAtom(chooseYourDeviceBrandStepAtom);
  const resetSelectedDevicesWithPremiseAtom = useResetAtom(
    selectedDevicesWithPremiseAtom
  );
  const track = useTrackMyAccountEvents();

  const { portalDeviceManagementAmazon, portalDeviceManagementHoneywell } =
    featureFlagClient.getFlags([
      ["portalDeviceManagementAmazon", false],
      ["portalDeviceManagementHoneywell", false],
    ]);

  const {
    tDevicesEnrollChooseBrandFormAmazon,
    tDevicesEnrollChooseBrandFormHoneywell,
    tDevicesEnrollChooseBrandFormNext,
  } = translate(devicesEnrollChooseBrandFormTranslations);

  const handleNextClick = useCallback(() => {
    track({
      action: ActionType.click,
      category: "devices.enroll.choose-your-device",
      event: EventType.DeviceBrandSelected,
      value: deviceBrand as string,
    });

    // Ensure there is no previous atom state when user starts flow again
    resetSelectedDevicesWithPremiseAtom();
  }, [deviceBrand, track, resetSelectedDevicesWithPremiseAtom]);

  return (
    <StyledContainer>
      <StyledRadioContainer>
        {portalDeviceManagementAmazon.value ? (
          <PortalIconRadioField
            checked={deviceBrand === DeviceBrands.Amazon}
            handleClick={() => {
              setDeviceBrand(DeviceBrands.Amazon);
            }}
            icon={<Amazon />}
            ariaLabel={tDevicesEnrollChooseBrandFormAmazon}
            name="deviceBrand"
          />
        ) : null}
        {portalDeviceManagementHoneywell.value ? (
          <PortalIconRadioField
            checked={deviceBrand === DeviceBrands.Honeywell}
            handleClick={() => {
              setDeviceBrand(DeviceBrands.Honeywell);
            }}
            icon={<Honeywell />}
            ariaLabel={tDevicesEnrollChooseBrandFormHoneywell}
            name="deviceBrand"
          />
        ) : null}
      </StyledRadioContainer>
      <RhLinkButton
        data-tracking-click={{
          event: "Customer going to thermostat terms and conditions",
        }}
        disabled={!deviceBrand}
        fullWidth
        href={devicesEnrollStepPath("terms-and-conditions")}
        label={tDevicesEnrollChooseBrandFormNext}
        onClick={handleNextClick}
        size="medium"
      >
        {tDevicesEnrollChooseBrandFormNext}
      </RhLinkButton>
    </StyledContainer>
  );
};

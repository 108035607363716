import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import { PaymentMethod } from "@common/models/PaymentMethod.model";
import {
  formatCurrency,
  formatShorthandMonthDayYear,
} from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as LeftChevron } from "@design-system/icons/LeftChevron.svg";
import { SvgIcon } from "@mui/material";
import { paymentExtensionFormAtom } from "@portal-account/components/PaymentExtension/PaymentExtension.atoms";
import { usePaymentExtension } from "@portal-account/components/PaymentExtension/usePaymentExtension";
import { paymentExtensionConfirmTranslations } from "@portal-account/components/PaymentExtensionConfirm/PaymentExtensionConfirm.en.i18n";
import {
  ButtonsContainer,
  LabelText,
  ReviewRow,
  StyledPaymentExtensionConfirmContainer,
  StyledReviewContainer,
  StyledSubmitButton,
  StyledTermsCheckbox,
} from "@portal-account/components/PaymentExtensionConfirm/PaymentExtensionConfirm.styled";
import { useCreatePaymentExtensionMutation } from "@portal-account/mutations/useCreatePaymentExtensionMutation";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import dayjs from "dayjs";
import { useAtomValue } from "jotai";
import React, { useState } from "react";

interface PaymentExtensionConfirmProps {
  disconnectionDueDate: string;
  pastDueBalance: string;
  paymentMethods: PaymentMethod[];
  premiseId: string;
}

export const PaymentExtensionConfirm = ({
  disconnectionDueDate,
  pastDueBalance,
  paymentMethods,
  premiseId,
}: PaymentExtensionConfirmProps) => {
  const { translate } = useTranslations();
  const {
    tPaymentExtensionConfirmReviewAndSubmit,
    tPaymentExtensionConfirmTotalPastDue,
    tPaymentExtensionConfirmDownPaymentDue,
    tPaymentExtensionConfirmExtensionDueDate,
    tPaymentExtensionConfirmPaymentMethod,
    tPaymentExtensionConfirmExtendedBalance,
    tPaymentExtensionConfirmDownPaymentDate,
    tPaymentExtensionConfirmTermsAgree,
    tPaymentExtensionConfirmBack,
    tPaymentExtensionConfirmSubmit,
    tPaymentExtensionConfirmError,
  } = translate(paymentExtensionConfirmTranslations);

  const [termsChecked, setTermsChecked] = useState(false);

  const {
    extendedAmount,
    extensionDueDate,
    handleNextStep,
    handlePreviousStep,
  } = usePaymentExtension({ disconnectionDueDate, pastDueBalance });

  const { downPayment, selectedPaymentId } = useAtomValue(
    paymentExtensionFormAtom
  );

  const paymentMethod = paymentMethods.find(
    (pMethod) => pMethod.id === selectedPaymentId
  );

  const createPaymentExtensionMutation = useCreatePaymentExtensionMutation();
  const flash = useRhFlash();

  const handleCreatePaymentExtension = () => {
    createPaymentExtensionMutation.mutate(
      {
        payload: {
          amountExtended: extendedAmount,
          amountPaid: downPayment,
          extendToDate: dayjs(extensionDueDate).format(ISO8601_DATE_FORMAT),
          paymentMethodId: selectedPaymentId,
        },
        premiseId,
      },
      {
        onError: () => {
          flash.error(tPaymentExtensionConfirmError);
        },
        onSuccess: (data) => handleNextStep({ responseData: data }),
      }
    );
  };

  return (
    <StyledPaymentExtensionConfirmContainer>
      <RhTypography variant="h1" align="center">
        {tPaymentExtensionConfirmReviewAndSubmit}
      </RhTypography>
      <StyledReviewContainer>
        <ReviewRow>
          <LabelText>{tPaymentExtensionConfirmTotalPastDue}</LabelText>
          <RhTypography>{formatCurrency(pastDueBalance)}</RhTypography>
        </ReviewRow>
        <ReviewRow>
          <LabelText>{tPaymentExtensionConfirmDownPaymentDue}</LabelText>
          <RhTypography>{formatCurrency(downPayment)}</RhTypography>
        </ReviewRow>
        <ReviewRow>
          <LabelText>{tPaymentExtensionConfirmDownPaymentDate}</LabelText>
          <RhTypography>
            {formatShorthandMonthDayYear(dayjs().toString())}
          </RhTypography>
        </ReviewRow>
        <ReviewRow>
          <LabelText>{tPaymentExtensionConfirmPaymentMethod}</LabelText>
          {paymentMethod ? (
            <RhTypography>
              {`${paymentMethod.companyName} ${paymentMethod.trimmedMaskedNumber}`}
            </RhTypography>
          ) : null}
        </ReviewRow>
        <RhDivider sx={{ opacity: 0.5, width: "100%" }} />
        <ReviewRow>
          <LabelText>{tPaymentExtensionConfirmExtendedBalance}</LabelText>
          <RhTypography>{formatCurrency(extendedAmount)}</RhTypography>
        </ReviewRow>

        <ReviewRow>
          <LabelText>{tPaymentExtensionConfirmExtensionDueDate}</LabelText>
          <RhTypography align="right">
            {formatShorthandMonthDayYear(extensionDueDate)}
          </RhTypography>
        </ReviewRow>
        <RhDivider sx={{ opacity: 0.5, width: "100%" }} />
        <StyledTermsCheckbox
          label={tPaymentExtensionConfirmTermsAgree}
          name="terms"
          checked={termsChecked}
          onChange={(event) => setTermsChecked(event.target.checked)}
        />
      </StyledReviewContainer>
      <ButtonsContainer>
        <StyledSubmitButton
          data-tracking-click={{
            event: "Create payment extension clicked",
          }}
          disabled={!termsChecked || createPaymentExtensionMutation.isPending}
          onClick={handleCreatePaymentExtension}
          startIcon={
            createPaymentExtensionMutation.isPending ? (
              <RhCircularProgress size={16} marginBottom={0} marginTop={0} />
            ) : null
          }
        >
          {tPaymentExtensionConfirmSubmit}
        </StyledSubmitButton>
        <RhButton
          data-tracking-click={{
            event: "Customer going back to Payment Extension 1st step",
          }}
          aria-label={tPaymentExtensionConfirmBack}
          role="link"
          onClick={handlePreviousStep}
          color="primary"
          variant="text"
          startIcon={
            <SvgIcon
              aria-hidden
              viewBox="5 0 47 90"
              fill="none"
              component={LeftChevron}
              fontSize="inherit"
            />
          }
        >
          {tPaymentExtensionConfirmBack}
        </RhButton>
      </ButtonsContainer>
    </StyledPaymentExtensionConfirmContainer>
  );
};

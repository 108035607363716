import { IdType } from "@common/types/apiTypes";
import {
  PaymentExtensionFormValues,
  PaymentExtensionSteps,
} from "@portal-account/components/PaymentExtension/PaymentExtension.types";
import { CreatePaymentExtensionResponseType } from "@portal-account/types/paymentMethodTypes";
import { atom } from "jotai";

export const paymentExtensionFormAtom = atom<PaymentExtensionFormValues>({
  downPayment: "",
  selectedPaymentId: "",
});

export const paymentExtensionActiveStep = atom<PaymentExtensionSteps>(
  PaymentExtensionSteps.form
);

export const createPaymentExtensionResponseAtom =
  atom<CreatePaymentExtensionResponseType>({
    amountExtended: "",
    amountPaid: "",
    extendToDate: "",
    id: "",
    paymentNumber: "",
    premise: "",
  });

export const oneTimePaymentMethodIdAtom = atom<IdType | null>(null);

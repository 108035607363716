import { AccountFooterLoggedOutTranslations } from "@portal-account/components/AccountFooterLoggedOut/AccountFooterLoggedOut.en.i18n";
import {
  StyledAllRightsReservedContainer,
  StyledAllRightsReservedLoggedOut,
  StyledUnauthenticatedContainer,
} from "@portal-account/components/AccountFooterLoggedOut/AccountFooterLoggedOut.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

export const AccountFooterLoggedOut = () => {
  const { translate } = useTranslations();

  const { tAccountFooterLoggedOutAllRightsReserved } = translate(
    AccountFooterLoggedOutTranslations
  );

  return (
    <StyledUnauthenticatedContainer>
      <StyledAllRightsReservedContainer>
        <StyledAllRightsReservedLoggedOut
          color="textSecondary"
          align="center"
          variant="body2"
        >
          {tAccountFooterLoggedOutAllRightsReserved}
        </StyledAllRightsReservedLoggedOut>
      </StyledAllRightsReservedContainer>
    </StyledUnauthenticatedContainer>
  );
};

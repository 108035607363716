import { ediApi } from "@common/api/ediApi";
import { prospectApi } from "@common/api/prospectApi";
import { FeatureFlagMetric } from "@common/components/FeatureFlagClientProvider/FeatureFlagClientProvider.types";
import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { determineCreditEvaluationDefinition } from "@common/services/creditEvaluation.service";
import { CreditScoreOutcome } from "@common/types/creditCheckTypes";
import { AddressFormType } from "@common/types/customerTypes";
import {
  AREA_NOT_SERVICED,
  FormError,
  IRONHIDE_CUSTOMER,
  METER_NOT_SERVICEABLE,
  MULTIPLE_METERS,
  PRICING_OFFERS_MULTIPLE_UTILITIES,
  RhApiError,
} from "@common/types/errorTypes";
import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useProspectUpdateMutation } from "@enroll-data/hooks/mutations/useProspectUpdate.mutation";
import { enrollOffersPath } from "@enroll-utils/constants/routePaths";
import { IronhideCustomerMessage } from "@portal-enroll/components/IronhideCustomerMessage/IronhideCustomerMessage";
import { SignUpPageLayout } from "@portal-enroll/components/SignUpPageLayout/SignUpPageLayout";
import { SignUpPremiseFields } from "@portal-enroll/components/SignUpPremiseFields/SignUpPremiseFields";
import { DUNS_NOT_FOUND } from "@portal-enroll/constants/offer.constant";
import { useSignUpFlow } from "@portal-enroll/hooks/useSignUpFlow";
import { signUpPremisePageTranslations } from "@portal-enroll/pages/SignUpPremisePage/SignUpPremisePage.en.i18n";
import {
  selectCreditCheckContactValues,
  selectProspectId,
  selectSignUpAcquisition,
  selectSignUpPremiseFormValues,
  selectSignUpState,
} from "@portal-enroll/selectors/signUpSelectors";
import {
  ActionType,
  CategoryType,
  EnrollmentEvents,
  track,
} from "@portal-enroll/services/segment.service";
import { SignUpStoreState } from "@portal-enroll/slices/signUpSlice";
import { LoggedOutForm } from "@portal-shared/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal-shared/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { ESI_ID_NOT_FOUND } from "@portal-shared/constants/offer.constant";
import { isValidUSAddress } from "@portal-shared/forms/validators";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { FORM_ERROR } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const signUpPremiseFormValidator =
  generateValidationErrorCollector<SignUpPremiseFormValues>({
    serviceAddress: isValidUSAddress,
  });

export interface SignUpPremiseFormValues {
  additionalInformationRequired: boolean;
  creditEvaluation: CreditScoreOutcome | null;
  depositAlternativeAmount: string | null;
  depositAlternativeElected: boolean;
  depositAmount: number | null;
  dunsNumber: string;
  esiId: string | null;
  serviceAddress: AddressFormType;
  ssnRequired: boolean;
}

const MULTIPLE_METER_ERRORS = new Set([
  MULTIPLE_METERS,
  PRICING_OFFERS_MULTIPLE_UTILITIES,
]);

export const SignUpPremisePage = () => {
  const flash = useRhFlash();
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const { announceError } = useRhAnnouncement();
  const navigate = useNavigate();

  const currentValues = useSelector<SignUpStoreState, SignUpPremiseFormValues>(
    selectSignUpPremiseFormValues
  );
  const prospectId = useSelector(selectProspectId);
  const customerContactValues = useSelector(selectCreditCheckContactValues);
  const { dunsNumber: currentDunsNumber } = useSelector(selectSignUpState);
  const { acquisitionMedium } = useSelector(selectSignUpAcquisition);
  const { featureFlagClient } = useFeatureFlagClient();
  const prospectUpdateMutation = useProspectUpdateMutation();

  const { translate } = useTranslations();
  const {
    tSignUpPremisePagePlansHaveChanged,
    tSignUpPremisePageSubmitCta,
    tSignUpPremisePageUnknownErrorApiMeterAvailability,
    tSignUpPremisePageWhereShouldWeSendEnergy,
  } = translate(signUpPremisePageTranslations);

  const onSubmit = async ({
    serviceAddress,
  }: SignUpPremiseFormValues): Promise<void | FormError> => {
    try {
      const { esiId, dunsNumber } = await ediApi.meterAvailability({
        city: serviceAddress.city,
        secondary: serviceAddress.unitNumber,
        state: serviceAddress.state,
        streetLine: serviceAddress.addressLine1,
        zipcode: serviceAddress.zipCode,
      });

      if (dunsNumber !== currentDunsNumber) {
        // the user changed their address enough that
        // we the current offers they were given are no
        // longer valid.
        // So send the user back to choose a new plan
        await prospectUpdateMutation.mutateAsync({
          data: {
            zipCode: serviceAddress.zipCode,
          },
        });

        flash.error(tSignUpPremisePagePlansHaveChanged);
        navigate(enrollOffersPath());
      } else if (esiId) {
        const {
          depositAmount,
          depositAlternativeAmount,
          outcome,
          ssnRequired,
          nosRequired,
          additionalInformationRequired,
        } = await prospectApi.creditScoreEvaluation({
          ...customerContactValues,
          ...serviceAddress,
          acquisitionMedium,
          prospectId,
        });

        await prospectApi.trackEnteredSignup(
          prospectId,
          customerContactValues.email
        );

        if (outcome) {
          const deposit = {
            depositAlternativeAmount: depositAlternativeAmount ?? "0",
            depositAmount: depositAmount ?? 0,
            depositRequired: Boolean(depositAmount),
          };

          track({
            action: ActionType.clickedNextPage,
            category: "address.creditCheckComplete",
            event: EnrollmentEvents.enrollmentClick,
            label: "Credit Worthiness",
            value: determineCreditEvaluationDefinition({
              creditEvaluation: outcome,
              depositAmount,
              ssnRequired,
            }),
            ...deposit,
            additionalInformationRequired,
            nosRequired,
            ssnRequired,
          });
        }

        track({
          action: ActionType.premiseSubmitted,
          category: CategoryType.Premise,
          event: EnrollmentEvents.enrollmentClick,
          label: "Premise Information",
          ...serviceAddress,
        });

        featureFlagClient.trackMetric(
          FeatureFlagMetric.PortalProspectServiceAddressSubmitted
        );

        signUpClickNextStepHandler({
          nextStep: "details",
          signUpData: {
            additionalInformationRequired,
            creditEvaluation: outcome,
            depositAlternativeAmount,
            depositAmount,
            dunsNumber,
            esiId,
            nosRequired,
            serviceAddress,
            ssnRequired,
          },
          track: true,
        });
      } else {
        signUpClickNextStepHandler({
          nextStep: "confirm-address",
          signUpData: {
            dunsNumber,
            esiId: ESI_ID_NOT_FOUND,
            serviceAddress,
          },
          track: true,
        });
      }
    } catch (caughtError: unknown) {
      const error = caughtError as RhApiError;

      if (
        error.data.errorCode === AREA_NOT_SERVICED ||
        error.data.errorCode === METER_NOT_SERVICEABLE
      ) {
        await prospectUpdateMutation.mutateAsync({
          data: {
            zipCode: serviceAddress.zipCode,
          },
        });

        navigate(enrollOffersPath());
      } else if (MULTIPLE_METER_ERRORS.has(error.data.errorCode || "")) {
        signUpClickNextStepHandler({
          nextStep: "confirm-address",
          signUpData: {
            dunsNumber: DUNS_NOT_FOUND,
            esiId: ESI_ID_NOT_FOUND,
            serviceAddress,
          },
          track: true,
        });
      } else if (error.data.errorCode === IRONHIDE_CUSTOMER) {
        announceError(<IronhideCustomerMessage />);
      } else {
        flash.error(tSignUpPremisePageUnknownErrorApiMeterAvailability);

        return {
          [FORM_ERROR]: [tSignUpPremisePageUnknownErrorApiMeterAvailability],
        };
      }
    }
  };

  return (
    <SignUpPageLayout>
      <LoggedOutPageHeader
        headerText={tSignUpPremisePageWhereShouldWeSendEnergy}
      />
      <Form<SignUpPremiseFormValues>
        initialValues={currentValues}
        onSubmit={onSubmit}
        validate={signUpPremiseFormValidator}
        render={({ handleSubmit, values, valid }) => (
          <LoggedOutForm
            onSubmit={handleSubmit}
            submitButtonText={tSignUpPremisePageSubmitCta}
          >
            <SignUpPremiseFields trackEvent={trackEvent} />
          </LoggedOutForm>
        )}
      />
    </SignUpPageLayout>
  );
};

import { GuestFeatureFlagClientProvider } from "@portal-guest/components/GuestFeatureFlagClientProvider/GuestFeatureFlagClientProvider";
import { GuestGlobalLogic } from "@portal-guest/components/GuestGlobalLogic/GuestGlobalLogic";
import { GuestHeader } from "@portal-guest/components/GuestHeader/GuestHeader";
import { AwsLoggedOutConnectHelmet } from "@portal-shared/components/AwsLoggedOutConnectHelmet/AwsLoggedOutConnectHelmet";
import { LoggedOutFooter } from "@portal-shared/components/LoggedOutFooter/LoggedOutFooter";
import { PortalContent } from "@portal-shared/components/PortalContent/PortalContent.styled";
import { PortalSharedMainContainer } from "@portal-shared/components/PortalSharedMainContainer/PortalSharedMainContainer";
import { SessionCheck } from "@portal-shared/components/SessionCheck/SessionCheck";
import { SessionManager } from "@portal-shared/components/SessionManager/SessionManager";
import { SharedModalsManager } from "@portal-shared/components/SharedModalsManager/SharedModalsManager";
import React from "react";
import { Outlet } from "react-router-dom";

export const GuestRouteProvidersWithOutlet = () => {
  return (
    <SessionManager>
      <GuestFeatureFlagClientProvider>
        <GuestGlobalLogic />
        <SharedModalsManager />
        <AwsLoggedOutConnectHelmet />
        <PortalSharedMainContainer>
          <GuestHeader />
          <PortalContent>
            <SessionCheck secured={false}>
              <Outlet />
            </SessionCheck>
          </PortalContent>
          <LoggedOutFooter />
        </PortalSharedMainContainer>
      </GuestFeatureFlagClientProvider>
    </SessionManager>
  );
};

import { RhStepper } from "@design-system/components/RhStepper/RhStepper";
import { DevicesEnrollStepperIcon } from "@portal-account/components/DevicesEnrollStepper/DevicesEnrollStepperIcon";
import { useDevicesEnrollStepperSteps } from "@portal-account/components/DevicesEnrollStepper/useDevicesEnrollStepperSteps";
import { DevicesEnrollStepType } from "@portal-account/routes/routePaths";
import React from "react";

interface DevicesEnrollStepperProps {
  step: DevicesEnrollStepType;
}

export const DevicesEnrollStepper = (props: DevicesEnrollStepperProps) => {
  const { step } = props;
  const devicesEnrollStepperKeysInOrder = useDevicesEnrollStepperSteps();

  const activeStepIndex = devicesEnrollStepperKeysInOrder.findIndex(
    (s) => s.id === step
  );

  return (
    <RhStepper<DevicesEnrollStepType>
      activeStepIndex={activeStepIndex}
      stepperKeysInOrder={devicesEnrollStepperKeysInOrder}
      stepperIcon={DevicesEnrollStepperIcon}
    />
  );
};

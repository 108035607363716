import { SummaryLabelCell } from "@portal-account/components/SummaryInfo/SumaryLabelCell";
import { StyledSummaryInfoContainer } from "@portal-account/components/SummaryInfo/SummaryInfo.styled";
import { SummaryValueCell } from "@portal-account/components/SummaryInfo/SummaryValueCell";
import React, { ReactNode } from "react";

interface SummaryInfoProps {
  label: ReactNode;
  value: ReactNode;
}

export const SummaryInfo = (props: SummaryInfoProps) => {
  const { label, value } = props;

  return (
    <StyledSummaryInfoContainer>
      <SummaryLabelCell>{label}</SummaryLabelCell>
      <SummaryValueCell>{value}</SummaryValueCell>
    </StyledSummaryInfoContainer>
  );
};

import { api } from "@common/api/api";
import { ChangePasswordResponseType } from "@common/api/customerResponseTypes";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { FormError, OKTA_REQUEST_ERROR } from "@common/types/errorTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhSubmitButton } from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import { myAccountPasswordChangeTranslations } from "@portal-account/components/MyAccountPasswordChange/MyAccountPasswordChange.en.i18n";
import {
  MyAccountPasswordChangeFieldsContainer,
  MyAccountPasswordChangePasswordRequirements,
} from "@portal-account/components/MyAccountPasswordChange/MyAccountPasswordChange.styled";
import { PortalPasswordField } from "@portal-account/components/PortalPasswordField/PortalPasswordField";
import { useTrackMyAccountEvents } from "@portal-account/hooks/useTrackMyAccountEvents";
import { Customer } from "@portal-account/models/Customer.model";
import { MyAccountEvents } from "@portal-account/services/segment.service";
import {
  PortalCard,
  PortalCardHeader,
  PortalCardTitle,
} from "@portal-shared/components/PortalCard/PortalCard.styled";
import {
  isEqual,
  isNotEqual,
  isRequired,
} from "@portal-shared/forms/validators";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { FormApi } from "final-form";
import React from "react";
import { Form } from "react-final-form";

type FinalFormSubmitFunction<T> = (
  values: T,
  formApi: FormApi<T>
) => Promise<void | FormError>;

type MyAccountPasswordChangeProps = {
  customer: Customer;
};

export type MyAccountPasswordChangeFormValues = {
  newPassword: string;
  newPasswordConfirmation: string;
  oldPassword: string;
};

const passwordChangeFormInitialValues =
  Object.freeze<MyAccountPasswordChangeFormValues>({
    newPassword: "",
    newPasswordConfirmation: "",
    oldPassword: "",
  });

export const MyAccountPasswordChange = ({
  customer,
}: MyAccountPasswordChangeProps) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));
  const { translate } = useTranslations();
  const flash = useRhFlash();
  const track = useTrackMyAccountEvents();

  const {
    tMyAccountPasswordChangeConfirmNewPassword,
    tMyAccountPasswordChangeCurrentPassword,
    tMyAccountPasswordChangeMinimumRequirements,
    tMyAccountPasswordChangeNewPassword,
    tMyAccountPasswordChangeNewPasswordsMustMatch,
    tMyAccountPasswordChangeNonOktaErrorUpdatingPassword,
    tMyAccountPasswordChangeNotNewPassword,
    tMyAccountPasswordChangeOktaRequestFailed,
    tMyAccountPasswordChangeSubmitButtonCta,
    tMyAccountPasswordChangeSuccess,
    tMyAccountPasswordChangeTitle,
  } = translate(myAccountPasswordChangeTranslations);

  const myAccountPasswordChangeFormValidator =
    generateValidationErrorCollector<MyAccountPasswordChangeFormValues>({
      newPassword: [
        isRequired,
        (value, { oldPassword }) =>
          isNotEqual<string>(
            value,
            oldPassword,
            tMyAccountPasswordChangeNotNewPassword
          ),
      ],
      newPasswordConfirmation: [
        isRequired,
        (value, { newPassword }) =>
          isEqual<string>(
            value,
            newPassword,
            tMyAccountPasswordChangeNewPasswordsMustMatch
          ),
      ],
      oldPassword: [isRequired],
    });

  const onSubmit: FinalFormSubmitFunction<
    MyAccountPasswordChangeFormValues
  > = async (values) => {
    const [error] = await handleAjaxCall<ChangePasswordResponseType>(
      api.customers.password.change({
        confirmNewPassword: values.newPasswordConfirmation,
        id: customer.id,
        newPassword: values.newPassword,
        oldPassword: values.oldPassword,
      })
    );

    if (error) {
      const errorType = error.data.errorCode;

      if (errorType === OKTA_REQUEST_ERROR) {
        return {
          newPassword: [tMyAccountPasswordChangeOktaRequestFailed],
        } as FormError;
      } else {
        flash.error(tMyAccountPasswordChangeNonOktaErrorUpdatingPassword);
      }
    } else {
      flash.success(tMyAccountPasswordChangeSuccess);

      track({
        event: MyAccountEvents.passwordUpdated,
        label: "Updated password",
      });
    }
  };

  return (
    <PortalCard>
      <PortalCardHeader>
        <PortalCardTitle>{tMyAccountPasswordChangeTitle}</PortalCardTitle>
      </PortalCardHeader>
      <Form<MyAccountPasswordChangeFormValues>
        initialValues={passwordChangeFormInitialValues}
        onSubmit={onSubmit}
        validate={myAccountPasswordChangeFormValidator}
        render={({ handleSubmit, form }) => (
          <form
            noValidate
            onSubmit={handleSubmit}
            data-testid="myAccountPasswordChangeForm"
          >
            <MyAccountPasswordChangeFieldsContainer>
              <PortalPasswordField name="oldPassword">
                {tMyAccountPasswordChangeCurrentPassword}
              </PortalPasswordField>
              <Box>
                <PortalPasswordField name="newPassword">
                  {tMyAccountPasswordChangeNewPassword}
                </PortalPasswordField>
                <MyAccountPasswordChangePasswordRequirements
                  variant="body2"
                  color="textSecondary"
                >
                  {tMyAccountPasswordChangeMinimumRequirements}
                </MyAccountPasswordChangePasswordRequirements>
              </Box>
              <PortalPasswordField name="newPasswordConfirmation">
                {tMyAccountPasswordChangeConfirmNewPassword}
              </PortalPasswordField>
            </MyAccountPasswordChangeFieldsContainer>
            <RhFlexBox justifyContent="flex-end">
              <RhSubmitButton
                data-tracking-click={{
                  event: "Customer changing password",
                }}
                fullWidth={isMobile}
                disallowPristineSubmit
                restartOnSuccess
              >
                {tMyAccountPasswordChangeSubmitButtonCta}
              </RhSubmitButton>
            </RhFlexBox>
          </form>
        )}
      />
    </PortalCard>
  );
};

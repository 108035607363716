import { premiseApi } from "@portal-account/api/premiseApi";
import { premiseQueryKeys } from "@portal-account/queryKeys/premiseQueryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreatePaymentExtensionMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: premiseApi.paymentExtensions,
    onSuccess: (_, { premiseId }) => {
      queryClient.invalidateQueries({
        queryKey: premiseQueryKeys.retrieve(premiseId),
      });
    },
  });

  return mutation;
};

import { IdType } from "@common/types/apiTypes";
import { CustomerType } from "@common/types/customerTypes";
import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";

export type CustomerPreferencesStateType = {
  premiseId: IdType | null;
};

export const initialCustomerPreferencesState =
  Object.freeze<CustomerPreferencesStateType>({
    premiseId: null,
  });

// Handlers

export const premisePreferenceUpdatedHandler: CaseReducer<
  CustomerPreferencesStateType,
  PayloadAction<IdType>
> = (state, action) => ({
  ...state,
  premiseId: action.payload,
});

export const clearCustomerPreferenceHandler: CaseReducer<
  CustomerPreferencesStateType,
  PayloadAction
> = (state, action) => {
  return { ...state, ...initialCustomerPreferencesState };
};

export const receivedCustomerHandler: CaseReducer<
  CustomerPreferencesStateType,
  PayloadAction<CustomerType>
> = (state, action) => {
  const preferencesUpdated: Partial<CustomerPreferencesStateType> = {};

  if (!state.premiseId && action.payload.premises) {
    preferencesUpdated.premiseId = action.payload.premises[0].id;
  }
  return { ...state, ...preferencesUpdated };
};

const customerPreferencesReducers = {
  clearPreferences: clearCustomerPreferenceHandler,
  premiseUpdated: premisePreferenceUpdatedHandler,
};

const customerPreferenceSlice = createSlice({
  initialState: initialCustomerPreferencesState,
  name: "customerPreferences",
  reducers: customerPreferencesReducers,
});

export const customerPreferencesReducer = customerPreferenceSlice.reducer;

export const customerPreferencesPremiseUpdated =
  customerPreferenceSlice.actions.premiseUpdated;
export const customerPreferencesCleared =
  customerPreferenceSlice.actions.clearPreferences;

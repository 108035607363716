import { featureFlagsApi } from "@common/api/featureFlagsApi";
import { FeatureFlagContext } from "@common/components/FeatureFlagClientProvider/FeatureFlagClientProvider.context";
import {
  checkAllFlagsExistsInLaunchDarkly,
  fetchAllFlags,
  sendTrackMetric,
} from "@common/components/FeatureFlagClientProvider/FeatureFlagClientProvider.helpers";
import {
  FeatureFlagMetric,
  FeatureFlagSet,
  FeatureFlagValues,
  RhFlag,
  RhFlags,
  ValidFlagValues,
} from "@common/components/FeatureFlagClientProvider/FeatureFlagClientProvider.types";
import { useScreenResolution } from "@common/hooks/useScreenResolution";
import { isBot } from "@common/utils/isBot";
import { decamelize } from "humps";
import isEqual from "lodash/isEqual";
import React, { useCallback, useEffect, useMemo, useState } from "react";

interface FeatureFlagClientProviderProps {
  children: React.ReactNode;
  featureFlagUserId?: string | null;
  sendEvents?: boolean;
}

let firstTimeIdentify = true;

export const FeatureFlagClientProvider = ({
  children,
  featureFlagUserId,
  sendEvents = true,
}: FeatureFlagClientProviderProps) => {
  const [fetchedFlags, setFetchedFlags] = useState<FeatureFlagSet>({});
  const [fetchingFlags, setFetchingFlags] = useState<boolean>(true);
  const screenResolution = useScreenResolution();
  const sendFeatureFlagEvents = !isBot() && featureFlagUserId && sendEvents;
  const [currentCustomIdentifyProps, setCurrentCustomIdentifyProps] = useState<
    Record<string, unknown>
  >({});

  const sendIdentifyEvent = useCallback(
    async (custom?: Record<string, unknown>): Promise<boolean> => {
      if (featureFlagUserId) {
        try {
          const newCustomIdentifyProps = {
            ...currentCustomIdentifyProps,
            screenResolution,
            ...custom,
          };

          // Avoid creating new currentCustomIdentifyProps when unnecessary,
          // otherwise, this will trigger an unnecessary fetch flags event
          if (
            firstTimeIdentify ||
            !isEqual(currentCustomIdentifyProps, newCustomIdentifyProps)
          ) {
            firstTimeIdentify = false;
            await featureFlagsApi.user.identify({
              custom: newCustomIdentifyProps,
              key: featureFlagUserId,
            });

            setCurrentCustomIdentifyProps(() => newCustomIdentifyProps);

            return true;
          }
        } catch (error) {
          return false;
        }
      }

      return Promise.resolve(false);
    },
    [currentCustomIdentifyProps, featureFlagUserId, screenResolution]
  );

  // Identify user
  useEffect(() => {
    if (sendFeatureFlagEvents) {
      (async () => {
        const identified = await sendIdentifyEvent(currentCustomIdentifyProps);

        if (identified) {
          // eslint-disable-next-line no-console
          console.info(
            `[Feature flags] - Feature flag user (${featureFlagUserId}) identified successfully.`
          );
        }
      })();
    }
  }, [
    currentCustomIdentifyProps,
    sendFeatureFlagEvents,
    sendIdentifyEvent,
    featureFlagUserId,
  ]);

  // Fetch all flags
  useEffect(() => {
    if (sendFeatureFlagEvents) {
      (async () => {
        const allFlags = await fetchAllFlags(
          featureFlagUserId,
          currentCustomIdentifyProps,
          setFetchingFlags
        );

        setFetchedFlags(allFlags.flags);
      })();
    }
  }, [sendFeatureFlagEvents, featureFlagUserId, currentCustomIdentifyProps]);

  const sendRecordVisitor = useCallback(
    async (flagName: string): Promise<boolean> => {
      const kebabCaseFlagName = decamelize(flagName, {
        separator: "-",
      });

      if (featureFlagUserId) {
        try {
          await featureFlagsApi.user.recordVisitor(kebabCaseFlagName, {
            custom: currentCustomIdentifyProps,
            key: featureFlagUserId,
          });

          return true;
        } catch {
          return false;
        }
      }

      return Promise.resolve(false);
    },
    [currentCustomIdentifyProps, featureFlagUserId]
  );

  const getFlags = useCallback(
    (flagNames: [keyof FeatureFlagValues, ValidFlagValues][]) => {
      if (Object.keys(fetchedFlags).length) {
        checkAllFlagsExistsInLaunchDarkly(
          fetchedFlags,
          flagNames.map(([flagName]) => flagName)
        );
      }

      return flagNames.reduce<RhFlags>(
        (accumulator, [currentFlag, currentDefault]) => {
          const currentValue = fetchedFlags[currentFlag];
          let rhFlag: RhFlag;

          if (!sendFeatureFlagEvents || currentValue === undefined) {
            rhFlag = {
              label: currentFlag,
              recordVisitor: () => {},
              value: currentDefault,
            };
          } else {
            rhFlag = {
              label: currentFlag,
              recordVisitor: async () => {
                const flagRecorded = await sendRecordVisitor(currentFlag);

                if (flagRecorded) {
                  // eslint-disable-next-line no-console
                  console.info(
                    `[Feature flags] - Feature flag (${currentFlag}) recorded successfully for user (${featureFlagUserId})`
                  );
                }
              },
              value: currentValue,
            };
          }

          return {
            ...accumulator,
            [currentFlag]: rhFlag,
          };
        },
        {} as RhFlags
      );
    },
    [featureFlagUserId, fetchedFlags, sendFeatureFlagEvents, sendRecordVisitor]
  );

  const trackMetric = useCallback(
    async (metric: FeatureFlagMetric) => {
      if (sendFeatureFlagEvents) {
        const sentMetric = await sendTrackMetric(featureFlagUserId, metric);

        if (sentMetric) {
          // eslint-disable-next-line no-console
          console.info(
            `[Feature flags] - metric (${metric}) sent successfully for user (${featureFlagUserId})`
          );
        }
      }
    },
    [featureFlagUserId, sendFeatureFlagEvents]
  );

  const isFetchingFlags = useCallback((): boolean => {
    return fetchingFlags;
  }, [fetchingFlags]);

  const featureFlagClient = useMemo(
    () => ({
      featureFlagClient: {
        getFlags,
        isFetchingFlags,
        sendIdentifyEvent,
        trackMetric,
      },
    }),
    [getFlags, trackMetric, sendIdentifyEvent, isFetchingFlags]
  );

  return (
    <FeatureFlagContext.Provider value={featureFlagClient}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

import { api } from "@common/api/api";
import { RhApiError } from "@common/types/errorTypes";
import { SESSION_STORAGE_CUSTOMER_ID_KEY } from "@portal-account/components/CustomerProvider/CustomerProvider.constants";
import {
  rewardsErrored,
  rewardsReceived,
  rewardsRefetch,
  rewardsRequested,
} from "@portal-account/slices/rewardsSlice";
import { SagaReturnType, all, call, put, takeLatest } from "redux-saga/effects";

export function* refetchRewardsWorker() {
  const sessionStorageCustomerId = sessionStorage.getItem(
    SESSION_STORAGE_CUSTOMER_ID_KEY
  );

  if (!sessionStorageCustomerId) {
    return;
  }

  const customerId: string = JSON.parse(sessionStorageCustomerId);

  if (customerId) {
    yield put(rewardsRequested());
    try {
      const rewards: SagaReturnType<typeof api.customers.rewards> = yield call(
        api.customers.rewards,
        customerId
      );

      yield put(rewardsReceived(rewards));
    } catch (error: unknown) {
      yield put(rewardsErrored(error as RhApiError));
    }
  }
}

export function* rewardsWatcher() {
  yield all([takeLatest(rewardsRefetch.type, refetchRewardsWorker)]);
}

import { RhSubmitButton } from "@design-system/components/RhSubmitButton/RhSubmitButton";
import { RhTextField } from "@design-system/components/RhTextField/RhTextField";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { forgotPasswordPageTranslations } from "@portal-account/pages/ForgotPasswordPage/ForgotPasswordPage.en.i18n";
import { LoggedOutFieldsLayout } from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";
import * as RFF from "react-final-form";

export const ForgotPasswordFields = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));

  const { submitSucceeded } = RFF.useFormState({
    subscription: {
      submitSucceeded: true,
    },
  });

  const { translate } = useTranslations();

  const {
    tForgotPasswordPageEmail,
    tForgotPasswordPageNextCTA,
    tForgotPasswordPageSubmitted,
  } = translate(forgotPasswordPageTranslations);

  return submitSucceeded ? (
    <LoggedOutFieldsLayout>
      <RhTypography variant="subtitle1" align="center" color="primary">
        {tForgotPasswordPageSubmitted}
      </RhTypography>
    </LoggedOutFieldsLayout>
  ) : (
    <>
      <LoggedOutFieldsLayout>
        <RhTextField name="email" type="email">
          {tForgotPasswordPageEmail}
        </RhTextField>
      </LoggedOutFieldsLayout>
      <RhSubmitButton
        data-tracking-click={{ event: "Customer clicking forgot password" }}
        fullWidth
        size={isMobile ? "medium" : "large"}
      >
        {tForgotPasswordPageNextCTA}
      </RhSubmitButton>
    </>
  );
};

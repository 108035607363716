import { premiseApi } from "@common/api/premiseApi";
import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { IdType } from "@common/types/apiTypes";
import { CustomerBillingPreferences } from "@common/types/customerTypes";
import { PremiseSourceType, PremiseType } from "@common/types/premiseTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { AveragedBillingDialog } from "@portal-account/components/AveragedBillingDialog/AveragedBillingDialog";
import { MyAccountAutoPay } from "@portal-account/components/MyAccountAutoPay/MyAccountAutoPay";
import { MyAccountBillingPreferencesCardTranslations } from "@portal-account/components/MyAccountBillingPreferencesCard/MyAccountBillingPreferencesCard.en.i18n";
import { PickYourOwnDueDayBox } from "@portal-account/components/MyAccountBillingPreferencesCard/PickYourOwnDueDayBox";
import { PickYourOwnDueDateDialog } from "@portal-account/components/PickYourOwnDueDateDialog/PickYourOwnDueDateDialog";
import { PreferencesSwitchInput } from "@portal-account/components/PreferencesSwitchInput/PreferencesSwitchInput";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { useTrackMyAccountEvents } from "@portal-account/hooks/useTrackMyAccountEvents";
import { Premise } from "@portal-account/models/Premise.model";
import { customerQueryKeys } from "@portal-account/queryKeys/customerQuerykeys";
import {
  ActionType,
  MyAccountEvents,
} from "@portal-account/services/segment.service";
import {
  PortalCard,
  PortalCardHeader,
  PortalCardTitle,
} from "@portal-shared/components/PortalCard/PortalCard.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { usePortalOfferSnapshotQuery } from "@portal-shared/queries/usePortalOfferSnapshotQuery";
import { useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import styled from "styled-components";

export const TitleText = styled(RhTypography)`
  &.MuiTypography-root {
    font-size: 16px;
    font-weight: ${FontWeight.Bold};
  }
`;

const StyledPortalCard = styled(PortalCard)`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(2)};
`;

export interface MyAccountBillingPreferencesCardProps {
  averagedBillingMonthlyCharge: number | null;
  canPickBillingDueDay: boolean;
  initialPreferences: CustomerBillingPreferences;
}

interface AccountModalStates {
  autoPay: boolean;
  averagedBilling: boolean;
  pickYourOwnDueDay: boolean;
}

interface ShouldShowAveragedBillingToggleUtilityFunctionProps {
  flag: boolean;
  preferences: CustomerBillingPreferences;
  premise: Premise;
}
function shouldShowAveragedBillingToggle({
  flag,
  premise,
  preferences,
}: ShouldShowAveragedBillingToggleUtilityFunctionProps) {
  //  Never show the toggle if flag is off
  if (!flag) {
    return false;
  }

  if (preferences.averagedBilling) {
    // if the customer has already opted in, show the toggle
    return true;
  }

  const {
    source: premiseSource,
    averagedBillingMonthlyCharge,
    hasActiveDpp,
  } = premise;

  if (hasActiveDpp) {
    return false;
  }

  if (premiseSource !== PremiseSourceType.RHYTHM) {
    return false;
  }

  return Boolean(averagedBillingMonthlyCharge);
}

export const MyAccountBillingPreferencesCard = ({
  averagedBillingMonthlyCharge,
  canPickBillingDueDay,
  initialPreferences,
}: MyAccountBillingPreferencesCardProps) => {
  const { translate, translateJsx } = useTranslations();
  const { premise } = usePremiseFromContext();
  const flash = useRhFlash();
  const track = useTrackMyAccountEvents();
  const queryClient = useQueryClient();

  const {
    tMyAccountBillingPreferencesCardAveragedBilling,
    tMyAccountBillingPreferencesCardAveragedBillingUseAverageBilling,
    tMyAccountBillingPreferencesCardError,
    tMyAccountBillingPreferencesCardPreferencesUpdated,
    tMyAccountBillingPreferencesCardTitle,
    tMyAccountBillingPreferencesCardErrorFetching,
  } = translate(MyAccountBillingPreferencesCardTranslations);

  const offerSnapshotQuery = usePortalOfferSnapshotQuery({
    offerSnapshotUuid: premise.currentOrder?.offerSnapshotUuid ?? "",
    queryOptions: {
      enabled: Boolean(premise.currentOrder?.offerSnapshotUuid),
    },
  });

  const { featureFlagClient } = useFeatureFlagClient();
  const { portalAverageBilling, portalPickYourDueDate } =
    featureFlagClient.getFlags([
      ["portalAverageBilling", false],
      ["portalPickYourDueDate", false],
    ]);

  const [modals, setModals] = useState<AccountModalStates>({
    autoPay: false,
    averagedBilling: false,
    pickYourOwnDueDay: false,
  });

  const rhythmPremise: boolean = PremiseSourceType.RHYTHM === premise.source;

  const showPickBillingDueDay: boolean =
    canPickBillingDueDay &&
    Boolean(portalPickYourDueDate.value) &&
    rhythmPremise;

  const [updatingPreferences, setUpdatingPreferences] =
    useState<boolean>(false);
  const [preferences, setPreferences] = useState<CustomerBillingPreferences>({
    ...initialPreferences,
  });
  const showAveragedBillingToggle: boolean = shouldShowAveragedBillingToggle({
    flag: Boolean(portalAverageBilling.value),
    preferences,
    premise,
  });

  const updateAveragedBilling = (value: boolean) => {
    track({
      action: ActionType.click,
      event: MyAccountEvents.averageBillingToggle,
      label: "Toggled average billing",
      value,
    });

    updatePremisePreferences<boolean>(
      premiseApi.setAveragedBilling,
      "averagedBilling",
      value
    );
  };

  const updatePickedBillingDueDay = (value: number) => {
    track({
      event: MyAccountEvents.billingDueDateSelected,
      label: "Selected billing due date",
    });

    updatePremisePreferences<number>(
      premiseApi.setPickedBillingDueDay,
      "pickedBillingDueDay",
      value
    );
  };

  const updatePremisePreferences = async <T,>(
    endpoint: (id: IdType, value: T) => Promise<PremiseType>,
    name: keyof CustomerBillingPreferences,
    value: T
  ) => {
    if (updatingPreferences) {
      return;
    }
    setUpdatingPreferences(true);

    const oldPreferences = { ...preferences };

    // automatically switch the toggle so user perceives no lagging while endpoint is resolving
    // if there is an error, then just switch it back to its original state
    setPreferences({
      ...preferences,
      [name]: value,
    });
    const [error] = await handleAjaxCall<PremiseType>(
      endpoint(premise.id, value)
    );

    if (error) {
      setPreferences(oldPreferences);
      flash.error(tMyAccountBillingPreferencesCardError);
    } else {
      queryClient.invalidateQueries({
        queryKey: customerQueryKeys.me(),
      });
      flash.success(tMyAccountBillingPreferencesCardPreferencesUpdated);
    }

    setUpdatingPreferences(false);
  };

  const toggleModalOpen = (
    modalName: keyof AccountModalStates,
    isOpen: boolean
  ) => {
    setModals({
      ...modals,
      [modalName]: isOpen,
    });
  };

  const allToggleProps = { disabled: updatingPreferences };

  const handleToggleAveragedBilling = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ): void => {
    if (checked) {
      toggleModalOpen("averagedBilling", true);
    } else {
      updateAveragedBilling(false);
    }
  };

  if (offerSnapshotQuery.isPending) {
    return (
      <PortalCard>
        <PortalCardHeader>
          <PortalCardTitle>
            {tMyAccountBillingPreferencesCardTitle}
          </PortalCardTitle>
        </PortalCardHeader>
        <RhCircularProgress />
      </PortalCard>
    );
  }

  if (offerSnapshotQuery.isError) {
    return (
      <PortalCard>
        <PortalCardHeader>
          <PortalCardTitle>
            {tMyAccountBillingPreferencesCardTitle}
          </PortalCardTitle>
        </PortalCardHeader>
        <RhTypography>
          {tMyAccountBillingPreferencesCardErrorFetching}
        </RhTypography>
      </PortalCard>
    );
  }

  const offerSnapshot = offerSnapshotQuery.data;

  const { tMyAccountBillingPreferencesCardAutoPayDisabledWarning } =
    translateJsx({
      tMyAccountBillingPreferencesCardAutoPayDisabledWarning: {
        discountAmount: formatCurrency(
          offerSnapshot.autopayPaperlessMonthlyDiscount ?? 0
        ),
      },
    });

  const showAutoPayWarning =
    offerSnapshot.isAutoPayPaperlessDiscountOffer && !premise.autopay;

  return (
    <>
      <StyledPortalCard>
        <PortalCardHeader>
          <TitleText>{tMyAccountBillingPreferencesCardTitle}</TitleText>

          {showAutoPayWarning ? (
            <RhTypography variant="body1" color="textSecondary">
              {tMyAccountBillingPreferencesCardAutoPayDisabledWarning}
            </RhTypography>
          ) : null}
        </PortalCardHeader>

        {showAveragedBillingToggle && (
          <PreferencesSwitchInput
            {...allToggleProps}
            name="averagedBilling"
            checked={preferences.averagedBilling}
            onChange={handleToggleAveragedBilling}
            label={tMyAccountBillingPreferencesCardAveragedBilling}
            detail={
              tMyAccountBillingPreferencesCardAveragedBillingUseAverageBilling
            }
          />
        )}
        {showPickBillingDueDay && (
          <PickYourOwnDueDayBox
            name="pickBillingDueDay"
            pickedBillingDueDay={preferences.pickedBillingDueDay}
            openModal={() => toggleModalOpen("pickYourOwnDueDay", true)}
          />
        )}

        <MyAccountAutoPay premise={premise} offerSnapshot={offerSnapshot} />
      </StyledPortalCard>

      {modals.averagedBilling && (
        <AveragedBillingDialog
          averagedBillingMonthlyCharge={
            averagedBillingMonthlyCharge?.toString() || ""
          }
          isOpen={modals.averagedBilling}
          onClose={() => toggleModalOpen("averagedBilling", false)}
          onSubmit={() => {
            updateAveragedBilling(true);
            toggleModalOpen("averagedBilling", false);
          }}
        />
      )}

      {modals.pickYourOwnDueDay && (
        <PickYourOwnDueDateDialog
          onClose={() => toggleModalOpen("pickYourOwnDueDay", false)}
          onSubmit={(value: number | null) => {
            toggleModalOpen("pickYourOwnDueDay", false);

            if (value !== null) {
              updatePickedBillingDueDay(value);
            }
          }}
        />
      )}
    </>
  );
};

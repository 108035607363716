import { IdType } from "@common/types/apiTypes";
import { invoiceApi } from "@portal-account/api/invoiceApi";
import { invoiceQueryKeys } from "@portal-account/queryKeys/invoiceQueryKeys";
import { useQuery } from "@tanstack/react-query";

interface UseBillBreakdownFactorsQueryProps {
  invoiceId: IdType;
}

export const useBillBreakdownFactorsQuery = (
  props: UseBillBreakdownFactorsQueryProps
) => {
  const { invoiceId } = props;

  return useQuery({
    queryFn: () => invoiceApi.factors(invoiceId),
    queryKey: invoiceQueryKeys.factors(invoiceId),
  });
};

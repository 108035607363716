import {
  StyledBillBreakdownInvoiceCard,
  StyledBillBreakdownInvoiceCardDivider,
  StyledBillBreakdownInvoiceCardSkeleton,
} from "@portal-account/components/BillBreakdownInvoiceCard/BillBreakdownInvoiceCard.styled";
import React from "react";

export const BillBreakdownInvoiceCardSkeleton = () => (
  <StyledBillBreakdownInvoiceCard>
    <StyledBillBreakdownInvoiceCardSkeleton />
    <StyledBillBreakdownInvoiceCardDivider />
    <StyledBillBreakdownInvoiceCardSkeleton />
  </StyledBillBreakdownInvoiceCard>
);

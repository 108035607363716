import { IdType } from "@common/types/apiTypes";
import { premiseApi } from "@portal-account/api/premiseApi";
import { premiseQueryKeys } from "@portal-account/queryKeys/premiseQueryKeys";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { useQuery } from "@tanstack/react-query";

interface UsePremiseRenewalOfferSnapshotsQuery {
  premiseId: IdType;
  queryOptions?: {
    enabled?: boolean;
  };
}

export const usePremiseRenewalOfferSnapshotsQuery = (
  props: UsePremiseRenewalOfferSnapshotsQuery
) => {
  const { premiseId, queryOptions: { enabled = true } = {} } = props;

  const query = useQuery({
    enabled,
    queryFn: () => premiseApi.allRenewableOfferSnapshots(premiseId),
    queryKey: premiseQueryKeys.allRenewableOfferSnapshots(premiseId),
    select: (data) => {
      return {
        ...data,
        myAccountOfferSnapshots: data.myAccountOfferSnapshots.map(
          (offerSnapshot) => new OfferSnapshot(offerSnapshot)
        ),
        oneClickRenewalOfferSnapshot: data.oneClickRenewalOfferSnapshot
          ? new OfferSnapshot(data.oneClickRenewalOfferSnapshot)
          : null,
      };
    },
  });

  return query;
};

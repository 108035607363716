import { PremiseListItemType } from "@common/types/premiseTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { devicesEnrollSelectDevicesFormTranslations } from "@portal-account/components/DevicesEnrollSelectDevicesForm/DevicesEnrollSelectDevicesForm.en.i18n";
import {
  ConnectionStatus,
  EnrolledDeviceAddressContainer,
  SelectDeviceContainer,
  SelectDeviceInfoColumn,
  SelectDeviceStatus,
  StyledEnrolledCheckIcon,
} from "@portal-account/components/DevicesEnrollSelectDevicesForm/SelectDevice.styled";
import { useDeviceStatus } from "@portal-account/hooks/useDeviceStatus";
import { DeviceType } from "@portal-account/types/devicesTypes";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

interface EnrolledDeviceProps {
  device: DeviceType;
  premises: PremiseListItemType[] | undefined;
}

export const EnrolledDevice = (props: EnrolledDeviceProps) => {
  const { device, premises } = props;
  const {
    deviceBrand,
    deviceModel,
    deviceName,
    deviceStatus,
    premiseId,
    deviceId,
  } = device;
  const deviceStatusText = useDeviceStatus();
  const { translate } = useTranslations();

  const {
    tDevicesEnrollSelectDevicesFormAddress,
    tDevicesEnrollSelectDevicesFormConnected,
    tDevicesEnrollSelectDevicesFormConnectionStatus,
    tDevicesEnrollSelectDevicesFormDeviceName,
    tDevicesEnrollSelectDevicesFormDeviceStatus,
    tDevicesEnrollSelectDevicesFormDeviceType,
    tDevicesEnrollSelectDevicesFormModelNumber,
  } = translate(devicesEnrollSelectDevicesFormTranslations);

  const enrolledPremise = premises?.find(
    (premise) => premise.id === String(premiseId)
  );

  return (
    <SelectDeviceContainer $isEnrolled>
      <SelectDeviceInfoColumn>
        <div>
          <RhTypography fontWeight={FontWeight.Semibold} variant="body1">
            {tDevicesEnrollSelectDevicesFormDeviceName}
          </RhTypography>
          {deviceName}
        </div>
        <div>
          <RhTypography fontWeight={FontWeight.Semibold} variant="body1">
            {tDevicesEnrollSelectDevicesFormDeviceType}
          </RhTypography>
          {deviceBrand}
        </div>
      </SelectDeviceInfoColumn>
      <SelectDeviceInfoColumn>
        <div>
          <RhTypography fontWeight={FontWeight.Semibold} variant="body1">
            {tDevicesEnrollSelectDevicesFormModelNumber}
          </RhTypography>
          {deviceModel}
        </div>
        <div>
          <RhTypography fontWeight={FontWeight.Semibold} variant="body1">
            {tDevicesEnrollSelectDevicesFormDeviceStatus}
          </RhTypography>
          <SelectDeviceStatus $status={deviceStatus}>
            {deviceStatusText[deviceStatus]}
          </SelectDeviceStatus>
        </div>
      </SelectDeviceInfoColumn>
      <EnrolledDeviceAddressContainer>
        <div>
          <RhTypography fontWeight={FontWeight.Semibold} variant="body1">
            {tDevicesEnrollSelectDevicesFormConnectionStatus}
          </RhTypography>
          <ConnectionStatus>
            <StyledEnrolledCheckIcon
              aria-label={`Device ${deviceId} connected`}
            />
            {tDevicesEnrollSelectDevicesFormConnected}
          </ConnectionStatus>
        </div>
        {enrolledPremise ? (
          <div>
            <RhTypography fontWeight={FontWeight.Semibold} variant="body1">
              {tDevicesEnrollSelectDevicesFormAddress}
            </RhTypography>

            <div>
              {enrolledPremise.serviceAddress?.deliveryLine1}{" "}
              {enrolledPremise.serviceAddress?.lastLine}
              <br />
              {enrolledPremise.billingAccountNumber}
            </div>
          </div>
        ) : null}
      </EnrolledDeviceAddressContainer>
    </SelectDeviceContainer>
  );
};

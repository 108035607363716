import { isBot } from "@common/utils/isBot";

export enum GuestEvents {
  addNewCard = "addNewCard",
  quickPay = "quickPay",
}

export enum RenewalEvents {
  renewalClick = "renewalClick",
}

export enum CategoryType {
  OneClickRenewals = "One-click Renewals",
}

export enum ActionType {
  click = "click",
}

export enum LabelType {
  ClickedRenewalSubmit = "Clicked Renewal Submit",
}

export interface AnalyticsEvent {
  [key: string]: string | number | boolean;
  action: ActionType;
  category: string;
  event: GuestEvents | RenewalEvents;
  label: string;
  value: string | boolean;
}

export type TrackEventHandler = (event: Partial<AnalyticsEvent>) => void;

export const track = (options: Partial<AnalyticsEvent>) => {
  if (isBot()) {
    return;
  }

  const { event, ...properties } = options;

  if (event) {
    window.FS?.event(event, properties);
    window.analytics?.track?.(event, properties);
    window.dataLayer?.push(options);
  }
};

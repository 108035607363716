import { isBot } from "@common/utils/isBot";

export enum EnrollmentEvents {
  enrollmentClick = "enrollmentClick",
  identify = "identify",
}

export enum CategoryType {
  BillingPreferences = "billing-preferences",
  Contact = "contact",
  FullStory = "fullstory",
  Name = "name",
  Premise = "premise",
}

export enum ActionType {
  billingPreferencesSubmitted = "billingPreferencesSubmitted",
  click = "click",
  clickedBackPage = "clickedBackPage",
  clickedEnrollmentSubmit = "clickedEnrollmentSubmit",
  clickedNextPage = "clickedNextPage",
  contactSubmitted = "contactSubmitted",
  emailSubmitted = "emailSubmitted",
  focus = "fieldFocus",
  nameSubmitted = "nameSubmitted",
  openedCart = "openedCart",
  premiseSubmitted = "premiseSubmitted",
  prospectCreated = "prospectCreated",
  userIdentified = "userIdentified",
}

export enum LabelType {
  ConfirmedProductAddOnSelection = "Confirmed product add-on selection",
  FullStoryUserIdentified = "FullStory User Identified",
  SkippedProductAddOnsSelection = "Skipped product add-ons selection",
}

export interface AnalyticsEvent {
  [key: string]: string | number | boolean;
  action: ActionType;
  category: string;
  event: EnrollmentEvents;
  label: string;
  value: string | boolean;
}

export type TrackEventHandler = (event: Partial<AnalyticsEvent>) => void;

export const track = (options: Partial<AnalyticsEvent>) => {
  if (isBot()) {
    return;
  }

  const { event, ...properties } = options;

  if (event) {
    window.FS?.event(event, properties);
    window.analytics?.track?.(event, properties);
    window.dataLayer?.push(options);
  }
};

export const createTrackEvent = (
  base: Partial<AnalyticsEvent>
): TrackEventHandler => {
  return (options: Partial<AnalyticsEvent>) => {
    track({ ...base, ...options });
  };
};

import { ajax } from "@common/services/ajax";
import { IdType } from "@common/types/apiTypes";

const BASE_URL = "/api/portal/smart-thermostats";

export const smartThermostatsApi = {
  destroy: (smartThermostatsId: IdType) =>
    ajax.destroy(`${BASE_URL}/${smartThermostatsId}/`),
  swapPremise: ({
    newPremiseId,
    smartThermostatId,
  }: {
    newPremiseId: IdType;
    smartThermostatId: IdType;
  }) =>
    ajax.post(`${BASE_URL}/${smartThermostatId}/swap-premise/`, {
      newPremiseId,
    }),
};

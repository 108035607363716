import { premiseProductContractApi } from "@portal-account/api/premiseProductContractApi";
import { productContractQueryKeys } from "@portal-account/queryKeys/productContractQueryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCreateProductContractMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: premiseProductContractApi.create,
    onSuccess: (_, { premiseId }) => {
      queryClient.invalidateQueries({
        queryKey: productContractQueryKeys.list.DEFAULT(premiseId),
      });
    },
  });
};

import {
  createPaymentExtensionResponseAtom,
  paymentExtensionActiveStep,
  paymentExtensionFormAtom,
} from "@portal-account/components/PaymentExtension/PaymentExtension.atoms";
import { EXTENSION_DAYS } from "@portal-account/components/PaymentExtension/PaymentExtension.constants";
import {
  PaymentExtensionFormValues,
  PaymentExtensionSteps,
} from "@portal-account/components/PaymentExtension/PaymentExtension.types";
import { CreatePaymentExtensionResponseType } from "@portal-account/types/paymentMethodTypes";
import dayjs from "dayjs";
import { useAtom, useSetAtom } from "jotai";
import { useMemo } from "react";

interface UsePaymentExtensionArgs {
  disconnectionDueDate: string;
  pastDueBalance: string;
}

export function usePaymentExtension({
  disconnectionDueDate,
  pastDueBalance,
}: UsePaymentExtensionArgs) {
  const [formValues, setFormValues] = useAtom(paymentExtensionFormAtom);
  const [activeStep, setActiveStep] = useAtom(paymentExtensionActiveStep);
  const setCreatePaymentExtensionResponseAtom = useSetAtom(
    createPaymentExtensionResponseAtom
  );

  const extensionDueDate = useMemo(() => {
    return dayjs(disconnectionDueDate).add(EXTENSION_DAYS, "days").toString();
  }, [disconnectionDueDate]);

  const extendedAmount = useMemo(() => {
    return (Number(pastDueBalance) - Number(formValues.downPayment)).toFixed(2);
  }, [formValues.downPayment, pastDueBalance]);

  const handleNextStep = ({
    newFormValues,
    responseData,
  }: {
    newFormValues?: Partial<PaymentExtensionFormValues>;
    responseData?: CreatePaymentExtensionResponseType;
  }) => {
    switch (activeStep) {
      case PaymentExtensionSteps.form: {
        setActiveStep(PaymentExtensionSteps.confirmation);
        break;
      }
      case PaymentExtensionSteps.confirmation: {
        setActiveStep(PaymentExtensionSteps.success);
        break;
      }
      case PaymentExtensionSteps.success: {
        break;
      }
    }

    setFormValues({
      ...formValues,
      ...newFormValues,
    });

    if (responseData) {
      setCreatePaymentExtensionResponseAtom(responseData);
    }
  };

  const handlePreviousStep = () => {
    if (activeStep === PaymentExtensionSteps.confirmation) {
      setActiveStep(PaymentExtensionSteps.form);
    }
  };

  return {
    extendedAmount,
    extensionDueDate,
    handleNextStep,
    handlePreviousStep,
  };
}

import { useCustomerFromContext } from "@portal-account/components/CustomerProvider/useCustomerFromContext";
import { selectAnalytics } from "@portal-account/selectors/analyticsSelectors";
import {
  ActionType,
  EventType,
  track,
} from "@portal-account/services/segment.service";
import { analyticsDisableSendCustomerLoginEvent } from "@portal-account/slices/analyticsSlice";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useSendCustomerLoginEvent = () => {
  const { sendCustomerLoginEvent } = useSelector(selectAnalytics);
  const dispatch = useDispatch();
  const customer = useCustomerFromContext();

  useEffect(() => {
    if (sendCustomerLoginEvent) {
      track({
        action: ActionType.customerLogin,
        category: "portal",
        event: EventType.portalCustomerLogin,
        label: "Customer logged in",
        value: customer.id,
      });
      dispatch(analyticsDisableSendCustomerLoginEvent());
    }
  }, [customer.id, dispatch, sendCustomerLoginEvent]);
};

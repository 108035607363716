import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { borders } from "@design-system/constants/borders";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { StyledInvoicesRow } from "@portal-account/components/InvoicesRow/InvoicesRow.styled";
import styled from "styled-components";

export const StyledBillInvoiceRow = styled(StyledInvoicesRow)`
  &:first-child {
    border-top: ${borders[100]};
  }
  align-items: flex-start;
  padding-bottom: ${rhSpacingPx(2)};
  padding-top: ${rhSpacingPx(2)};
`;

export const StyledLateFeeMessage = styled(RhTypography)`
  &.MuiTypography-root {
    margin-top: ${rhSpacingPx(0.5)};
  }
`;

export const BillInvoicesInvoicesRowElement = styled(RhTypography)`
  &.MuiTypography-root {
    &:first-child {
      flex-basis: 145px;
      margin-right: auto;
      padding: 0;
      text-align: start;
    }

    &:last-child {
      flex-basis: 150px;
      flex-shrink: 1;
      padding: 0;
      text-align: end;
      @media (max-width: ${RhythmBreakpoints.SM}px) {
        flex-basis: 110px;
      }
    }

    flex-basis: 71px;
    flex-shrink: 1;
    text-align: end;
  }
`;

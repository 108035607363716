import { customerApi } from "@portal-account/api/customerApi";
import { useMutation } from "@tanstack/react-query";

export const useAttachResideoDevicesMutation = () => {
  const mutation = useMutation({
    mutationFn: customerApi.attachResideoDevices,
  });

  return mutation;
};

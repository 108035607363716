import { billingApi } from "@common/api/billingApi";
import { premiseApi } from "@common/api/premiseApi";
import { RhApiError } from "@common/types/errorTypes";
import {
  PaymentMethodType,
  PaymentMethodsResponseType,
} from "@common/types/paymentMethodTypes";
import { selectBillingDetailsState } from "@portal-account/selectors/billingDetailsSelectors";
import { selectCustomerPremisePreference } from "@portal-account/selectors/customerPreferencesSelectors";
import {
  billingDetailsErrored,
  billingDetailsFetch,
  billingDetailsReceived,
  billingDetailsRefetch,
  billingDetailsRequested,
  paymentMethodSetAsDefault,
  paymentMethodSetAsDefaultRequest,
} from "@portal-account/slices/billingDetailsSlice";
import { PayloadAction } from "@reduxjs/toolkit";
import {
  SagaReturnType,
  all,
  call,
  put,
  select,
  takeLatest,
} from "redux-saga/effects";

export function* fetchPaymentMethodsWorker() {
  const { data } = yield select(selectBillingDetailsState);

  if (!data) {
    yield call(refetchPaymentMethodsWorker);
  }
}

export function* refetchPaymentMethodsWorker() {
  const premiseId: SagaReturnType<typeof selectCustomerPremisePreference> =
    yield select(selectCustomerPremisePreference);

  yield put(billingDetailsRequested());

  try {
    const billingDetails: PaymentMethodsResponseType = yield call(
      premiseApi.paymentMethods.list,
      premiseId
    );

    yield put(billingDetailsReceived(billingDetails.paymentMethods));
  } catch (err) {
    yield put(billingDetailsErrored(err as RhApiError));
  }
}

export function* setDefaultPaymentMethodWorker({
  payload: { id: paymentMethodId },
}: PayloadAction<Pick<PaymentMethodType, "id">>) {
  const premiseId: SagaReturnType<typeof selectCustomerPremisePreference> =
    yield select(selectCustomerPremisePreference);

  if (premiseId) {
    try {
      yield call(billingApi.paymentMethods.default, paymentMethodId, {
        premiseId,
      });

      yield put(paymentMethodSetAsDefault({ id: paymentMethodId }));
      yield call(refetchPaymentMethodsWorker);
    } catch (err) {
      yield put(billingDetailsErrored(err as RhApiError));
    }
  }
}

export function* billingDetailsWatcher() {
  yield all([
    takeLatest(billingDetailsFetch.type, fetchPaymentMethodsWorker),
    takeLatest(billingDetailsRefetch.type, refetchPaymentMethodsWorker),
    takeLatest(
      paymentMethodSetAsDefaultRequest.type,
      setDefaultPaymentMethodWorker
    ),
  ]);
}

import { RhApiError } from "@common/types/errorTypes";
import { smartThermostatsApi } from "@portal-account/api/smartThermostatsApi";
import { useMutation } from "@tanstack/react-query";

export const useDisconnectDeviceMutation = () => {
  return useMutation({
    mutationFn: smartThermostatsApi.destroy,
    onError: (_error: RhApiError) => {},
  });
};

import { StyledRhTextButton } from "@design-system/components/RhTextButton/RhTextButton.styled";
import React from "react";

interface RhTextButtonProps extends React.ComponentProps<"button"> {
  "data-tracking-click": {
    [key: string]: string;
    event: string;
  };
}

export const RhTextButton = (props: RhTextButtonProps) => {
  const {
    "data-tracking-click": dataTrackingClick,
    className,
    children,
    ...buttonProps
  } = props;

  return (
    <StyledRhTextButton
      {...buttonProps}
      className={className}
      data-tracking-click={JSON.stringify(dataTrackingClick)}
    >
      {children}
    </StyledRhTextButton>
  );
};

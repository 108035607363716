import { useRenewalReminderModal } from "@portal-account/components/AccountGlobalLogic/useRenewalReminderModal";
import { useSendCustomerLoginEvent } from "@portal-account/components/AccountGlobalLogic/useSendCustomerLoginEvent";
import { useSwitchEBillToPaperlessModal } from "@portal-account/components/AccountGlobalLogic/useSwitchEBillToPaperlessModal";
import { useSwitchPrintToPaperlessModal } from "@portal-account/components/AccountGlobalLogic/useSwitchPrintToPaperlessModal";
import { useSetDjangoLanguageCookie } from "@portal-shared/hooks/useSetDjangoLanguageCookie";

export const AccountGlobalLogic = () => {
  useSendCustomerLoginEvent();
  useSetDjangoLanguageCookie();
  useSwitchEBillToPaperlessModal();
  useSwitchPrintToPaperlessModal();
  useRenewalReminderModal();

  return null;
};

import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { DeviceEnrollmentRefresher } from "@portal-account/components/DeviceEnrollmentRefresher/DeviceEnrollmentRefresher";
import { DeviceFAQsCard } from "@portal-account/components/DeviceFAQsCard/DeviceFAQsCard";
import { StyledImageWrapper } from "@portal-account/components/DeviceInformationCard/DeviceInformationCard.styled";
import { ReactComponent as Thermostat } from "@portal-account/images/Thermostat.svg";
import { devicesPageTranslations } from "@portal-account/pages/DevicesPage/DevicesPage.en.i18n";
import {
  StyledContainer,
  StyledNoAttachedDevicesContainer,
  StyledNoAttachedDevicesHeader,
} from "@portal-account/pages/DevicesPage/DevicesPage.styled";
import { devicesEnrollStepPath } from "@portal-account/routes/routePaths";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

interface NoAttachedDevicesProps {
  credit: string | undefined;
}

export const NoAttachedDevices = (props: NoAttachedDevicesProps) => {
  const { credit } = props;
  const { translate, translateJsx } = useTranslations();
  const {
    tDevicesPageAddNewButton,
    tDevicesPageNoAttachedDevicesSubtitle,
    tDevicesPageFAQsTitle,
  } = translate(devicesPageTranslations);

  const { tDevicesPageNoAttachedDevicesTitle } = translateJsx({
    tDevicesPageNoAttachedDevicesTitle: {
      credit,
    },
  });

  return (
    <StyledContainer>
      <StyledNoAttachedDevicesHeader>
        <StyledImageWrapper>
          <Thermostat />
        </StyledImageWrapper>
        <RhTypography variant="h1">
          {tDevicesPageNoAttachedDevicesTitle}
        </RhTypography>
        <RhTypography variant="subtitle1">
          {tDevicesPageNoAttachedDevicesSubtitle}
        </RhTypography>
      </StyledNoAttachedDevicesHeader>
      <StyledNoAttachedDevicesContainer>
        <RhLinkButton
          data-tracking-click={{
            event: "Customer going to choose your thermostat device brand page",
          }}
          label={tDevicesPageAddNewButton}
          variant="contained"
          href={devicesEnrollStepPath("choose-your-device-brand")}
          size="medium"
          fullWidth={false}
        >
          {tDevicesPageAddNewButton}
        </RhLinkButton>
        <DeviceEnrollmentRefresher credit={credit} />
        <DeviceFAQsCard cardTitle={tDevicesPageFAQsTitle} />
      </StyledNoAttachedDevicesContainer>
    </StyledContainer>
  );
};

export const AccountFooterLoggedInTranslations: Record<string, string> = {
  tAccountFooterLoggedInAllRightsReserved:
    "© Rhythm Ops, LLC, d/b/a Rhythm. All Rights Reserved. PUCT Certificate No. 10279.",
  tAccountFooterLoggedInFAQ: "FAQ",
  tAccountFooterLoggedInFacebook: "Facebook",
  tAccountFooterLoggedInHome: "Home",
  tAccountFooterLoggedInInstagram: "Instagram",
  tAccountFooterLoggedInLinkedin: "LinkedIn",
  tAccountFooterLoggedInPrivacyPolicy: "Privacy Policy",
  tAccountFooterLoggedInResources: "Resources",
  tAccountFooterLoggedInTermsOfUse: "Terms of Use",
  tAccountFooterLoggedInTwitter: "Twitter",
};

import { RhApiError } from "@common/types/errorTypes";
import {
  formatCurrency,
  formatKwh,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { invoiceFileDownload } from "@common/utils/invoiceFileDownload";
import { RhRouterLink } from "@design-system/components/RhRouterLink/RhRouterLink";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as DownloadIcon } from "@design-system/icons/DownloadIcon.svg";
import { ReactComponent as PdfIcon } from "@design-system/icons/PDFIcon.svg";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { billingHistoryTableV2Translations } from "@portal-account/components/BillingHistoryTableV2/BillingHistoryTableV2.en.i18n";
import { MyAccountTable } from "@portal-account/components/MyAccountTable/MyAccountTable";
import {
  StyledDownloadContainer,
  StyledDownloadLink,
} from "@portal-account/components/MyAccountTable/MyAccountTable.styled";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { useTrackMyAccountEvents } from "@portal-account/hooks/useTrackMyAccountEvents";
import { Invoice } from "@portal-account/models/Invoice.model";
import { billBreakdownPath } from "@portal-account/routes/routePaths";
import { MyAccountEvents } from "@portal-account/services/segment.service";
import { InvoiceTypeEnum } from "@portal-account/types/invoiceTypes";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { createColumnHelper, noop } from "@tanstack/react-table";
import dayjs from "dayjs";
import React, { useMemo } from "react";

interface BillingHistoryTableV2Props {
  invoices: Invoice[];
}

export const BillingHistoryTableV2 = (props: BillingHistoryTableV2Props) => {
  const { invoices } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.SM));
  const flash = useRhFlash();
  const { premise } = usePremiseFromContext();

  const { translate } = useTranslations();
  const track = useTrackMyAccountEvents();

  const {
    tBillingHistoryTableV2BillDate,
    tBillingHistoryTableV2Download,
    tBillingHistoryTableV2DueDate,
    tBillingHistoryTableV2ErrorDownloading,
    tBillingHistoryTableV2Invoice,
    tBillingHistoryTableV2ServicePeriod,
    tBillingHistoryTableV2TotalAmountDue,
    tBillingHistoryTableV2TotalUsage,
    tBillingHistoryTableV2BillBreakdown,
  } = translate(billingHistoryTableV2Translations);

  const sortedInvoices = useMemo(
    () =>
      invoices.sort(
        (a, b) =>
          dayjs(b.invoiceDate).valueOf() - dayjs(a.invoiceDate).valueOf()
      ),
    [invoices]
  );

  const handleDownload = (invoice: Invoice) => {
    track({
      event: MyAccountEvents.downloadBill,
      label: "Billing history download bill",
    });

    invoiceFileDownload({
      billingAccountNumber: premise.billingAccountNumber,
      invoiceDate: invoice.invoiceDate,
      invoiceId: invoice.id,
      premiseId: premise.id,
    })
      .then(noop)
      .catch((_error: RhApiError) => {
        flash.error(tBillingHistoryTableV2ErrorDownloading);
      });
  };

  const columnVisibility = isMobile
    ? {
        amount: true,
        billBreakdown: false,
        billDate: true,
        dueDate: true,
        invoice: false,
        servicePeriod: true,
        totalUsage: false,
      }
    : {
        amount: true,
        billBreakdown: true,
        billDate: true,
        dueDate: true,
        invoice: true,
        servicePeriod: true,
        totalUsage: true,
      };

  const columnHelper = createColumnHelper<Invoice>();

  const columns = [
    columnHelper.display({
      cell: ({ row }) => (
        <>
          <b>{formatMonthDayYear(row.original.invoiceDate)}</b>
          {isMobile ? (
            <div>
              <StyledDownloadLink onClick={() => handleDownload(row.original)}>
                {tBillingHistoryTableV2Download}
              </StyledDownloadLink>
            </div>
          ) : null}
        </>
      ),
      header: tBillingHistoryTableV2BillDate,
      id: "billDate",
    }),
    columnHelper.display({
      cell: ({ row }) =>
        row.original.formattedInvoiceServicePeriod.map((date) => (
          <div key={date}>{date}</div>
        )),
      header: tBillingHistoryTableV2ServicePeriod,
      id: "servicePeriod",
    }),
    columnHelper.display({
      cell: ({ row }) => {
        const kwh = row.original.totalKwh;

        return kwh ? formatKwh(Number(kwh), 0) : "-";
      },
      header: tBillingHistoryTableV2TotalUsage,
      id: "totalUsage",
    }),
    columnHelper.display({
      cell: ({ row }) => (
        <>
          <b>{formatCurrency(row.original.amount)}</b>
          {isMobile && row.original.invoiceType === InvoiceTypeEnum.ENERGY ? (
            <div>
              <RhRouterLink to={billBreakdownPath(row.original.id)}>
                {tBillingHistoryTableV2BillBreakdown}
              </RhRouterLink>
            </div>
          ) : null}
        </>
      ),
      header: tBillingHistoryTableV2TotalAmountDue,
      id: "amount",
    }),
    columnHelper.display({
      cell: ({ row }) => formatMonthDayYear(row.original.dueDate),
      header: tBillingHistoryTableV2DueDate,
      id: "dueDate",
    }),
    columnHelper.display({
      cell: ({ row }) => (
        <StyledDownloadContainer>
          <StyledDownloadLink onClick={() => handleDownload(row.original)}>
            <DownloadIcon />
          </StyledDownloadLink>
        </StyledDownloadContainer>
      ),
      header: tBillingHistoryTableV2Invoice,
      id: "invoice",
    }),
    columnHelper.display({
      cell: ({ row }) =>
        row.original.invoiceType === InvoiceTypeEnum.ENERGY ? (
          <RhRouterLink
            title="Bill Breakdown"
            to={billBreakdownPath(row.original.id)}
          >
            <PdfIcon />
          </RhRouterLink>
        ) : null,
      header: tBillingHistoryTableV2BillBreakdown,
      id: "billBreakdown",
    }),
  ];

  return (
    <MyAccountTable<Invoice>
      columns={columns}
      data={sortedInvoices}
      rowId="id"
      columnVisibility={columnVisibility}
    />
  );
};

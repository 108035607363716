export const createPasswordPageTranslations = {
  tCreatePasswordPageAccountNumber: "Account Number: {accountNumber}",
  tCreatePasswordPageCreatePassword: "Create Password",
  tCreatePasswordPageCreateYourPassword:
    "Create your password and access your Rhythm Account.",
  tCreatePasswordPageInvalidPassword:
    "Does not meet the requirements of the password policy.",
  tCreatePasswordPagePassword: "Password",
  tCreatePasswordPagePasswordMinimumRequirements:
    "Use 8 or more characters with a mix of letters, numbers and symbols.",
  tCreatePasswordPageRequestedService:
    "Requested Service Start Date: {startDate}",
  tCreatePasswordPageSitTight:
    "Sit tight, we're processing your request. You'll receive an email and SMS in the next few hours to confirm your enrollment and service start date.",
  tCreatePasswordPageStayInformed:
    "Stay informed about your usage, view and pay bills, and see other important documents.",
  tCreatePasswordPageThanks: "Thanks for choosing Rhythm, {firstName}!",
  tCreatePasswordPageTwoFriendsHighFiving: "Two friends high fiving",
  tCreatePasswordPageUnknownOktaIssue:
    "We are having issues setting your password. Please try again.",
};

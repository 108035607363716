import { IdType } from "@common/types/apiTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { billTipsCardTranslations } from "@portal-account/components/BillTipsCard/BillTipsCard.en.i18n";
import { BillTipsCardSkeleton } from "@portal-account/components/BillTipsCard/BillTipsCard.skeleton";
import {
  StyledBillTipsCard,
  StyledBillTipsDivider,
  StyledTipsList,
} from "@portal-account/components/BillTipsCard/BillTipsCard.styled";
import { useBillBreakdownFactorsQuery } from "@portal-account/queries/useBillBreakdownFactorsQuery";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

interface BillTipsCardProps {
  invoiceId: IdType;
}

export const BillTipsCard = (props: BillTipsCardProps) => {
  const { invoiceId } = props;

  const { data, isPending } = useBillBreakdownFactorsQuery({ invoiceId });

  const { translate } = useTranslations();
  const {
    tBillTipsCardBeAFan,
    tBillTipsCardIfYoureNotHomeAC,
    tBillTipsCardSealHeat,
    tBillTipsCardBlankets,
    tBillTipsCardIfYoureNotHomeHeat,
    tBillTipsCardSealCold,
    tBillTipsCardSomeTips,
    tBillTipsCardCheckWindows,
    tBillTipsCardLed,
    tBillTipsCardUnplug,
  } = translate(billTipsCardTranslations);

  if (isPending) {
    return <BillTipsCardSkeleton />;
  }

  const getTips = () => {
    if (!data?.weather) {
      return [tBillTipsCardCheckWindows, tBillTipsCardUnplug, tBillTipsCardLed];
    }

    const { numberOfColdDays, numberOfHotDays } = data.weather;

    if (numberOfHotDays > numberOfColdDays) {
      return [
        tBillTipsCardIfYoureNotHomeAC,
        tBillTipsCardBeAFan,
        tBillTipsCardSealHeat,
      ];
    }

    if (numberOfColdDays > numberOfHotDays) {
      return [
        tBillTipsCardIfYoureNotHomeHeat,
        tBillTipsCardBlankets,
        tBillTipsCardSealCold,
      ];
    }

    return [tBillTipsCardCheckWindows, tBillTipsCardUnplug, tBillTipsCardLed];
  };

  return (
    <StyledBillTipsCard>
      <RhTypography fontWeight="Bold" variant="subtitle2">
        {tBillTipsCardSomeTips}
      </RhTypography>
      <StyledBillTipsDivider />
      <StyledTipsList>
        {getTips()?.map((tip) => (
          <li key={tip}>
            <RhTypography>{tip}</RhTypography>
          </li>
        ))}
      </StyledTipsList>
    </StyledBillTipsCard>
  );
};

import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { deviceFAQsCardTranslations } from "@portal-account/components/DeviceFAQsCard/DeviceFAQsCard.en.i18n";
import { useDeviceFaqs } from "@portal-account/components/DeviceFAQsCard/useDeviceFaqs";
import { PortalAccordion } from "@portal-shared/components/PortalAccordion/PortalAccordion.styled";
import {
  PortalCard,
  PortalCardHeader,
  PortalCardTitle,
} from "@portal-shared/components/PortalCard/PortalCard.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

interface DeviceFAQsCardProps {
  cardTitle?: string;
}

export const DeviceFAQsCard = (props: DeviceFAQsCardProps) => {
  const { cardTitle } = props;
  const faqs = useDeviceFaqs();
  const { translate } = useTranslations();
  const { tDeviceFAQsCardTitle } = translate(deviceFAQsCardTranslations);

  const actualCardTitle = cardTitle || tDeviceFAQsCardTitle;

  return (
    <PortalCard $noMargin>
      <PortalCardHeader>
        <PortalCardTitle>{actualCardTitle}</PortalCardTitle>
      </PortalCardHeader>
      {faqs.map(({ faqTitle, faqBody }) => (
        <PortalAccordion key={faqTitle?.toString()}>
          <summary>
            <RhTypography variant="body1" align="left">
              {faqTitle}
            </RhTypography>
          </summary>
          {faqBody.map((text) => (
            <RhTypography
              key={text?.toString()}
              component="p"
              variant="body1"
              align="left"
            >
              {text}
            </RhTypography>
          ))}
        </PortalAccordion>
      ))}
    </PortalCard>
  );
};

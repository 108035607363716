import { FeatureFlagMetric } from "@common/components/FeatureFlagClientProvider/FeatureFlagClientProvider.types";
import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { CLEAR_LINK_COPIED_TIME } from "@portal-account/components/CopyReferralLinkButton/CopyReferralLinkButton.constants";
import {
  StyledCopyReferralButton,
  StyledCopyReferralContainer,
  StyledCopyReferralCopyIcon,
  StyledCopyReferralInput,
} from "@portal-account/components/CopyReferralLinkButton/CopyReferralLinkButton.styled";
import { useCustomerFromContext } from "@portal-account/components/CustomerProvider/useCustomerFromContext";
import { signUpReferralUrl } from "@portal-account/routes/routePaths";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import React, { useEffect, useRef, useState } from "react";

interface CopyReferralLinkButtonProps {
  buttonText?: string;
  dense?: boolean;
  hideUrl?: boolean;
  trackCopyWithFeatureFlag?: boolean;
}

export const CopyReferralLinkButton = ({
  dense,
  hideUrl = false,
  buttonText,
  trackCopyWithFeatureFlag,
}: CopyReferralLinkButtonProps) => {
  const copyLinkInputRef = useRef<HTMLElement>(null);
  const { t } = useRhIntl();
  const [isLinkCopied, setIsLinkCopied] = useState<boolean>(false);
  const customer = useCustomerFromContext();
  const isDisabled = isLinkCopied;

  const { featureFlagClient } = useFeatureFlagClient();

  useEffect(() => {
    if (isLinkCopied) {
      const timeoutId = setTimeout(() => {
        setIsLinkCopied(false);
      }, CLEAR_LINK_COPIED_TIME);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [isLinkCopied]);

  const trackOnCopy = () => {
    featureFlagClient.trackMetric(
      FeatureFlagMetric.PortalCustomerCopiedOrSharedRafButton
    );
  };

  const copyTextToClipboard = () => {
    if (!isLinkCopied) {
      navigator.clipboard.writeText(referralUrl);

      setIsLinkCopied(true);

      if (trackCopyWithFeatureFlag) {
        trackOnCopy();
      }
    }
  };

  const copyLinkCTA = buttonText ?? t("CopyReferralLinkButton.copyLinkCTA");
  const linkCopiedText = t("CopyReferralLinkButton.copyLinkCopied");
  const currentButtonText = isLinkCopied ? linkCopiedText : copyLinkCTA;

  if (!customer) {
    return (
      <RhFlexBox justifyContent="flex-end">
        <StyledCopyReferralButton
          data-tracking-click={{ event: "Customer copying referral code" }}
          $dense={!!dense}
          size="small"
          color="primary"
          disabled
        >
          <StyledCopyReferralCopyIcon />
          {copyLinkCTA}
        </StyledCopyReferralButton>
      </RhFlexBox>
    );
  }

  const referralUrl =
    customer.referralToFriendCode &&
    signUpReferralUrl(customer.referralToFriendCode);

  return (
    <StyledCopyReferralContainer $dense={!!dense}>
      {referralUrl && (
        <StyledCopyReferralInput
          $dense={!!dense}
          $hideUrl={!!hideUrl}
          readOnly
          disableUnderline
          ref={copyLinkInputRef}
          value={referralUrl}
        />
      )}
      <StyledCopyReferralButton
        data-tracking-click={{ event: "Customer copying referral link" }}
        $dense={!!dense}
        size="small"
        color="primary"
        disabled={isDisabled}
        onClick={copyTextToClipboard}
      >
        <StyledCopyReferralCopyIcon />
        {currentButtonText}
      </StyledCopyReferralButton>
    </StyledCopyReferralContainer>
  );
};

import {
  StyledCheckIcon,
  StyledPortalSelectorListItem,
  StyledPortalSelectorStatusIndicator,
  StyledPortalSelectorStatusText,
  StyledPortalSelectorSubtitle,
  StyledPortalSelectorTitle,
  StyledPortalSelectorTitleContainer,
} from "@portal-account/components/PortalSelector/PortalSelector.styled";
import { deviceStatusColor } from "@portal-account/helpers/deviceStatusColor";
import { useDeviceStatus } from "@portal-account/hooks/useDeviceStatus";
import { DeviceType } from "@portal-account/types/devicesTypes";
import React from "react";

interface DevicesProps {
  device: DeviceType;
  isInMenu?: boolean;
  isSelectedDevice?: boolean;
}

export const DeviceSelectorListItem = (props: DevicesProps) => {
  const { device, isSelectedDevice, isInMenu } = props;
  const { deviceBrand, deviceModel, deviceName, deviceStatus } = device;
  const deviceStatusText = useDeviceStatus();

  return (
    <StyledPortalSelectorListItem>
      {!isInMenu ? (
        <StyledPortalSelectorStatusIndicator
          $statusColor={deviceStatusColor(deviceStatus)}
          data-testid="DeviceSelector__StatusIndicator"
        />
      ) : null}

      <StyledPortalSelectorTitleContainer>
        <StyledPortalSelectorTitle $isSelected={Boolean(isSelectedDevice)}>
          {deviceName}
        </StyledPortalSelectorTitle>

        <StyledPortalSelectorSubtitle>
          {deviceBrand} | {deviceModel}
        </StyledPortalSelectorSubtitle>

        {isInMenu ? (
          <StyledPortalSelectorStatusText
            $statusColor={deviceStatusColor(deviceStatus)}
          >
            {deviceStatusText[deviceStatus]}
          </StyledPortalSelectorStatusText>
        ) : null}
      </StyledPortalSelectorTitleContainer>

      {isSelectedDevice && isInMenu ? <StyledCheckIcon /> : null}
    </StyledPortalSelectorListItem>
  );
};

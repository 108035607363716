import { UUIDType } from "@common/types/apiTypes";
import { AccountStoreState } from "@portal-account/types/portalSlicesTypes";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ProductAddOnSliceType {
  selectedProductPriceIds: UUIDType[];
}

const initialProductAddOnState: ProductAddOnSliceType = {
  selectedProductPriceIds: [],
};

const reducers = {
  addProductPrice: (
    state: ProductAddOnSliceType,
    action: PayloadAction<UUIDType>
  ): ProductAddOnSliceType => {
    state.selectedProductPriceIds.push(action.payload);

    return state;
  },
  clearProductPrices: () => {
    return { ...initialProductAddOnState };
  },
  removeProductPrice: (
    state: ProductAddOnSliceType,
    action: PayloadAction<UUIDType>
  ): ProductAddOnSliceType => {
    const newPrices = state.selectedProductPriceIds.filter(
      (price) => price !== action.payload
    );

    return {
      ...state,
      selectedProductPriceIds: newPrices,
    };
  },
};

export const productAddOnsSlice = createSlice<
  ProductAddOnSliceType,
  typeof reducers
>({
  initialState: initialProductAddOnState,
  name: "productAddOns",
  reducers,
});

export const selectProductAddOns = (state: AccountStoreState) =>
  state.productAddOns;

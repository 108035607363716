import {
  EnrollmentStatus,
  EnrollmentType as EnrollmentTypeEnum,
} from "@common/enums/customer.enum";
import { PremiseStatus } from "@common/enums/premise.enum";
import { AddressForm } from "@common/models/AddressForm.model";
import { Order } from "@common/models/Order.model";
import { IdType, UUIDType } from "@common/types/apiTypes";
import {
  DeferredPaymentPlanType,
  DisconnectNoticeType,
  PaymentExtensionType,
} from "@common/types/billingTypes";
import { AddressFormType, AddressType } from "@common/types/customerTypes";
import { KeysMatch } from "@common/types/KeysMatch";
import { MeterType } from "@common/types/meterTypes";
import { OrderStatus } from "@common/types/orderTypes";
import { formatCurrency } from "@common/utils/dataFormatters";
import {
  CommunicationPreferenceType,
  PremiseSourceType,
  PremiseType,
} from "@portal-account/types/premiseTypes";
import dayjs from "dayjs";
import { last as lodashLast, orderBy as lodashOrderBy } from "lodash";

export class Premise {
  public autopay: boolean;
  public averagedBilling: boolean;
  public averagedBillingBalance: number | null;
  public averagedBillingMonthlyCharge: number | null;
  public billingAccountId: string;
  public billingAccountNumber: string;
  public billingPaymentMethodId: string | null;
  public canUpdateMoveOutEndDate: boolean;
  public communicationPreference: CommunicationPreferenceType;
  public confirmedStartDate: string | null;
  public customerId: IdType;
  public customerUuid: UUIDType;
  public deferredPaymentPlans?: DeferredPaymentPlanType[];
  public disconnectNotices?: DisconnectNoticeType[];
  public endDate: string;
  public enrollmentStatus: EnrollmentStatus;
  public enrollmentType: EnrollmentTypeEnum;
  public hasActiveDpp: boolean;
  public hasHistoricUsage: boolean;
  public id: IdType;
  public inRenewalWindow: boolean;
  public invoiceByPrint: boolean;
  public isNoCheckOrAch: boolean;
  public isNoCreditOrDebitCard: boolean;
  public lastPyddInvoiceDate: string | null;
  public lifeSupport: boolean;
  public mailingAddress: AddressForm | null;
  public meter: MeterType | null;
  public orders: Order[];
  public paymentExtensions?: PaymentExtensionType[];
  public pickedBillingDueDay: number | null;
  public serviceAddress: AddressType | null;
  public serviceEndDate: string | null;
  public serviceRequestedDate: string | null;
  public source: PremiseSourceType;
  public status: PremiseStatus;
  public thermostatManagementMonthlyDiscount: string;
  public uuid: UUIDType;
  public zuoraSetupServiceAddress: AddressFormType | null;

  public constructor(premise: PremiseType) {
    this.autopay = premise.autopay;
    this.averagedBilling = premise.averagedBilling;
    this.averagedBillingBalance = premise.averagedBillingBalance;
    this.averagedBillingMonthlyCharge = premise.averagedBillingMonthlyCharge;
    this.billingAccountId = premise.billingAccountId;
    this.billingAccountNumber = premise.billingAccountNumber;
    this.billingPaymentMethodId = premise.billingPaymentMethodId;
    this.canUpdateMoveOutEndDate = premise.canUpdateMoveOutEndDate;
    this.communicationPreference = premise.communicationPreference;
    this.confirmedStartDate = premise.confirmedStartDate;
    this.customerId = premise.customerId;
    this.customerUuid = premise.customerUuid;
    this.deferredPaymentPlans = premise.deferredPaymentPlans;
    this.disconnectNotices = premise.disconnectNotices;
    this.endDate = premise.endDate;
    this.enrollmentStatus = premise.enrollmentStatus;
    this.enrollmentType = premise.enrollmentType;
    this.hasActiveDpp = premise.hasActiveDpp;
    this.hasHistoricUsage = premise.hasHistoricUsage;
    this.id = premise.id;
    this.inRenewalWindow = premise.inRenewalWindow;
    this.invoiceByPrint = premise.invoiceByPrint;
    this.isNoCheckOrAch = premise.isNoCheckOrAch;
    this.isNoCreditOrDebitCard = premise.isNoCreditOrDebitCard;
    this.lastPyddInvoiceDate = premise.lastPyddInvoiceDate;
    this.lifeSupport = premise.lifeSupport;
    this.mailingAddress = premise.mailingAddress
      ? new AddressForm(premise.mailingAddress)
      : null;
    this.meter = premise.meter;
    this.orders = premise.orders
      .map((orderType) => new Order(orderType))
      .sort((a, b) => {
        return dayjs(a.startDate) >= dayjs(b.startDate) ? 1 : -1;
      });
    this.paymentExtensions = premise.paymentExtensions;
    this.pickedBillingDueDay = premise.pickedBillingDueDay;
    this.serviceAddress = premise.serviceAddress;
    this.serviceEndDate = premise.serviceEndDate;
    this.serviceRequestedDate = premise.serviceRequestedDate;
    this.source = premise.source;
    this.status = premise.status;
    this.thermostatManagementMonthlyDiscount =
      premise.thermostatManagementMonthlyDiscount;
    this.uuid = premise.uuid;
    this.zuoraSetupServiceAddress = premise.zuoraSetupServiceAddress;
  }

  private get activeOrder() {
    // Current active order should be the newest active order.
    return lodashLast(
      this.orders.filter((order) => order.status === OrderStatus.Active)
    );
  }

  public get futureOrder() {
    return this.orders.filter(
      (order) => order.status === OrderStatus.Enrolled
    )[0];
  }

  private get uncreatedOrder() {
    return this.orders.filter(
      (order) => order.status === OrderStatus.Uncreated
    )[0];
  }

  public get invoiceByEmailOnly(): boolean {
    return Boolean(!this.invoiceByPrint);
  }

  public get isPaperless() {
    return (
      this.communicationPreference === CommunicationPreferenceType.PAPERLESS
    );
  }

  public get isPrint() {
    return this.communicationPreference === CommunicationPreferenceType.PRINT;
  }

  public get currentOrder() {
    if (this.activeOrder) {
      return this.activeOrder;
    } else if (this.futureOrder) {
      return this.futureOrder;
    } else if (this.uncreatedOrder) {
      return this.uncreatedOrder;
    } else if (this.orders) {
      return lodashLast(this.orders);
    } else {
      return null;
    }
  }

  public get isOnLegacyEBill(): boolean {
    return this.communicationPreference === CommunicationPreferenceType.EBILL;
  }

  public get latestDisconnectNotice() {
    if (this.disconnectNoticesBySentDate.length === 0) {
      return null;
    }

    return this.disconnectNoticesBySentDate[
      this.disconnectNoticesBySentDate.length - 1
    ];
  }

  public get formattedThermostatManagementMonthlyDiscount() {
    const decimals =
      Number(this.thermostatManagementMonthlyDiscount) % 1 === 0 ? 0 : 2;

    return formatCurrency(this.thermostatManagementMonthlyDiscount, decimals);
  }

  private get disconnectNoticesBySentDate() {
    return lodashOrderBy(
      [...(this.disconnectNotices ?? [])],
      ["sentToLobAt"],
      ["asc"]
    );
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummyKeysCheckDoNotDeleteOrChange: KeysMatch<PremiseType, Premise> =
  undefined;

import { IdType } from "@common/types/apiTypes";
import { premiseProductContractApi } from "@portal-account/api/premiseProductContractApi";
import { productContractQueryKeys } from "@portal-account/queryKeys/productContractQueryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";

interface UseCancelProductContractProps {
  premiseId: IdType;
}
export const useCancelProductContractMutation = ({
  premiseId,
}: UseCancelProductContractProps) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: premiseProductContractApi.cancel,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: productContractQueryKeys.list.DEFAULT(premiseId),
      });
    },
  });
};

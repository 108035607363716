import { premiseApi } from "@portal-account/api/premiseApi";
import { customerQueryKeys } from "@portal-account/queryKeys/customerQuerykeys";
import { premiseQueryKeys } from "@portal-account/queryKeys/premiseQueryKeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const usePremiseAutoPayUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: premiseApi.updateAutoPay,
    onSuccess: (_, { premiseId }) => {
      queryClient.invalidateQueries({
        queryKey: premiseQueryKeys.retrieve(premiseId),
      });
      queryClient.invalidateQueries({
        queryKey: customerQueryKeys.me(),
      });
    },
  });
};

import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { MyAccountMailingAddressForm } from "@portal-account/components/MyAccountMailingAddressForm/MyAccountMailingAddressForm";
import { StyledMyAccountServiceInfoSubSection } from "@portal-account/components/MyAccountServiceInfo/MyAccountServiceInfo.styled";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import {
  PortalCard,
  PortalCardHeader,
  PortalCardTitle,
} from "@portal-shared/components/PortalCard/PortalCard.styled";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import React from "react";

export const MyAccountServiceInfo = () => {
  const { premise } = usePremiseFromContext();

  const { t } = useRhIntl();

  const title = t("MyAccountServiceInfo.serviceInfo");
  const serviceAddress = t("MyAccountServiceInfo.serviceAddress");
  const mailingAddress = t("MyAccountServiceInfo.mailingAddress");
  const esiId = t("MyAccountServiceInfo.esiId");
  const accountNumber = t("MyAccountServiceInfo.accountNumber");
  const notAvailable = t("MyAccountServiceInfo.notAvailable");

  return (
    <PortalCard>
      <PortalCardHeader>
        <PortalCardTitle>{title}</PortalCardTitle>
      </PortalCardHeader>
      <RhFlexBox marginBottom={2.5}>
        <StyledMyAccountServiceInfoSubSection>
          <RhTypography variant="subtitle2" fontWeight="Bold">
            {serviceAddress}
          </RhTypography>
          <RhTypography variant="body1">
            {premise.serviceAddress?.deliveryLine1}
          </RhTypography>
          <RhTypography variant="body1">
            {premise.serviceAddress?.lastLine}
          </RhTypography>
        </StyledMyAccountServiceInfoSubSection>
        <StyledMyAccountServiceInfoSubSection>
          <RhTypography variant="subtitle2" fontWeight="Bold">
            {accountNumber}
          </RhTypography>
          <RhTypography variant="body1">
            {premise.billingAccountNumber || notAvailable}
          </RhTypography>
        </StyledMyAccountServiceInfoSubSection>
        <StyledMyAccountServiceInfoSubSection>
          <RhTypography variant="subtitle2" fontWeight="Bold">
            {esiId}
          </RhTypography>
          <RhTypography variant="body1">
            {premise.meter?.esiId || notAvailable}
          </RhTypography>
        </StyledMyAccountServiceInfoSubSection>
      </RhFlexBox>
      <StyledMyAccountServiceInfoSubSection>
        <RhTypography variant="subtitle2" fontWeight="Bold">
          {mailingAddress}
        </RhTypography>

        <MyAccountMailingAddressForm />
      </StyledMyAccountServiceInfoSubSection>
    </PortalCard>
  );
};

import { maskCurrency, unmaskCurrency } from "@common/forms/currency.mask";
import { PaymentMethod } from "@common/models/PaymentMethod.model";
import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhDivider } from "@design-system/components/RhDivider/RhDivider";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import {
  oneTimePaymentMethodIdAtom,
  paymentExtensionFormAtom,
} from "@portal-account/components/PaymentExtension/PaymentExtension.atoms";
import { PaymentExtensionFormValues } from "@portal-account/components/PaymentExtension/PaymentExtension.types";
import { usePaymentExtension } from "@portal-account/components/PaymentExtension/usePaymentExtension";
import { paymentExtensionFormTranslations } from "@portal-account/components/PaymentExtensionForm/PaymentExtensionForm.en.i18n";
import {
  PaymentExtensionFormDisclaimer,
  PaymentExtensionFormTitleContainer,
  StyledPaymentExtensionDownPaymentInput,
  StyledPaymentExtensionDueDate,
  StyledPaymentExtensionForm,
  StyledPaymentExtensionFormContainer,
  StyledPaymentExtensionFormRow,
  StyledPaymentExtensionMinimumText,
} from "@portal-account/components/PaymentExtensionForm/PaymentExtensionForm.styled";
import { PaymentMethodSelector } from "@portal-account/components/PaymentMethodSelector/PaymentMethodSelector";
import { PaymentMethodOptionsType } from "@portal-shared/components/PaymentMethodOptions/PaymentMethodOptions";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { useAtomValue, useSetAtom } from "jotai";
import React from "react";
import { FormProvider, useForm } from "react-hook-form";

interface PaymentExtensionFormProps {
  disconnectionDueDate: string;
  isOneTimePaymentMethodPending?: boolean;
  oneTimePaymentMethod?: PaymentMethod;
  pastDueBalance: string;
  paymentMethods: PaymentMethod[];
}

// Ensure if pastDueBalance is odd number, we get bigger value for downPayment
const getMinimumDownPayment = (pastDueBalance: number) => {
  const half = pastDueBalance / 2;
  const halfRounded = Math.round(half * 100) / 100;

  const remainingRounded =
    Math.round((pastDueBalance - halfRounded) * 100) / 100;

  return halfRounded >= remainingRounded ? halfRounded : remainingRounded;
};

export const PaymentExtensionForm = ({
  disconnectionDueDate,
  isOneTimePaymentMethodPending,
  oneTimePaymentMethod,
  pastDueBalance,
  paymentMethods,
}: PaymentExtensionFormProps) => {
  const { translate, translateJsx } = useTranslations();

  const setOneTimePaymentMethodId = useSetAtom(oneTimePaymentMethodIdAtom);
  const formAtomValues = useAtomValue(paymentExtensionFormAtom);
  const { extensionDueDate, handleNextStep } = usePaymentExtension({
    disconnectionDueDate,
    pastDueBalance,
  });

  const minimumDownPayment = getMinimumDownPayment(Number(pastDueBalance));

  const defaultPaymentMethod =
    paymentMethods.find((paymentMethod) => paymentMethod.defaultPaymentMethod)
      ?.id || "";

  const methods = useForm<PaymentExtensionFormValues>({
    defaultValues: {
      downPayment: maskCurrency(
        formAtomValues.downPayment || minimumDownPayment.toString()
      ),
      selectedPaymentId:
        formAtomValues.selectedPaymentId || defaultPaymentMethod,
    },
  });

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { isValid, isSubmitting },
  } = methods;

  const {
    tPaymentExtensionFormDownPayment,
    tPaymentExtensionFormDisclaimer,
    tPaymentExtensionFormTitle,
    tPaymentExtensionFormExtendedBalance,
    tPaymentExtensionFormExtensionDueDate,
    tPaymentExtensionFormTotalPastDueBalance,
    tPaymentExtensionFormReviewPayment,
  } = translate(paymentExtensionFormTranslations);

  const { tPaymentExtensionFormMinimum, tPaymentExtensionFormSubtitle } =
    translateJsx({
      tPaymentExtensionFormMinimum: {
        minimum: formatCurrency(minimumDownPayment),
      },
      tPaymentExtensionFormSubtitle: {
        bold: (str: string) => <strong>{str}</strong>,
        disconnectionDueDate: formatMonthDayYear(disconnectionDueDate),
        pastDueBalance: formatCurrency(pastDueBalance),
      },
    });

  const watchDownPayment = watch("downPayment");

  const extendedBalance = Number(pastDueBalance) - Number(watchDownPayment);

  const onSubmit = handleSubmit((values) =>
    handleNextStep({ newFormValues: values })
  );

  const onPaymentMethodAdded = (
    achOrCardAddedResponse: ZuoraAddPaymentResponseType,
    paymentMethodOptions?: PaymentMethodOptionsType
  ) => {
    if (paymentMethodOptions?.isOneTimePayment) {
      setOneTimePaymentMethodId(achOrCardAddedResponse.refId);
    }
    setValue("selectedPaymentId", achOrCardAddedResponse.refId);
  };

  return (
    <StyledPaymentExtensionFormContainer>
      <PaymentExtensionFormTitleContainer>
        <RhTypography variant="h1">{tPaymentExtensionFormTitle}</RhTypography>
        <RhTypography variant="subtitle2">
          {tPaymentExtensionFormSubtitle}
        </RhTypography>
        <PaymentExtensionFormDisclaimer variant="body2">
          {tPaymentExtensionFormDisclaimer}
        </PaymentExtensionFormDisclaimer>
      </PaymentExtensionFormTitleContainer>
      <FormProvider {...methods}>
        <StyledPaymentExtensionForm onSubmit={onSubmit}>
          <StyledPaymentExtensionFormRow>
            <label htmlFor="downPayment">
              <RhTypography>{tPaymentExtensionFormDownPayment}</RhTypography>
            </label>
            <StyledPaymentExtensionDownPaymentInput
              id="downPayment"
              type="text"
              {...register("downPayment", {
                onChange: (e) => {
                  setValue("downPayment", maskCurrency(e.target.value));
                },
                setValueAs: (value) => unmaskCurrency(value),
                validate: (value) => Number(value) >= minimumDownPayment,
              })}
            />
          </StyledPaymentExtensionFormRow>
          <StyledPaymentExtensionMinimumText>
            {tPaymentExtensionFormMinimum}
          </StyledPaymentExtensionMinimumText>
          <StyledPaymentExtensionFormRow>
            <RhTypography>{tPaymentExtensionFormExtendedBalance}</RhTypography>
            <RhTypography>{formatCurrency(extendedBalance)}</RhTypography>
          </StyledPaymentExtensionFormRow>
          <StyledPaymentExtensionFormRow data-testid="extensionDueDate">
            <RhTypography>{tPaymentExtensionFormExtensionDueDate}</RhTypography>
            <StyledPaymentExtensionDueDate>
              {formatMonthDayYear(extensionDueDate)}
            </StyledPaymentExtensionDueDate>
          </StyledPaymentExtensionFormRow>
          <RhDivider sx={{ opacity: 0.5 }} />
          <StyledPaymentExtensionFormRow>
            <RhTypography fontWeight={FontWeight.Bold}>
              {tPaymentExtensionFormTotalPastDueBalance}
            </RhTypography>
            <RhTypography fontWeight={FontWeight.Bold}>
              {formatCurrency(pastDueBalance)}
            </RhTypography>
          </StyledPaymentExtensionFormRow>
          <PaymentMethodSelector
            onPaymentMethodAdded={onPaymentMethodAdded}
            oneTimePaymentMethod={oneTimePaymentMethod}
            isOneTimePaymentMethodPending={isOneTimePaymentMethodPending}
            paymentInputName="selectedPaymentId"
            paymentMethods={paymentMethods}
          />
          <RhButton
            data-tracking-click={{ event: "Customer going to review step" }}
            type="submit"
            disabled={!isValid || isSubmitting || paymentMethods.length === 0}
          >
            {tPaymentExtensionFormReviewPayment}
          </RhButton>
        </StyledPaymentExtensionForm>
      </FormProvider>
    </StyledPaymentExtensionFormContainer>
  );
};

import { ReactComponent as ChevronDown } from "@design-system/icons/ChevronDown.svg";
import { devicesSelectorAtom } from "@portal-account/components/DeviceSelector/DeviceSelector.atoms";
import { DeviceSelectorListItem } from "@portal-account/components/DeviceSelector/DeviceSelectorListItem";
import {
  StyledChevronIconContainer,
  StyledPortalSelectorContainer,
  StyledPortalSelectorMenu,
  StyledPortalSelectorMenuContainer,
  StyledPortalSelectorMenuItem,
  StyledPortalSelectorMenuItemLink,
  StyledSelectedItemShown,
} from "@portal-account/components/PortalSelector/PortalSelector.styled";
import { useClickOutsideViewToggle } from "@portal-account/hooks/useClickOutsideViewToggle";
import { useTrackMyAccountEvents } from "@portal-account/hooks/useTrackMyAccountEvents";
import {
  ActionType,
  MyAccountEvents,
} from "@portal-account/services/segment.service";
import { DeviceType } from "@portal-account/types/devicesTypes";
import { useAtom } from "jotai";
import React, { useEffect } from "react";

interface DeviceSelectorProps {
  devices: Array<DeviceType> | undefined;
}

export const DeviceSelector = (props: DeviceSelectorProps) => {
  const { devices } = props;
  const { ref, isOpen, setIsOpen } =
    useClickOutsideViewToggle<HTMLDivElement>();
  const [selectedDevice, setSelectedDevice] = useAtom(devicesSelectorAtom);
  const track = useTrackMyAccountEvents();

  useEffect(() => {
    if (devices && !selectedDevice) {
      setSelectedDevice(devices[0]);
    }
  }, [devices, selectedDevice, setSelectedDevice]);

  if (!devices || !selectedDevice) {
    return null;
  }

  const toggleDeviceSelector = () => {
    setIsOpen((prev) => !prev);
  };

  const trackDeviceChanged = (device: DeviceType) => {
    setSelectedDevice(device);

    track({
      action: ActionType.click,
      event: MyAccountEvents.deviceSelectedFromMenu,
      label: "Device changed",
      newDeviceId: device.deviceId,
    });

    return toggleDeviceSelector();
  };

  const hasMultipleDevices = devices && devices.length > 1;

  return (
    <StyledPortalSelectorContainer ref={ref}>
      <StyledSelectedItemShown
        aria-controls="DeviceSelectorMenu"
        aria-haspopup="true"
        onClick={toggleDeviceSelector}
        $hasMenuItems={Boolean(hasMultipleDevices)}
        aria-label="DeviceSelector"
        id="DeviceSelector"
      >
        <DeviceSelectorListItem device={selectedDevice} />
        {hasMultipleDevices ? (
          <StyledChevronIconContainer
            $isOpen={isOpen}
            data-testid="DeviceSelector__ChevronDownIcon"
          >
            <ChevronDown height="10px" width="10px" />
          </StyledChevronIconContainer>
        ) : null}
      </StyledSelectedItemShown>
      {isOpen && hasMultipleDevices ? (
        <StyledPortalSelectorMenuContainer
          id="DeviceSelectorMenu"
          aria-hidden={!isOpen}
        >
          <StyledPortalSelectorMenu role="menu">
            {devices.map((device) => (
              <StyledPortalSelectorMenuItem
                key={device.deviceId}
                role="menuitem"
              >
                <StyledPortalSelectorMenuItemLink
                  as="div"
                  onClick={() => trackDeviceChanged(device)}
                >
                  <DeviceSelectorListItem
                    device={device}
                    isSelectedDevice={
                      device.deviceId === selectedDevice.deviceId
                    }
                    isInMenu
                  />
                </StyledPortalSelectorMenuItemLink>
              </StyledPortalSelectorMenuItem>
            ))}
          </StyledPortalSelectorMenu>
        </StyledPortalSelectorMenuContainer>
      ) : null}
    </StyledPortalSelectorContainer>
  );
};

import { IdType } from "@common/types/apiTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as CheckIcon } from "@design-system/icons/CheckIcon2.svg";
import { BillFactors } from "@portal-account/components/BillFactors/BillFactors";
import { billFactorsCardTranslations } from "@portal-account/components/BillFactorsCard/BillFactorsCard.en.i18n";
import { BillFactorsCardSkeleton } from "@portal-account/components/BillFactorsCard/BillFactorsCard.skeleton";
import {
  StyledBillFactorsCard,
  StyledBillFactorsDivider,
  StyledNoFactorsContainer,
} from "@portal-account/components/BillFactorsCard/BillFactorsCard.styled";
import { useBillBreakdownFactorsQuery } from "@portal-account/queries/useBillBreakdownFactorsQuery";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

interface BillFactorsCardProps {
  invoiceId: IdType;
}

export const BillFactorsCard = (props: BillFactorsCardProps) => {
  const { invoiceId } = props;

  const { data, isPending, isError } = useBillBreakdownFactorsQuery({
    invoiceId,
  });

  const { translate } = useTranslations();
  const {
    tBillFactorsCardALotOfFactors,
    tBillFactorsCardWhatChanged,
    tBillFactorsCardYourBill,
    tBillFactorsCardNotMuchChanged,
    tBillFactorsCardError,
    tBillFactorsCardTheresNothing,
    tBillFactorsCardThisMightBe,
  } = translate(billFactorsCardTranslations);

  if (isPending) {
    return <BillFactorsCardSkeleton />;
  }

  if (isError) {
    return (
      <StyledBillFactorsCard>
        <RhTypography>{tBillFactorsCardError}</RhTypography>
      </StyledBillFactorsCard>
    );
  }

  if (data.invoiceCompatible === false) {
    return (
      <StyledBillFactorsCard>
        <RhTypography variant="subtitle2" fontWeight="Bold">
          {tBillFactorsCardWhatChanged}
        </RhTypography>
        <RhTypography>{tBillFactorsCardALotOfFactors}</RhTypography>
        <StyledBillFactorsDivider />
        <StyledNoFactorsContainer>
          <RhTypography fontWeight="Bold">
            {tBillFactorsCardTheresNothing}
          </RhTypography>
          <RhTypography variant="body2">
            {tBillFactorsCardThisMightBe}
          </RhTypography>
        </StyledNoFactorsContainer>
      </StyledBillFactorsCard>
    );
  }

  const isNoFactors = data.invoiceCompatible && Object.keys(data).length === 1;

  return (
    <StyledBillFactorsCard>
      <RhTypography variant="subtitle2" fontWeight="Bold">
        {tBillFactorsCardWhatChanged}
      </RhTypography>
      <RhTypography>{tBillFactorsCardALotOfFactors}</RhTypography>
      <StyledBillFactorsDivider />
      {isNoFactors ? (
        <StyledNoFactorsContainer>
          <CheckIcon width={48} height={48} />
          <RhTypography fontWeight="Bold">
            {tBillFactorsCardNotMuchChanged}
          </RhTypography>
          <RhTypography variant="body2">
            {tBillFactorsCardYourBill}
          </RhTypography>
        </StyledNoFactorsContainer>
      ) : (
        <BillFactors billFactors={data} />
      )}
    </StyledBillFactorsCard>
  );
};

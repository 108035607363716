import { unmaskCurrency } from "@common/forms/currency.mask";
import { formatCurrency } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { AmountType } from "@portal-account/enums/AmountType.enum";
import { PayBillPageFormValues } from "@portal-account/pages/PayBillPage/PayBillPageFormValuesType";
import { StyledRemainingBillBalance } from "@portal-account/pages/PayBillPage/RemainingBillBalance/RemainingBillBalance.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";
import { useFormState } from "react-final-form";

export const RemainingBillBalance = () => {
  const { translateJsx } = useTranslations();

  const { values } = useFormState<PayBillPageFormValues>({
    subscription: { values: true },
  });

  if (values.amountType !== AmountType.Custom) {
    return null;
  }

  const unmaskedCustomAmount = Number(
    unmaskCurrency(values.maskedCustomAmount)
  );
  const amount = formatCurrency(values.totalAmount - unmaskedCustomAmount);

  const { tPaymentAmountRemainingBalance } = translateJsx({
    tPaymentAmountRemainingBalance: { amount },
  });

  return (
    <StyledRemainingBillBalance>
      <RhTypography variant="body1" color="textSecondary">
        {tPaymentAmountRemainingBalance}
      </RhTypography>
    </StyledRemainingBillBalance>
  );
};

import { Grid } from "@mui/material";
import { AccountHistoryPanel } from "@portal-account/components/AccountHistoryPanel/AccountHistoryPanel";
import { AdditionalServicesSection } from "@portal-account/components/AdditionalServicesSection/AdditionalServicesSection";
import { AtAGlanceSection } from "@portal-account/components/AtAGlanceSection/AtAGlanceSection";
import { BillingSection } from "@portal-account/components/BillingSection/BillingSection";
import { CustomerRewardsSection } from "@portal-account/components/CustomerRewardsSection/CustomerRewardsSection";
import { EnergyUsageSectionHandler } from "@portal-account/components/EnergyUsageSectionHandler/EnergyUsageSectionHandler";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { ReferAFriendSection } from "@portal-account/components/ReferAFriendSection/ReferAFriendSection";
import { YourCurrentPlanSection } from "@portal-account/components/YourCurrentPlanSection/YourCurrentPlanSection";
import { CustomerHomeNewSignupSection } from "@portal-account/pages/CustomerHomePage/CustomerHomeNewSignupSection/CustomerHomeNewSignupSection";
import { customerHomePageTranslations } from "@portal-account/pages/CustomerHomePage/CustomerHomePage.en.i18n";
import {
  StyledBillingSection,
  StyledGridContainer,
  StyledStickySection,
} from "@portal-account/pages/CustomerHomePage/CustomerHomePage.styled";
import { CustomerHomeRenewalSection } from "@portal-account/pages/CustomerHomePage/CustomerHomeRenewalSection/CustomerHomeRenewalSection";
import { PortalPageLayout } from "@portal-shared/components/PortalPageLayout/PortalPageLayout";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";
import { Helmet } from "react-helmet-async";

export const CustomerHomePage = () => {
  const { translate } = useTranslations();

  const { tCustomerHomePageRhythmEnergy, tCustomerHomePagePageTitle } =
    translate(customerHomePageTranslations);

  const { premise } = usePremiseFromContext();

  return (
    <>
      <Helmet>
        <title>
          {`${tCustomerHomePageRhythmEnergy} - ${tCustomerHomePagePageTitle}`}
        </title>
      </Helmet>
      <PortalPageLayout>
        <StyledGridContainer container spacing={3} direction="row">
          <Grid item xs={12} md={8} sm={8}>
            {premise.inRenewalWindow ? (
              <CustomerHomeRenewalSection />
            ) : (
              <CustomerHomeNewSignupSection />
            )}
            <StyledBillingSection>
              <StyledStickySection>
                <BillingSection />
                <AdditionalServicesSection />
              </StyledStickySection>
            </StyledBillingSection>
            {premise.currentOrder && (
              <YourCurrentPlanSection
                order={premise.currentOrder}
                premise={premise}
              />
            )}
            <AtAGlanceSection />
            <CustomerRewardsSection />
            <EnergyUsageSectionHandler />
            <ReferAFriendSection />
            <AccountHistoryPanel />
          </Grid>
        </StyledGridContainer>
      </PortalPageLayout>
    </>
  );
};

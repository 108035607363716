import { IdType, UUIDType } from "@common/types/apiTypes";
import { atom } from "jotai";

interface ContractAddedModal {
  ids: UUIDType[];
}

interface EnergyBreakdown {
  offerSnapshotUuid: UUIDType;
}

interface SwitchEBillToPaperless {
  premiseId: IdType;
}

interface SwitchPrintToPaperless {
  premiseId: IdType;
  showRewardsIncentive: boolean;
}

export interface AccountModalsAtomType {
  contractsAdded: ContractAddedModal | null;
  disconnectDevice: true | null;
  energyBreakdown: EnergyBreakdown | null;
  renewalReminder: true | null;
  switchEBillToPaperless: SwitchEBillToPaperless | null;
  switchPrintToPaperless: SwitchPrintToPaperless | null;
  updateDevicePremise: true | null;
}

export const initialAccountModalsAtom: AccountModalsAtomType = {
  contractsAdded: null,
  disconnectDevice: null,
  energyBreakdown: null,
  renewalReminder: null,
  switchEBillToPaperless: null,
  switchPrintToPaperless: null,
  updateDevicePremise: null,
};

export const modalsStateAtom = atom<AccountModalsAtomType>(
  initialAccountModalsAtom
);

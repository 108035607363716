import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as ColdWeatherIcon } from "@design-system/icons/bill-factors/ColdWeather.svg";
import { ReactComponent as HotWeatherIcon } from "@design-system/icons/bill-factors/HotWeather.svg";
import { billFactorsTranslations } from "@portal-account/components/BillFactors/BillFactors.en.i18n";
import { StyledBillFactor } from "@portal-account/components/BillFactors/BillFactors.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

interface BillFactorWeatherProps {
  numberOfColdDays: number;
  numberOfHotDays: number;
}

export const BillFactorWeather = ({
  numberOfColdDays,
  numberOfHotDays,
}: BillFactorWeatherProps) => {
  const { translate } = useTranslations();
  const {
    tBillFactorsWeather,
    tBillFactorsColderDays,
    tBillFactorsHotterDays,
  } = translate(billFactorsTranslations);

  const isColdDays = numberOfColdDays > numberOfHotDays;

  return (
    <StyledBillFactor>
      {isColdDays ? <ColdWeatherIcon /> : <HotWeatherIcon />}
      <RhTypography fontWeight="Bold">{tBillFactorsWeather}</RhTypography>
      <RhTypography variant="body2">
        {isColdDays ? tBillFactorsColderDays : tBillFactorsHotterDays}
      </RhTypography>
    </StyledBillFactor>
  );
};

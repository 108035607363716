import { isProduction } from "@common/services/getEnvApplicationSettings.service";
import {
  loadReduxSlice,
  watchPersistedReduxSlice,
} from "@common/services/persistReduxSlice";
import {
  analyticsReducer,
  analyticsSliceName,
  initialAnalyticsState,
} from "@portal-account/slices/analyticsSlice";
import { billingDetailsReducer } from "@portal-account/slices/billingDetailsSlice";
import { customerPreferencesReducer } from "@portal-account/slices/customerPreferencesSlice";
import { productAddOnsSlice } from "@portal-account/slices/productAddOnSlice";
import { rewardsReducer } from "@portal-account/slices/rewardsSlice";
import { accountRootSaga } from "@portal-account/store/accountRoot.saga";
import { AccountStoreState } from "@portal-account/types/portalSlicesTypes";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

const sagaMiddleware = createSagaMiddleware();

export const accountRootReducers = combineReducers<AccountStoreState>({
  analytics: analyticsReducer,
  billingDetails: billingDetailsReducer,
  customerPreferences: customerPreferencesReducer,
  productAddOns: productAddOnsSlice.reducer,
  rewards: rewardsReducer,
});

const devToolsOptions = !isProduction()
  ? {
      name: "Account Store",
    }
  : false;

export const accountStore = configureStore({
  devTools: devToolsOptions,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(sagaMiddleware),
  preloadedState: {
    analytics: loadReduxSlice(analyticsSliceName, initialAnalyticsState),
  },
  reducer: accountRootReducers,
});

watchPersistedReduxSlice(analyticsSliceName, accountStore);
sagaMiddleware.run(accountRootSaga);

export type AccountState = ReturnType<typeof accountStore.getState>;

import { RhCheckboxField } from "@design-system/components/RhCheckboxField/RhCheckboxField";
import { RhFlexBox } from "@design-system/components/RhFlexBox/RhFlexBox";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import {
  MyAccountMailingAddressFieldsContainer,
  MyAccountMailingAddressFieldsStateContainer,
  MyAccountMailingAddressInputStyled,
} from "@portal-account/components/MyAccountMailingAddressForm/MyAccountMailingAddressFields.styled";
import { MyAccountSubmitButton } from "@portal-account/components/MyAccountSubmitButton/MyAccountSubmitButton";
import { StateSelectField } from "@portal-shared/components/StateSelectField/StateSelectField";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import React from "react";
import { useField, useFormState } from "react-final-form";

export const MyAccountMailingAddressFields = () => {
  const { t } = useRhIntl();
  const { values } = useFormState();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));
  const {
    input: { onChange: rffOnChange },
  } = useField<string>("state");

  const address = t("MyAccountMailingAddressForm.address");
  const unitNumber = t("MyAccountMailingAddressForm.unitNumber");
  const city = t("MyAccountMailingAddressForm.city");
  const zipCode = t("MyAccountMailingAddressForm.zipCode");

  return (
    <>
      <RhCheckboxField
        name="mailingAddressSameAsServiceAddress"
        label={t(
          "MyAccountMailingAddressForm.mailingAddressSameAsServiceAddress"
        )}
      />
      {!values.mailingAddressSameAsServiceAddress && (
        <MyAccountMailingAddressFieldsContainer>
          <MyAccountMailingAddressInputStyled
            name="addressLine1"
            autoComplete="address-line1"
            autoFocus
          >
            {address}
          </MyAccountMailingAddressInputStyled>
          <MyAccountMailingAddressInputStyled
            name="unitNumber"
            autoComplete="address-line2"
          >
            {unitNumber}
          </MyAccountMailingAddressInputStyled>
          <MyAccountMailingAddressInputStyled
            name="city"
            autoComplete="address-level2"
          >
            {city}
          </MyAccountMailingAddressInputStyled>
          <MyAccountMailingAddressFieldsStateContainer>
            <StateSelectField
              defaultValue={values.state || "TX"}
              onChange={rffOnChange}
              name="state"
              translationId="MyAccountMailingAddressForm.state"
            />
          </MyAccountMailingAddressFieldsStateContainer>
          <MyAccountMailingAddressInputStyled
            name="zipCode"
            autoComplete="postal-code"
            inputMode="numeric"
          >
            {zipCode}
          </MyAccountMailingAddressInputStyled>
        </MyAccountMailingAddressFieldsContainer>
      )}

      <RhFlexBox justifyContent="flex-end">
        <MyAccountSubmitButton isMobile={isMobile} />
      </RhFlexBox>
    </>
  );
};

import {
  StyledBillFactor,
  StyledBillFactorsContainer,
} from "@portal-account/components/BillFactors/BillFactors.styled";
import {
  StyledBillFactorsCard,
  StyledLoadingContainer,
} from "@portal-account/components/BillFactorsCard/BillFactorsCard.styled";
import { Skeleton } from "@portal-shared/components/Skeleton/Skeleton";
import React from "react";

export const BillFactorsCardSkeleton = () => (
  <StyledBillFactorsCard>
    <StyledLoadingContainer>
      <Skeleton />
      <StyledBillFactorsContainer>
        <StyledBillFactor>
          <Skeleton $height="10em" />
        </StyledBillFactor>
        <StyledBillFactor>
          <Skeleton $height="10em" />
        </StyledBillFactor>
        <StyledBillFactor>
          <Skeleton $height="10em" />
        </StyledBillFactor>
      </StyledBillFactorsContainer>
    </StyledLoadingContainer>
  </StyledBillFactorsCard>
);

import {
  StyledBillBreakdownCard,
  StyledBillBreakdownContainer,
} from "@portal-account/components/BillBreakdownCard/BillBreakdownCard.styled";
import { Skeleton } from "@portal-shared/components/Skeleton/Skeleton";
import React from "react";

export const BillBreakdownCardSkeleton = () => (
  <StyledBillBreakdownCard>
    <StyledBillBreakdownContainer>
      <Skeleton />
      <Skeleton $height="10em" />
      <Skeleton $height="10em" />
      <Skeleton $height="10em" />
    </StyledBillBreakdownContainer>
  </StyledBillBreakdownCard>
);

import { customerPreferencesCleared } from "@portal-account/slices/customerPreferencesSlice";
import { rewardsClear } from "@portal-account/slices/rewardsSlice";
import { portalAuthClient } from "@portal-shared/services/PortalAuthClient.service";
import { useEffect } from "react";
import { useDispatch } from "react-redux";

export const SignOutPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    portalAuthClient.signOut().then(() => {
      sessionStorage.clear();
      dispatch(customerPreferencesCleared());
      dispatch(rewardsClear());
    });
  }, []);

  return null;
};

import { loadingOverlayTranslations } from "@portal-account/components/LoadingOverlay/LoadingOverlay.en.i18n";
import {
  LoadingOverlayContainer,
  LoadingOverlayWrapper,
} from "@portal-account/components/LoadingOverlay/LoadingOverlay.styled";
import bike from "@portal-account/images/bike-loader.lottie.json";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import Lottie from "lottie-react";
import React from "react";

export const LoadingOverlay = () => {
  const { translate } = useTranslations();
  const { tLoadingOverlayDescription } = translate(loadingOverlayTranslations);

  return (
    <LoadingOverlayContainer role="progressbar">
      <LoadingOverlayWrapper>
        <Lottie
          title={tLoadingOverlayDescription}
          animationData={bike}
          autoplay
          loop
          rendererSettings={{ preserveAspectRatio: "xMidYMid slice" }}
        />
      </LoadingOverlayWrapper>
    </LoadingOverlayContainer>
  );
};

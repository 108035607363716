import { IdType } from "@common/types/apiTypes";
import { customerQueryKeys } from "@portal-account/queryKeys/customerQuerykeys";

export const premiseQueryKeys = {
  BASE: [...customerQueryKeys.me(), "premise"] as const,
  accountSummary: (premiseId: IdType) => [
    ...premiseQueryKeys.BASE,
    premiseId,
    "accountSummary",
  ],
  allRenewableOfferSnapshots: (premiseId: IdType) => [
    ...premiseQueryKeys.BASE,
    premiseId,
    "allRenewableOfferSnapshots",
  ],
  attachedDevices: (premiseId: IdType) => [
    ...premiseQueryKeys.BASE,
    premiseId,
    "attachedDevices",
  ],
  billForecastSummary: (premiseId: IdType) => [
    ...premiseQueryKeys.BASE,
    premiseId,
    "billForecastSummary",
  ],
  billingStatus: (premiseId: IdType) => [
    ...premiseQueryKeys.BASE,
    premiseId,
    "billingStatus",
  ],
  documents: (premiseId: IdType) => [
    ...premiseQueryKeys.BASE,
    premiseId,
    "documents",
  ],
  energyInvoices: (premiseId: IdType) => [
    ...premiseQueryKeys.BASE,
    premiseId,
    "invoices",
    "list-energy",
  ],
  fetchPaymentMethod: (premiseId: IdType, paymentMethodId: string) => [
    ...premiseQueryKeys.BASE,
    premiseId,
    "fetchPaymentMethod",
    paymentMethodId,
  ],
  invoiceHistory: (premiseId: IdType) => [
    ...premiseQueryKeys.BASE,
    premiseId,
    "invoiceHistory",
  ],
  kwhUsageDay: (premiseId: IdType, date: string) => [
    ...premiseQueryKeys.BASE,
    premiseId,
    "kwhUsageDay",
    date,
  ],
  kwhUsageSummary: (premiseId: IdType) => [
    ...premiseQueryKeys.BASE,
    premiseId,
    "kwhUsageSummary",
  ],
  paymentExtensionsActive: (premiseId: IdType) => [
    ...premiseQueryKeys.BASE,
    premiseId,
    "paymentExtension",
    "active",
  ],
  paymentExtensionsQualified: (premiseId: IdType) => [
    ...premiseQueryKeys.BASE,
    premiseId,
    "paymentExtension",
    "qualified",
  ],
  paymentHistory: (premiseId: IdType) => [
    ...premiseQueryKeys.BASE,
    premiseId,
    "paymentHistory",
  ],
  paymentMethods: (premiseId: IdType) => [
    ...premiseQueryKeys.BASE,
    premiseId,
    "paymentMethods",
  ],
  renewalOfferSnapshots: (premiseId: IdType) => [
    ...premiseQueryKeys.BASE,
    premiseId,
    "renewalOfferSnapshots",
  ],
  retrieve: (premiseId: IdType) =>
    [...premiseQueryKeys.BASE, premiseId] as const,
  temperatureSummary: (premiseId: IdType) => [
    ...premiseQueryKeys.BASE,
    premiseId,
    "temperatureSummary",
  ],
  thermostatConnectionActivity: ({
    premiseId,
    searchOptions,
  }: {
    premiseId: IdType;
    searchOptions: {
      limit: number;
      offset: number;
      thermostatId: IdType;
    };
  }) => [
    ...premiseQueryKeys.BASE,
    premiseId,
    "thermostatConnectionActivity",
    searchOptions,
  ],
  thermostatDemandResponseActivity: ({
    premiseId,
    searchOptions,
  }: {
    premiseId: IdType;
    searchOptions: {
      limit: number;
      offset: number;
      thermostatId: IdType;
    };
  }) =>
    [
      ...premiseQueryKeys.BASE,
      premiseId,
      "thermostatDemandResponseActivity",
      searchOptions,
    ] as const,
};

import { PremiseListItemType } from "@common/types/premiseTypes";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { DevicePremiseSelector } from "@portal-account/components/DevicePremiseSelector/DevicePremiseSelector";
import { selectedDevicesWithPremiseAtom } from "@portal-account/components/DevicePremiseSelector/DevicePremiseSelector.atoms";
import { devicesEnrollSelectDevicesFormTranslations } from "@portal-account/components/DevicesEnrollSelectDevicesForm/DevicesEnrollSelectDevicesForm.en.i18n";
import {
  SelectDeviceAddressContainer,
  SelectDeviceContainer,
  SelectDeviceInfoColumn,
  SelectDeviceStatus,
} from "@portal-account/components/DevicesEnrollSelectDevicesForm/SelectDevice.styled";
import { StyledCheckIcon } from "@portal-account/components/PortalSelector/PortalSelector.styled";
import { useDeviceStatus } from "@portal-account/hooks/useDeviceStatus";
import { DeviceType } from "@portal-account/types/devicesTypes";
import { Skeleton } from "@portal-shared/components/Skeleton/Skeleton";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { useAtomValue } from "jotai";
import React from "react";

interface SelectDeviceProps {
  device: DeviceType;
  premises: PremiseListItemType[] | undefined;
}

export const SelectDevice = (props: SelectDeviceProps) => {
  const { device, premises } = props;
  const { deviceId, deviceBrand, deviceModel, deviceName, deviceStatus } =
    device;
  const deviceStatusText = useDeviceStatus();
  const { translate } = useTranslations();
  const selectedDevicesWithPremise = useAtomValue(
    selectedDevicesWithPremiseAtom
  );

  const {
    tDevicesEnrollSelectDevicesFormAddress,
    tDevicesEnrollSelectDevicesFormDeviceName,
    tDevicesEnrollSelectDevicesFormDeviceStatus,
    tDevicesEnrollSelectDevicesFormDeviceType,
    tDevicesEnrollSelectDevicesFormModelNumber,
  } = translate(devicesEnrollSelectDevicesFormTranslations);

  const thisDevice = selectedDevicesWithPremise.find(
    (deviceAtom) => deviceAtom.device.deviceId === deviceId
  );

  const isSelected = Boolean(thisDevice);

  return (
    <SelectDeviceContainer $isSelected={isSelected}>
      {isSelected ? (
        <StyledCheckIcon aria-label={`Device ${deviceId} selected`} />
      ) : null}

      <SelectDeviceInfoColumn>
        <div>
          <RhTypography fontWeight={FontWeight.Semibold} variant="body1">
            {tDevicesEnrollSelectDevicesFormDeviceName}
          </RhTypography>
          {deviceName}
        </div>
        <div>
          <RhTypography fontWeight={FontWeight.Semibold} variant="body1">
            {tDevicesEnrollSelectDevicesFormDeviceType}
          </RhTypography>
          {deviceBrand}
        </div>
      </SelectDeviceInfoColumn>
      <SelectDeviceInfoColumn>
        <div>
          <RhTypography fontWeight={FontWeight.Semibold} variant="body1">
            {tDevicesEnrollSelectDevicesFormModelNumber}
          </RhTypography>
          {deviceModel}
        </div>
        <div>
          <RhTypography fontWeight={FontWeight.Semibold} variant="body1">
            {tDevicesEnrollSelectDevicesFormDeviceStatus}
          </RhTypography>
          <SelectDeviceStatus $status={deviceStatus}>
            {deviceStatusText[deviceStatus]}
          </SelectDeviceStatus>
        </div>
      </SelectDeviceInfoColumn>
      <SelectDeviceAddressContainer>
        <RhTypography fontWeight={FontWeight.Semibold} variant="body1">
          {tDevicesEnrollSelectDevicesFormAddress}
        </RhTypography>
        {premises ? (
          <DevicePremiseSelector device={device} premises={premises} />
        ) : (
          <Skeleton $height="55px" />
        )}
      </SelectDeviceAddressContainer>
    </SelectDeviceContainer>
  );
};

import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { renewalsPageOfferSnapshotAtom } from "@portal-account/pages/RenewalsPage/RenewalsPage.atoms";
import { renewalsPageTranslations } from "@portal-account/pages/RenewalsPage/RenewalsPage.en.i18n";
import { usePremiseAccountSummaryQuery } from "@portal-account/queries/usePremiseAccountSummaryQuery";
import { usePremiseRenewalOfferSnapshotsQuery } from "@portal-account/queries/usePremiseRenewalOfferSnapshotsQuery";
import { accountRenewalsSummaryPath } from "@portal-account/routes/routePaths";
import { PortalPageLayout } from "@portal-shared/components/PortalPageLayout/PortalPageLayout";
import { RenewalPageLayout } from "@portal-shared/components/RenewalPageLayout/RenewalPageLayout";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import { Error404Page } from "@portal-shared/pages/Error404Page/Error404Page";
import { Error500Page } from "@portal-shared/pages/Error500Page/Error500Page";
import { usePortalOfferSnapshotQuery } from "@portal-shared/queries/usePortalOfferSnapshotQuery";
import { rootPath } from "@portal-shared/routes/routePaths";
import { useSetAtom } from "jotai";
import React from "react";
import { Navigate, useNavigate } from "react-router-dom";

export const RenewalsPage = () => {
  const { premise } = usePremiseFromContext();
  const accountSummaryQuery = usePremiseAccountSummaryQuery({
    premiseId: premise.id,
  });
  const { translate, translateJsx } = useTranslations();
  const { tRenewalsPageTranslationsTitle, tRenewalsPageTranslationsExplore } =
    translate(renewalsPageTranslations);
  const setOfferSnapshot = useSetAtom(renewalsPageOfferSnapshotAtom);
  const navigate = useNavigate();

  const { tRenewalsPageTranslationsChoose } = translateJsx({
    tRenewalsPageTranslationsChoose: {
      address: premise.serviceAddress?.deliveryLine1,
    },
  });

  const renewalOfferSnapshotsQuery = usePremiseRenewalOfferSnapshotsQuery({
    premiseId: premise.id,
  });

  const currentOrderOfferSnapshot = usePortalOfferSnapshotQuery({
    offerSnapshotUuid: premise.currentOrder?.offerSnapshotUuid ?? "",
    queryOptions: {
      enabled: Boolean(premise.currentOrder?.offerSnapshotUuid),
    },
  });

  if (!premise.inRenewalWindow) {
    return <Navigate to={rootPath()} />;
  }

  if (
    renewalOfferSnapshotsQuery.isPending ||
    accountSummaryQuery.isPending ||
    currentOrderOfferSnapshot.isPending
  ) {
    return (
      <PortalPageLayout>
        <RhCircularProgress />
      </PortalPageLayout>
    );
  }

  if (
    renewalOfferSnapshotsQuery.isError ||
    accountSummaryQuery.isError ||
    currentOrderOfferSnapshot.isError
  ) {
    return (
      <PortalPageLayout>
        <Error500Page />
      </PortalPageLayout>
    );
  }

  if (!premise.currentOrder) {
    return <PortalPageLayout>{null}</PortalPageLayout>;
  }

  const handleSelectOfferSnapshot = (offerSnapshot: OfferSnapshot) => {
    setOfferSnapshot(offerSnapshot);
    navigate(accountRenewalsSummaryPath());
  };

  const {
    myAccountOfferSnapshots,
    oneClickRenewalOfferSnapshot,
    potentialSavingsOneClickOffer,
  } = renewalOfferSnapshotsQuery.data;

  if (myAccountOfferSnapshots.length === 0 && !oneClickRenewalOfferSnapshot) {
    return (
      <PortalPageLayout>
        <Error404Page />
      </PortalPageLayout>
    );
  }

  return (
    <RenewalPageLayout
      currentOrder={premise.currentOrder}
      currentOrderOfferSnapshot={currentOrderOfferSnapshot.data}
      exploreOtherOffersTitle={tRenewalsPageTranslationsExplore}
      handleSelectOfferSnapshot={handleSelectOfferSnapshot}
      otherOfferSnapshots={myAccountOfferSnapshots}
      premiseId={premise.id}
      renewalOfferSnapshot={oneClickRenewalOfferSnapshot}
      subtitle={tRenewalsPageTranslationsChoose}
      title={tRenewalsPageTranslationsTitle}
      estimatedSavings={potentialSavingsOneClickOffer}
    />
  );
};

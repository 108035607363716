import { useCustomerFromContext } from "@portal-account/components/CustomerProvider/useCustomerFromContext";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { createTrackEvent } from "@portal-account/services/segment.service";

export function useTrackMyAccountEvents() {
  const { premise } = usePremiseFromContext();
  const customer = useCustomerFromContext();

  const trackEvent = createTrackEvent({
    customerId: customer?.id,
    premiseId: premise.id,
  });

  return trackEvent;
}

import { IdType } from "@common/types/apiTypes";
import { premiseApi } from "@portal-account/api/premiseApi";
import { premiseQueryKeys } from "@portal-account/queryKeys/premiseQueryKeys";
import { QUERY_STALE_TIME_DEFAULT_LONG } from "@portal-shared/constants/queryOptions.constants";
import { useQuery } from "@tanstack/react-query";

interface UseBillForecastSummaryProps {
  premiseId: IdType;
}
export const useBillForecastSummaryQuery = (
  props: UseBillForecastSummaryProps
) => {
  const { premiseId } = props;

  return useQuery({
    queryFn: () => premiseApi.billForecastSummary(premiseId),
    queryKey: premiseQueryKeys.billForecastSummary(premiseId),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: QUERY_STALE_TIME_DEFAULT_LONG,
  });
};

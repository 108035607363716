import { IdType, UUIDType } from "@common/types/apiTypes";
import { z } from "zod";

export type OrderIdType = OrderType["id"];

export enum OrderStatus {
  Active = "ACTIVE",
  Enrolled = "ENROLLED",
  Expired = "EXPIRED",
  Uncreated = "UNCREATED",
}

export const EarlyTerminationFeeZod = z.object({
  feeAmount: z.number(),
  monthsRemaining: z.number(),
});

export type EarlyTerminationFee = z.infer<typeof EarlyTerminationFeeZod>;

export interface OrderType {
  averageRateAt2000Kwh: string;
  contractEndDate: string;
  daysUntilOrderExpires: number | null;
  description: string;
  dunsNumber: string;
  earlyTerminationFee?: EarlyTerminationFee | null;
  endDate: string;
  energyRate: number;
  expectedEndDate?: string | null;
  hasContractExpiration: boolean;
  id: IdType;
  isTimeOfUse: boolean;
  offerSnapshotSolarEligible: boolean;
  offerSnapshotUuid: IdType;
  offersnapshotId: string;
  orderNumber: string;
  renewalToken: string | null;
  solarBuybackEnergyRate: string;
  solarEligible: boolean;
  solarGenerationCapped: boolean;
  startDate: string;
  status: OrderStatus;
  termMonths: number;
  termsOfServiceEn: string;
  termsOfServiceEs: string;
  termsOfServiceVersion: string | null;
  title: string;
  utilityName?: string;
  uuid: UUIDType;
  welcomePacketGeneratedAt: string | null;
}

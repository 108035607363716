import { CustomerContext } from "@portal-account/components/CustomerProvider/CustomerContext";
import { useContext } from "react";

export const useCustomerFromContext = () => {
  const customer = useContext(CustomerContext);

  if (!customer) {
    throw new Error("CustomerContext: No value provided");
  }

  return customer;
};

import { ISO8601_DATE_FORMAT } from "@common/constants/date.constant";
import { IdType } from "@common/types/apiTypes";
import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhModal } from "@design-system/components/RhModal/RhModal";
import { ModalWidths } from "@design-system/components/RhModal/RhModal.styled";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as WarningIcon } from "@design-system/icons/WarningIcon.svg";
import { rhSpacing } from "@design-system/theme/spacing";
import { useCancelProductContractMutation } from "@portal-account/mutations/useCancelProductContractMutation";
import { ProductContractCancelErrors } from "@portal-account/types/errorTypes";
import { ProductContractType } from "@portal-account/types/productContractTypes";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import dayjs from "dayjs";
import React, { useState } from "react";
import styled from "styled-components";

interface ProductContractOptOutProps {
  premiseId: IdType;
  productContract: ProductContractType;
}

const StyledButton = styled(RhButton)`
  &.MuiButton-root {
    color: ${CSSVariables.COLOR_WHITE};
    margin: ${rhSpacing(1)}px auto;
    padding: ${rhSpacing(1)}px ${rhSpacing(2)}px;

    &:hover {
      background-color: unset;
      text-decoration: underline;
      text-decoration-color: ${CSSVariables.COLOR_PRIMARY_MEDIUM};
    }
    &:hover p {
      color: ${CSSVariables.COLOR_PRIMARY_MEDIUM};
    }
  }
`;

const StyledOptOutMessage = styled(RhTypography)`
  &.MuiTypography-root {
    text-align: center;
  }
`;

const StyledButtonContainer = styled.footer`
  display: flex;
  gap: ${rhSpacing(2)}px;
  justify-content: center;
  width: 100%;
`;

const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: ${rhSpacing(2)}px;
  margin-bottom: ${rhSpacing(3)}px;
`;

// TODO: CHECK IF THIS COLOR WORKS CORRECTLY
export const ProductContractOptOut = ({
  productContract,
  premiseId,
}: ProductContractOptOutProps) => {
  const flash = useRhFlash();

  const { t, tJsx } = useRhIntl();

  const [modalOpen, setModalOpen] = useState(false);

  const mutation = useCancelProductContractMutation({ premiseId });
  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = () => {
    setModalOpen(true);
  };

  // For now, we are going to manually set the end date to today until future functionality calls for choosing this
  const today = dayjs().format(ISO8601_DATE_FORMAT);

  const onConfirm = () => {
    mutation.mutate(
      {
        contractId: productContract.id,
        endDate: today,
        premiseId,
      },
      {
        onError: (error) => {
          const errorCode: ProductContractCancelErrors = error.data
            .errorCode as ProductContractCancelErrors;

          let flashError: string;

          switch (errorCode) {
            case ProductContractCancelErrors.PRODUCT_CONTRACT_ALREADY_ENDED: {
              flashError = t(
                "ProductContractOptOut.productContractAlreadyEnded"
              );
              break;
            }
            case ProductContractCancelErrors.PRODUCT_CONTRACT_END_DATE_INVALID: {
              flashError = t(
                "ProductContractOptOut.productContractEndDateInvalid"
              );
              break;
            }
            case ProductContractCancelErrors.PRODUCT_CONTRACT_PENDING: {
              flashError = t("ProductContractOptOut.productContractPending");
              break;
            }
            default: {
              flashError = t("ProductContractOptOut.errorMessage");
              break;
            }
          }

          flash.error(flashError);
        },
        onSuccess: () => {
          flash.success(
            <div>
              {tJsx("ProductContractOptOut.successMessage", {
                tierName: productContract.tierName,
              })}
            </div>
          );
          closeModal();
        },
      }
    );
  };

  const optOutMessage = tJsx("ProductContractOptOut.message", {
    bold: (str) => <strong>{str}</strong>,
    tierName: productContract.tierName,
  });

  return (
    <>
      <StyledButton
        data-tracking-click={{ event: "" }}
        variant="text"
        fullWidth
        onClick={openModal}
      >
        <RhTypography variant="body2" color="primary">
          {t("ProductContractOptOut.optOutButton")}
        </RhTypography>
      </StyledButton>

      {modalOpen ? (
        <RhModal
          size={ModalWidths.auto}
          ariaLabel="Confirm Cancellation"
          ariaCloseLabel="Close"
          handleClose={closeModal}
        >
          <section>
            <StyledContainer>
              <WarningIcon />
              <StyledOptOutMessage variant="body1">
                {optOutMessage}
              </StyledOptOutMessage>
              <RhTypography variant="body1">
                {t("ProductContractOptOut.areYouSure")}
              </RhTypography>
            </StyledContainer>

            <StyledButtonContainer>
              <RhButton
                data-tracking-click={{
                  event: "Customer canceled opting out from product contract",
                }}
                variant="outlined"
                color="primary"
                onClick={closeModal}
                fullWidth
              >
                {t("ProductContractOptOut.cancel")}
              </RhButton>
              <RhButton
                data-tracking-click={{
                  event: "Customer opted out from product add-on",
                }}
                color="primary"
                onClick={onConfirm}
                fullWidth
              >
                {t("ProductContractOptOut.confirm")}
              </RhButton>
            </StyledButtonContainer>
          </section>
        </RhModal>
      ) : null}
    </>
  );
};

import { useAccountModals } from "@portal-account/components/AccountModalsManager/useAccountModals";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { usePortalCookies } from "@portal-account/hooks/usePortalCookies";
import { useEffect } from "react";

export enum SwitchEBillToPaperlessModalStates {
  HIDDEN = "hidden",
  SHOW = "show",
}

export const useSwitchEBillToPaperlessModal = () => {
  const { premise } = usePremiseFromContext();

  const {
    cookies: { rhSwitchEBillToPaperlessModal },
  } = usePortalCookies();

  const { addModal } = useAccountModals();

  useEffect(() => {
    if (
      premise.isOnLegacyEBill &&
      rhSwitchEBillToPaperlessModal === SwitchEBillToPaperlessModalStates.SHOW
    ) {
      addModal({
        switchEBillToPaperless: {
          premiseId: premise.id,
        },
      });
    }
  }, [addModal, premise, rhSwitchEBillToPaperlessModal]);

  return null;
};

import { IdType } from "@common/types/apiTypes";
import { premiseApi } from "@portal-account/api/premiseApi";
import { AccountSummary } from "@portal-account/models/AccountSummary.model";
import { premiseQueryKeys } from "@portal-account/queryKeys/premiseQueryKeys";
import { useQuery, useQueryClient } from "@tanstack/react-query";

interface UsePremiseAccountSummaryQueryProps {
  premiseId: IdType;
  queryOptions?: {
    enabled?: boolean;
  };
}

export const usePremiseAccountSummaryQuery = (
  props: UsePremiseAccountSummaryQueryProps
) => {
  const { premiseId, queryOptions: { enabled = true } = {} } = props;

  return useQuery({
    enabled,
    queryFn: () => premiseApi.accountSummary(premiseId),
    queryKey: premiseQueryKeys.accountSummary(premiseId),
    select: (data) => new AccountSummary(data),
  });
};

export function useInvalidatePremiseAccountSummaryQuery() {
  const queryClient = useQueryClient();

  return (premiseId: IdType) => {
    return queryClient.invalidateQueries({
      queryKey: premiseQueryKeys.accountSummary(premiseId),
    });
  };
}

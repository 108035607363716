import { billingDetailsWatcher } from "@portal-account/sagas/billingDetails.saga";
import { rewardsWatcher } from "@portal-account/sagas/rewards.saga";
import { all } from "redux-saga/effects";

// Register your sagas here
const accountSagas = [billingDetailsWatcher(), rewardsWatcher()];

export function* accountRootSaga() {
  yield all(accountSagas);
}

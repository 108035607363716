import {
  formatAsLocalTime,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { deviceConnectionActivityTableTranslations } from "@portal-account/components/DeviceConnectionActivityTable/DeviceConnectionActivityTable.en.i18n";
import { useDeviceConnectionActivityEvent } from "@portal-account/components/DeviceConnectionActivityTable/useDeviceConnectionActivityEvent";
import { MyAccountTable } from "@portal-account/components/MyAccountTable/MyAccountTable";
import { DeviceConnectionActivityType } from "@portal-account/types/devicesTypes";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { createColumnHelper } from "@tanstack/react-table";
import React, { Dispatch, SetStateAction } from "react";

interface DeviceConnectionActivityTableProps {
  connectionActivity: DeviceConnectionActivityType[];
  pageCount: number;
  pageIndex: number;
  pageSize: number;
  setPagination: Dispatch<
    SetStateAction<{ pageIndex: number; pageSize: number }>
  >;
}

export const DeviceConnectionActivityTable = (
  props: DeviceConnectionActivityTableProps
) => {
  const { connectionActivity, pageCount, pageIndex, pageSize, setPagination } =
    props;
  const { translate } = useTranslations();
  const deviceConnectionActivityEvents = useDeviceConnectionActivityEvent();
  const {
    tDeviceConnectionActivityTableActivity,
    tDeviceConnectionActivityTableDate,
    tDeviceConnectionActivityTableTime,
  } = translate(deviceConnectionActivityTableTranslations);

  const columnHelper = createColumnHelper<DeviceConnectionActivityType>();

  const columns = [
    columnHelper.display({
      cell: ({ row }) => <b>{formatMonthDayYear(row.original.occurredAt)}</b>,
      header: tDeviceConnectionActivityTableDate,
      id: "occurredAt",
    }),
    columnHelper.display({
      cell: ({ row }) => formatAsLocalTime(row.original.occurredAt),
      header: tDeviceConnectionActivityTableTime,
      id: "timeOccurredAt",
    }),
    columnHelper.display({
      cell: ({ row }) => deviceConnectionActivityEvents[row.original.eventType],
      header: tDeviceConnectionActivityTableActivity,
      id: "eventType",
    }),
  ];

  return (
    <MyAccountTable<DeviceConnectionActivityType>
      columns={columns}
      data={connectionActivity}
      rowId="occurredAt"
      pagination
      pageCount={pageCount}
      pageIndex={pageIndex}
      pageSize={pageSize}
      setPagination={setPagination}
    />
  );
};

import { IdType } from "@common/types/apiTypes";
import {
  formatCurrency,
  formatMonthDayYear,
} from "@common/utils/dataFormatters";
import { invoiceFileDownload } from "@common/utils/invoiceFileDownload";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { ReactComponent as DownloadIcon } from "@design-system/icons/DownloadIcon.svg";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { billBreakdownInvoiceCardTranslations } from "@portal-account/components/BillBreakdownInvoiceCard/BillBreakdownInvoiceCard.en.i18n";
import { BillBreakdownInvoiceCardSkeleton } from "@portal-account/components/BillBreakdownInvoiceCard/BillBreakdownInvoiceCard.skeleton";
import {
  StyledBillBreakdownInvoiceCard,
  StyledBillBreakdownInvoiceCardDivider,
  StyledBillBreakdownInvoiceCardTitle,
  StyledDownloadLink,
  StyledInvoiceLink,
} from "@portal-account/components/BillBreakdownInvoiceCard/BillBreakdownInvoiceCard.styled";
import { Premise } from "@portal-account/models/Premise.model";
import { useInvoiceQuery } from "@portal-account/queries/useInvoiceQuery";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

interface BillBreakdownInvoiceCardProps {
  invoiceId: IdType;
  premise: Premise;
}

export const BillBreakdownInvoiceCard = (
  props: BillBreakdownInvoiceCardProps
) => {
  const { premise, invoiceId } = props;

  const { data: invoice, isPending, isError } = useInvoiceQuery({ invoiceId });
  const { translate } = useTranslations();
  const flash = useRhFlash();

  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up(RhythmBreakpoints.SM));

  const {
    tBillBreakdownInvoiceCardBillDate,
    tBillBreakdownInvoiceCardDueDate,
    tBillBreakdownInvoiceCardInvoice,
    tBillBreakdownInvoiceCardAmountDue,
    tBillBreakdownInvoiceCardTotalUsage,
    tBillBreakdownInvoiceCardErrorViewingBill,
  } = translate(billBreakdownInvoiceCardTranslations);

  if (isPending) {
    return <BillBreakdownInvoiceCardSkeleton />;
  }

  if (isError) {
    return (
      <StyledBillBreakdownInvoiceCard>
        <RhTypography>{tBillBreakdownInvoiceCardErrorViewingBill}</RhTypography>
      </StyledBillBreakdownInvoiceCard>
    );
  }

  const handleDownload = () => {
    invoiceFileDownload({
      billingAccountNumber: premise.billingAccountNumber,
      invoiceDate: invoice.invoiceDate,
      invoiceId: invoice.id,
      premiseId: premise.id,
    }).catch(() => {
      flash.error(tBillBreakdownInvoiceCardErrorViewingBill);
    });
  };

  return (
    <StyledBillBreakdownInvoiceCard>
      <StyledBillBreakdownInvoiceCardTitle>
        {tBillBreakdownInvoiceCardBillDate}
      </StyledBillBreakdownInvoiceCardTitle>
      <StyledBillBreakdownInvoiceCardTitle>
        {tBillBreakdownInvoiceCardTotalUsage}
      </StyledBillBreakdownInvoiceCardTitle>
      <StyledBillBreakdownInvoiceCardTitle>
        {tBillBreakdownInvoiceCardAmountDue}
      </StyledBillBreakdownInvoiceCardTitle>
      <StyledBillBreakdownInvoiceCardTitle>
        {tBillBreakdownInvoiceCardDueDate}
      </StyledBillBreakdownInvoiceCardTitle>
      {isDesktop ? (
        <StyledBillBreakdownInvoiceCardTitle>
          {tBillBreakdownInvoiceCardInvoice}
        </StyledBillBreakdownInvoiceCardTitle>
      ) : null}
      <StyledBillBreakdownInvoiceCardDivider />
      <div>
        <RhTypography>{formatMonthDayYear(invoice.invoiceDate)}</RhTypography>
        {isDesktop ? null : (
          <StyledInvoiceLink onClick={handleDownload}>
            {tBillBreakdownInvoiceCardInvoice}
          </StyledInvoiceLink>
        )}
      </div>
      <RhTypography>{`${invoice.totalKwh} kWh`}</RhTypography>
      <RhTypography>{formatCurrency(invoice.amount)}</RhTypography>
      <RhTypography>{formatMonthDayYear(invoice.dueDate)}</RhTypography>
      {isDesktop ? (
        <div>
          <StyledDownloadLink onClick={handleDownload}>
            <DownloadIcon />
          </StyledDownloadLink>
        </div>
      ) : null}
    </StyledBillBreakdownInvoiceCard>
  );
};

import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledComparePowerSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1.5)};
  margin: 0 ${rhSpacingPx(2)};
`;

export const StyledComparePowerContainer = styled.div`
  align-items: center;
  background-color: ${CSSVariables.COLOR_PRIMARY_LIGHT};
  border: 1px solid ${CSSVariables.COLOR_PRIMARY_MEDIUM};
  border-radius: 6px;
  box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.1);
  color: ${CSSVariables.COLOR_GREY_700};
  display: flex;
  flex-direction: column;
  gap: ${rhSpacingPx(1)};
  padding: ${rhSpacingPx(2.5)} 0;
  text-align: center;

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    width: 530px;
  }
`;

export const StyledComparePowerButton = styled(RhLinkButton)`
  &.MuiButton-root {
    background-color: ${CSSVariables.COLOR_WHITE};
    border-color: ${CSSVariables.COLOR_SECONDARY_MEDIUM};
    margin-top: ${rhSpacingPx(1)};
    padding: ${rhSpacingPx(0.5)} ${rhSpacingPx(3)};
  }
`;

export const StyledDepositRequiredPageOrRow = styled.div`
  align-items: center;
  display: flex;
  gap: ${rhSpacingPx(2)};
  width: 100%;

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    width: 530px;
  }
`;

export const StyledDepositRequiredPageLine = styled.div`
  background-color: ${CSSVariables.COLOR_GREY_100};
  flex-grow: 1;
  height: 1px;
`;

import { FeatureFlagClientProvider } from "@common/components/FeatureFlagClientProvider/FeatureFlagClientProvider";
import { useIsExcludedUtmMedium } from "@portal-enroll/hooks/useIsExcludedUtmMedium";
import {
  ActionType,
  EnrollmentEvents,
  track,
} from "@portal-enroll/services/segment.service";
import { useFeatureFlagUserId } from "@portal-shared/hooks/useFeatureFlagUserId/useFeatureFlagUserId";
import React, { PropsWithChildren, useEffect } from "react";

interface EnrollFeatureFlagClientProviderProps {}

export const EnrollFeatureFlagClientProvider = (
  props: PropsWithChildren<EnrollFeatureFlagClientProviderProps>
) => {
  const { children } = props;

  const isExcludedUtmMedium = useIsExcludedUtmMedium();
  const featureFlagUserId = useFeatureFlagUserId();

  useEffect(() => {
    if (featureFlagUserId) {
      track({
        action: ActionType.userIdentified,
        category: "portal",
        event: EnrollmentEvents.identify,
        label: "User Identified",
        value: featureFlagUserId,
      });
    }
  }, [featureFlagUserId]);

  return (
    <FeatureFlagClientProvider
      featureFlagUserId={featureFlagUserId}
      sendEvents={!isExcludedUtmMedium}
    >
      {children}
    </FeatureFlagClientProvider>
  );
};

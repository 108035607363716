import {
  AccountModalsAtomType,
  initialAccountModalsAtom,
  modalsStateAtom,
} from "@portal-account/components/AccountModalsManager/AccountModalsManager.atoms";
import { useBaseModals } from "@portal-shared/hooks/useBaseModals";

export const useAccountModals = () => {
  return useBaseModals<AccountModalsAtomType>(
    modalsStateAtom,
    initialAccountModalsAtom
  );
};

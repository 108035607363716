import { EM_DASH } from "@common/constants/characters.constant";
import { UsageType } from "@common/types/usageTypes";
import { formatCurrency, formatKwh } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { translations as EnergyUsageSectionV2Translations } from "@portal-account/components/EnergyUsageSectionV2/EnergyUsageSectionV2.en.i18n";
import { EnergyUsageTooltip } from "@portal-account/components/EnergyUsageTooltip/EnergyUsageTooltip";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";
import styled from "styled-components";

export const TotalContainer = styled.div`
  display: grid;
  gap: ${rhSpacingPx(1)};
  grid-auto-flow: column;
  grid-template-rows: 1fr 1fr;
`;

const TitleContainer = styled.span`
  display: flex;
  flex-direction: row;
  gap: ${rhSpacingPx(0.5)};
`;

interface TotalUsageProps {
  showConsumption: boolean;
  showEarned: boolean;
  showGeneration: boolean;
  usageData: UsageType[];
}

interface TotalUsageType {
  totalConsumptionCost: number;
  totalConsumptionKwh: number;
  totalGenerationEarned: number;
  totalGenerationKwh: number;
}

export const TotalUsage = (props: TotalUsageProps) => {
  const { usageData, showGeneration, showEarned, showConsumption } = props;

  const { translate } = useTranslations();
  const {
    tEnergyUsageSectionV2TotalUsage,
    tEnergyUsageSectionV2TotalCost,
    tEnergyUsageSectionV2SurplusCredits,
    tEnergyUsageSectionV2TotalGeneration,
    tEnergyUsageSectionV2TotalUsageTooltip,
    tEnergyUsageSectionV2TotalCostTooltip,
    tEnergyUsageSectionV2TotalEarnedTooltip,
  } = translate(EnergyUsageSectionV2Translations);

  const totalUsage: TotalUsageType = usageData.reduce(
    (accumulator, currentValue) => {
      return {
        totalConsumptionCost:
          accumulator.totalConsumptionCost +
          Number(currentValue.consumptionCost),
        totalConsumptionKwh:
          accumulator.totalConsumptionKwh + Number(currentValue.consumptionKwh),
        totalGenerationEarned:
          accumulator.totalGenerationEarned +
          Number(currentValue.generationEarned),
        totalGenerationKwh:
          accumulator.totalGenerationKwh + Number(currentValue.generationKwh),
      };
    },
    {
      totalConsumptionCost: 0,
      totalConsumptionKwh: 0,
      totalGenerationEarned: 0,
      totalGenerationKwh: 0,
    }
  );

  return (
    <>
      {showGeneration && totalUsage.totalGenerationKwh > 0 ? (
        <TotalContainer>
          <RhTypography variant="body1" fontWeight="Bold">
            {tEnergyUsageSectionV2TotalGeneration}
          </RhTypography>
          <RhTypography variant="body1">
            {formatKwh(totalUsage.totalGenerationKwh, 0)}
          </RhTypography>
          <TitleContainer>
            <RhTypography variant="body1" fontWeight="Bold">
              {tEnergyUsageSectionV2SurplusCredits}
            </RhTypography>
            <EnergyUsageTooltip
              ariaLabel={tEnergyUsageSectionV2SurplusCredits}
              title={<>{tEnergyUsageSectionV2TotalEarnedTooltip}</>}
            />
          </TitleContainer>

          <RhTypography variant="body1">
            {showEarned
              ? formatCurrency(totalUsage.totalGenerationEarned, 2)
              : EM_DASH}
          </RhTypography>
        </TotalContainer>
      ) : null}

      {showConsumption ? (
        <TotalContainer>
          <TitleContainer>
            <RhTypography variant="body1" fontWeight="Bold">
              {tEnergyUsageSectionV2TotalUsage}
            </RhTypography>
            <EnergyUsageTooltip
              ariaLabel={tEnergyUsageSectionV2TotalUsage}
              title={<>{tEnergyUsageSectionV2TotalUsageTooltip}</>}
            />
          </TitleContainer>

          <RhTypography variant="body1">
            {formatKwh(totalUsage.totalConsumptionKwh, 0)}
          </RhTypography>

          <TitleContainer>
            <RhTypography variant="body1" fontWeight="Bold">
              {tEnergyUsageSectionV2TotalCost}
            </RhTypography>
            <EnergyUsageTooltip
              ariaLabel={tEnergyUsageSectionV2TotalCost}
              title={<>{tEnergyUsageSectionV2TotalCostTooltip}</>}
            />
          </TitleContainer>

          <RhTypography variant="body1">
            {formatCurrency(totalUsage.totalConsumptionCost, 2)}
          </RhTypography>
        </TotalContainer>
      ) : null}
    </>
  );
};

import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { brandTheme } from "@design-system/brandTheme/getBrandTheme";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { ReactComponent as Scale } from "@design-system/icons/Scale.svg";
import { OfferFeatures } from "@portal-shared/components/OfferFeatures/OfferFeatures";
import { OfferRateAndEstimate } from "@portal-shared/components/OfferRateAndEstimate/OfferRateAndEstimate";
import { RenewalAnnualSavingsTag } from "@portal-shared/components/RenewalAnnualSavingsTag/RenewalAnnualSavingsTag";
import { MINIMUM_AMOUNT_TO_SHOW_SAVINGS_TAG } from "@portal-shared/components/RenewalAnnualSavingsTag/RenewalAnnualSavingsTag.constants";
import { renewalOfferSnapshotCardTranslations } from "@portal-shared/components/RenewalOfferSnapshotCard/RenewalOfferSnapshotCard.en.i18n";
import {
  ButtonContainer,
  CalloutHeader,
  CompareContainer,
  FeaturesContainer,
  RenewalCardBody,
  RenewalCardContainer,
  StyledCompareButton,
  StyledOfferSnapshotDescription,
  TitleContainer,
} from "@portal-shared/components/RenewalOfferSnapshotCard/RenewalOfferSnapshotCard.styled";
import { TrackingView } from "@portal-shared/components/TrackingView/TrackingView";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { OfferSnapshot } from "@portal-shared/models/OfferSnapshot.model";
import React, { useId } from "react";

interface RenewalOfferSnapshotCardProps {
  estimatedSavings?: string;
  offerSnapshot: OfferSnapshot;
  onCompareClick?: (offerSnapshot: OfferSnapshot) => void;
  onLearnMoreClick: (offerSnapshot: OfferSnapshot) => void;
  onSelect: (offerSnapshot: OfferSnapshot) => void;
  recommended?: boolean;
}

export const RenewalOfferSnapshotCard = (
  props: RenewalOfferSnapshotCardProps
) => {
  const {
    offerSnapshot,
    onLearnMoreClick,
    onSelect,
    recommended,
    onCompareClick,
    estimatedSavings,
  } = props;

  const { translate } = useTranslations();
  const titleId = useId();

  const { featureFlagClient } = useFeatureFlagClient();

  const { portalEstimatedAnnualSavingsTag } = featureFlagClient.getFlags([
    ["portalEstimatedAnnualSavingsTag", false],
  ]);

  const {
    tRenewalOfferSnapshotCardLearnMore,
    tRenewalOfferSnapshotCardRecommendedPlan,
    tRenewalOfferSnapshotCardRenewNow,
    tRenewalOfferSnapshotCardOurRates,
  } = translate(renewalOfferSnapshotCardTranslations);

  const [locale] = useLocaleAtom();

  const hasCallout = recommended || offerSnapshot.hasCallout();

  const calloutBackgroundColor = recommended
    ? brandTheme.colors.primary.main
    : offerSnapshot.calloutBackgroundColor();

  const calloutText = recommended
    ? tRenewalOfferSnapshotCardRecommendedPlan
    : offerSnapshot.calloutText(locale);

  const showAnnualSavingsTag =
    portalEstimatedAnnualSavingsTag.value &&
    estimatedSavings &&
    Number(estimatedSavings) > MINIMUM_AMOUNT_TO_SHOW_SAVINGS_TAG;

  return (
    <RenewalCardContainer
      onClick={() => onSelect(offerSnapshot)}
      $calloutColor={calloutBackgroundColor}
      $hasCallout={hasCallout}
      aria-labelledby={titleId}
    >
      {hasCallout ? (
        <CalloutHeader $calloutColor={calloutBackgroundColor}>
          <RhTypography fontWeight={FontWeight.Bold}>
            {calloutText}
          </RhTypography>
        </CalloutHeader>
      ) : null}

      {showAnnualSavingsTag ? (
        <RenewalAnnualSavingsTag estimatedSavings={estimatedSavings} />
      ) : null}

      <TrackingView
        data={{
          event: "Renewal offer snapshot viewed",
          offerSnapshotUuid: offerSnapshot.uuid,
          recommended: !!recommended,
        }}
      />

      <RenewalCardBody>
        <TitleContainer>
          <RhTypography variant="h3" id={titleId}>
            {offerSnapshot.title}
          </RhTypography>

          <StyledOfferSnapshotDescription variant="body1" color="textSecondary">
            {offerSnapshot.description(locale)}
          </StyledOfferSnapshotDescription>
        </TitleContainer>

        <FeaturesContainer>
          <OfferFeatures offerSnapshot={offerSnapshot} />
        </FeaturesContainer>

        <OfferRateAndEstimate offerSnapshot={offerSnapshot} />

        {onCompareClick !== undefined ? (
          <CompareContainer>
            <StyledCompareButton
              data-tracking-click={JSON.stringify({
                event: "Customer clicked our rate vs others",
              })}
              type="button"
              onClick={(event) => {
                event.stopPropagation();
                onCompareClick(offerSnapshot);
              }}
            >
              <Scale aria-hidden /> {tRenewalOfferSnapshotCardOurRates}
            </StyledCompareButton>
          </CompareContainer>
        ) : null}
      </RenewalCardBody>

      <ButtonContainer>
        <RhButton
          data-tracking-click={{
            event: "Customer opening Renewal Plan Learn More modal",
          }}
          color="primary"
          size="small"
          variant="outlined"
          fullWidth
          onClick={(event) => {
            event.stopPropagation();
            onLearnMoreClick(offerSnapshot);
          }}
        >
          {tRenewalOfferSnapshotCardLearnMore}
        </RhButton>
        <RhButton
          data-tracking-click={{
            event: "Customer selecting a Renewal Plan",
            offerSnapshotUuid: offerSnapshot.uuid,
          }}
          fullWidth
          color="primary"
          size="small"
          onClick={() => onSelect(offerSnapshot)}
        >
          {tRenewalOfferSnapshotCardRenewNow}
        </RhButton>
      </ButtonContainer>
    </RenewalCardContainer>
  );
};

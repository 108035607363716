import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { MyAccountContactFormValues } from "@portal-account/components/MyAccountContact/MyAccountContact";
import {
  isRequired,
  isValidEmail,
  isValidPhoneNumber,
} from "@portal-shared/forms/validators";

export const myAccountContactFormValidator =
  generateValidationErrorCollector<MyAccountContactFormValues>({
    email: [isRequired, isValidEmail],
    phone: [isRequired, isValidPhoneNumber],
  });

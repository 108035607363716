import { rhWindow } from "@common/utils/rhWindow";
import { scrollToOffsetPosition } from "@common/utils/scrollToOffsetPosition";
import { RhButton } from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { useCustomerFromContext } from "@portal-account/components/CustomerProvider/useCustomerFromContext";
import { MyAccountBillingPreferencesCard } from "@portal-account/components/MyAccountBillingPreferencesCard/MyAccountBillingPreferencesCard";
import { MyAccountCommunicationPreferencesCard } from "@portal-account/components/MyAccountCommunicationPreferencesCard/MyAccountCommunicationPreferencesCard";
import { MyAccountContact } from "@portal-account/components/MyAccountContact/MyAccountContact";
import { MyAccountLanguageCard } from "@portal-account/components/MyAccountLanguageCard/MyAccountLanguageCard";
import { AccountNavAnchorTag } from "@portal-account/components/MyAccountNav/myAccountNaConfig";
import { MyAccountNav } from "@portal-account/components/MyAccountNav/MyAccountNav";
import { MyAccountPasswordChange } from "@portal-account/components/MyAccountPasswordChange/MyAccountPasswordChange";
import { MyAccountPaymentMethodsCard } from "@portal-account/components/MyAccountPaymentMethodsCard/MyAccountPaymentMethodsCard";
import { MyAccountProductAddOnsCard } from "@portal-account/components/MyAccountProductAddOnsCard/MyAccountProductAddOnsCard";
import { MyAccountServiceInfo } from "@portal-account/components/MyAccountServiceInfo/MyAccountServiceInfo";
import { MyAccountYourPlanCard } from "@portal-account/components/MyAccountYourPlanCard/MyAccountYourPlanCard";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import {
  MyAccountContainer,
  MyAccountNavLinksContainer,
  MyAccountSectionContainer,
  StyledBackToTopContainer,
  StyledHeader,
  StyledUpArrow,
} from "@portal-account/pages/MyAccountPage/MyAccountPage.styled";
import { PortalPageLayout } from "@portal-shared/components/PortalPageLayout/PortalPageLayout";
import React, { useCallback, useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useLocation } from "react-router-dom";

export const MyAccountPage = () => {
  const { formatMessage } = useIntl();
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));
  const isTablet = useMediaQuery(
    theme.breakpoints.between(RhythmBreakpoints.SM, RhythmBreakpoints.MD)
  );
  const isSmallDesktop = useMediaQuery(
    theme.breakpoints.between(RhythmBreakpoints.MD, RhythmBreakpoints.LG)
  );

  const [backButtonPresent, setBackButtonPresent] = useState(false);
  const location = useLocation();
  const anchorId = location?.state?.anchorId;

  const customer = useCustomerFromContext();
  const { premise } = usePremiseFromContext();

  const handleScroll = useCallback(() => {
    if (!isPhone) {
      return;
    }
    const elemTop: number =
      document.getElementById("myAccountHeader")?.offsetTop || 0;

    if (elemTop < document.documentElement.scrollTop && !backButtonPresent) {
      setBackButtonPresent(true);
    }
    if (elemTop > document.documentElement.scrollTop && backButtonPresent) {
      setBackButtonPresent(false);
    }
  }, [backButtonPresent, isPhone]);

  useEffect(() => {
    rhWindow.addEventListener("scroll", handleScroll);
    return () => rhWindow.removeEventListener("scroll", handleScroll);
  }, [handleScroll]);
  useEffect(() => {
    const anchorElement = document.getElementById(anchorId ?? "");

    if (anchorElement) {
      scrollToOffsetPosition(anchorElement);
    }
  }, [anchorId]);

  if ((isSmallDesktop || isTablet) && backButtonPresent) {
    setBackButtonPresent(false);
  }

  const scrollToTop = () =>
    rhWindow.scrollTo({
      behavior: "smooth",
      left: 0,
      top: 0,
    });

  return (
    <PortalPageLayout>
      <StyledHeader id="myAccountHeader">
        <RhTypography variant="h1">
          {formatMessage({ id: "MyAccountPage.account" })}
        </RhTypography>
      </StyledHeader>

      <MyAccountContainer>
        <MyAccountNavLinksContainer>
          <MyAccountNav />
        </MyAccountNavLinksContainer>

        <MyAccountSectionContainer>
          <div id={AccountNavAnchorTag.serviceInfo}>
            <MyAccountServiceInfo />
          </div>
          <div id={AccountNavAnchorTag.yourPlan}>
            <MyAccountYourPlanCard />
          </div>
          <div id={AccountNavAnchorTag.productAddOns}>
            <MyAccountProductAddOnsCard />
          </div>
          <div id={AccountNavAnchorTag.contactInfo}>
            <MyAccountContact customer={customer} />
          </div>
          <div id={AccountNavAnchorTag.languagePreference}>
            <MyAccountLanguageCard customer={customer} />
          </div>
          <div id={AccountNavAnchorTag.changePassword}>
            <MyAccountPasswordChange customer={customer} />
          </div>
          <div id={AccountNavAnchorTag.communicationPreferences}>
            <MyAccountCommunicationPreferencesCard />
          </div>
          <div id={AccountNavAnchorTag.billingPreferences}>
            <MyAccountBillingPreferencesCard
              averagedBillingMonthlyCharge={
                premise.averagedBillingMonthlyCharge
              }
              canPickBillingDueDay={
                (premise.meter?.isSmt ?? false) &&
                premise.meter?.smtSyncedAt !== null
              }
              initialPreferences={{
                averagedBilling: Boolean(premise.averagedBilling),
                pickedBillingDueDay: premise.pickedBillingDueDay,
              }}
            />
          </div>
          <div id={AccountNavAnchorTag.paymentMethods}>
            <MyAccountPaymentMethodsCard />
          </div>
          {backButtonPresent && (
            <StyledBackToTopContainer data-testid="myAccountPage__backToTop">
              <RhButton
                data-tracking-click={{
                  event: "Customer going back to top of My Account page",
                }}
                variant="text"
                color="primary"
                onClick={scrollToTop}
                size="small"
              >
                <StyledUpArrow />
                {formatMessage({ id: "MyAccountPage.backToTop" })}
              </RhButton>
            </StyledBackToTopContainer>
          )}
        </MyAccountSectionContainer>
      </MyAccountContainer>
    </PortalPageLayout>
  );
};

import { legacyCustomerApi } from "@portal-account/api/legacyCustomerApi";
import { customerQueryKeys } from "@portal-account/queryKeys/customerQuerykeys";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export const useCustomerLegacyUpdateMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: legacyCustomerApi.legacyUpdate,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: customerQueryKeys.BASE,
      });
    },
  });
};

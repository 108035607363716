import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { premiseApi } from "@portal-account/api/premiseApi";
import { premiseQueryKeys } from "@portal-account/queryKeys/premiseQueryKeys";
import { KwhUsageSummaryType } from "@portal-account/types/usageTypes";
import { QUERY_STALE_TIME_DEFAULT_LONG } from "@portal-shared/constants/queryOptions.constants";
import { useQuery } from "@tanstack/react-query";

interface UsePremiseKwhUsageOptions {
  enabled: boolean;
  premiseId: IdType;
}

export const usePremiseKwhUsageSummaryQuery = (
  options: UsePremiseKwhUsageOptions
) => {
  const { premiseId, enabled } = options;

  const query = useQuery<KwhUsageSummaryType, RhApiError>({
    enabled,
    queryFn: () => premiseApi.kwhUsageSummary(premiseId),
    queryKey: premiseQueryKeys.kwhUsageSummary(premiseId),
    refetchOnWindowFocus: false,
    retry: false,
    staleTime: QUERY_STALE_TIME_DEFAULT_LONG,
  });

  return query;
};

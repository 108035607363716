import { IdType, UUIDType } from "@common/types/apiTypes";
import { ProductContractStatusFilter } from "@portal-account/types/productContractTypes";

export const productContractQueryKeys = {
  claims: (premiseId: IdType, productContractId: UUIDType) =>
    [
      "premise",
      premiseId,
      "product-contract",
      productContractId,
      "claims",
    ] as const,
  list: {
    DEFAULT: (premiseId: IdType) =>
      ["premise", premiseId, "product-contract"] as const,
    active: (premiseId: IdType, contractIds: UUIDType[] = []) =>
      [
        ...productContractQueryKeys.list.DEFAULT(premiseId),
        ProductContractStatusFilter.ACTIVE,
        { contractIds },
      ] as const,
    expired: (premiseId: IdType) =>
      [
        ...productContractQueryKeys.list.DEFAULT(premiseId),
        ProductContractStatusFilter.EXPIRED,
      ] as const,
  },
};

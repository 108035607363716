import { TypedEntries } from "@common/types/typeUtils";
import {
  AccountModalsAtomType,
  initialAccountModalsAtom,
} from "@portal-account/components/AccountModalsManager/AccountModalsManager.atoms";
import { getAccountModalsByPriority } from "@portal-account/components/AccountModalsManager/getAccountModalsByPriority";
import { useAccountModals } from "@portal-account/components/AccountModalsManager/useAccountModals";
import { DisconnectDeviceModal } from "@portal-account/components/DisconnectDeviceModal/DisconnectDeviceModal";
import { EnergyBreakdownModal } from "@portal-account/components/EnergyBreakdownModal/EnergyBreakdownModal";
import { ProductAddonsOptInConfirmationModal } from "@portal-account/components/ProductAddonsOptInConfirmationModal/ProductAddonsOptInConfirmationModal";
import { RenewalReminderModal } from "@portal-account/components/RenewalReminderModal/RenewalReminderModal";
import { SwitchEBillToPaperlessModal } from "@portal-account/components/SwitchEBillToPaperlessModal/SwitchEBillToPaperlessModal";
import { SwitchPrintToPaperlessModal } from "@portal-account/components/SwitchPrintToPaperlessModal/SwitchPrintToPaperlessModal";
import { UpdateDevicePremiseModal } from "@portal-account/components/UpdateDevicePremiseModal/UpdateDevicePremiseModal";
import React, { ReactPortal } from "react";
import { createPortal } from "react-dom";

type ModalsMappedKeys = keyof typeof initialAccountModalsAtom;
type ModalsPriorityKeys = ReturnType<typeof getAccountModalsByPriority>[number];

type SameModalKeys = ModalsMappedKeys extends ModalsPriorityKeys
  ? ModalsPriorityKeys extends ModalsMappedKeys
    ? true
    : false
  : false;

// Dummy check to ensure modalsPriority has all modals
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummyKeysCheckDoNotDeleteOrChange: SameModalKeys = true;

export const AccountModalsManager = () => {
  const { modals, removeModal } = useAccountModals();

  const modalEntries = Object.entries(modals) as TypedEntries<typeof modals>;

  const modalsPrioritized = modalEntries
    .filter(([, value]) => value !== null)
    .sort((a, b) => {
      const modalsByPriority = getAccountModalsByPriority();

      return modalsByPriority.indexOf(a[0]) - modalsByPriority.indexOf(b[0]);
    });

  if (modalsPrioritized.length === 0) {
    return null;
  }

  const [modalKey, modalProps] = modalsPrioritized[0];

  let Modal: JSX.Element | null;

  switch (modalKey) {
    case "contractsAdded": {
      const props = modalProps as NonNullable<
        AccountModalsAtomType["contractsAdded"]
      >;

      Modal = (
        <ProductAddonsOptInConfirmationModal
          contractIdsAdded={props.ids}
          handleClose={() => {
            removeModal("contractsAdded");
          }}
        />
      );
      break;
    }
    case "disconnectDevice": {
      Modal = (
        <DisconnectDeviceModal
          handleClose={() => removeModal("disconnectDevice")}
        />
      );
      break;
    }
    case "energyBreakdown": {
      const props = modalProps as NonNullable<
        AccountModalsAtomType["energyBreakdown"]
      >;

      Modal = (
        <EnergyBreakdownModal
          offerSnapshotUuid={props.offerSnapshotUuid}
          handleClose={() => {
            removeModal("energyBreakdown");
          }}
        />
      );
      break;
    }

    case "switchEBillToPaperless": {
      const { premiseId } = modalProps as NonNullable<
        AccountModalsAtomType["switchEBillToPaperless"]
      >;

      Modal = (
        <SwitchEBillToPaperlessModal
          handleClose={() => {
            removeModal("switchEBillToPaperless");
          }}
          premiseId={premiseId}
        />
      );
      break;
    }

    case "switchPrintToPaperless": {
      const { premiseId, showRewardsIncentive } = modalProps as NonNullable<
        AccountModalsAtomType["switchPrintToPaperless"]
      >;

      Modal = (
        <SwitchPrintToPaperlessModal
          handleClose={() => {
            removeModal("switchPrintToPaperless");
          }}
          premiseId={premiseId}
          showRewardsIncentive={showRewardsIncentive}
        />
      );
      break;
    }

    case "renewalReminder": {
      Modal = (
        <RenewalReminderModal
          handleClose={() => {
            removeModal("renewalReminder");
          }}
        />
      );
      break;
    }

    case "updateDevicePremise": {
      Modal = (
        <UpdateDevicePremiseModal
          handleClose={() => removeModal("updateDevicePremise")}
        />
      );
      break;
    }

    default: {
      // Ensure all modal cases have been covered
      modalKey satisfies never;

      Modal = null;
      break;
    }
  }

  if (Modal === null) {
    return null;
  }

  return createPortal(Modal, document.body) as ReactPortal;
};

export const rootPath = (): string => {
  return "/";
};

export const signInPath = () => {
  return "/sign-in";
};

export const customerHomePath = () => {
  return "/home";
};

export const intlErrorPath = (): string => {
  return "/intl-error";
};

export const CREATE_PASSWORD = "create-password";

export const createPasswordPath = () => {
  return `/${CREATE_PASSWORD}`;
};

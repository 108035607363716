import { PremiseStatus } from "@common/enums/premise.enum";
import { formatCurrency, separateNumber } from "@common/utils/dataFormatters";
import { RhAnchor } from "@design-system/components/RhAnchor/RhAnchor";
import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhRouterLink } from "@design-system/components/RhRouterLink/RhRouterLink";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { customerRewardsSectionTranslations } from "@portal-account/components/CustomerRewardsSection/CustomerRewardsSection.en.i18n";
import {
  ActionsContainer,
  BalanceText,
  CustomerRewardsCard,
  PointsContainer,
  RewardsCard,
  StyledIllustrationContainer,
  StyledListPoints,
  StyledRewardsContainer,
  StyledTextContainer,
  StyledUnlockTextContainer,
} from "@portal-account/components/CustomerRewardsSection/CustomerRewardsSection.styled";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { RedeemRewards } from "@portal-account/components/RedeemRewards/RedeemRewards";
import { useGetCustomerRewards } from "@portal-account/hooks/useGetCustomerRewards";
import { ReactComponent as CoinMan } from "@portal-account/images/PortalCoinMan.svg";
import { rewardsPath } from "@portal-account/routes/routePaths";
import {
  PortalCardHeader,
  PortalCardTitle,
} from "@portal-shared/components/PortalCard/PortalCard.styled";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { usePortalOfferSnapshotQuery } from "@portal-shared/queries/usePortalOfferSnapshotQuery";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import React, { ReactNode } from "react";
import { useMatch } from "react-router-dom";

export const CustomerRewardsSection = () => {
  const isRewardsPage = useMatch(rewardsPath());
  const { translate, translateJsx } = useTranslations();
  const { rewards, requestMonitor, error } = useGetCustomerRewards();
  const { premise } = usePremiseFromContext();

  const offerSnapshotQuery = usePortalOfferSnapshotQuery({
    offerSnapshotUuid: premise.currentOrder?.offerSnapshotUuid ?? "",
    queryOptions: {
      enabled: Boolean(premise.currentOrder?.offerSnapshotUuid),
    },
  });

  const {
    tCustomerRewardsSectionAutoPay,
    tCustomerRewardsSectionAutoPayPoints,
    tCustomerRewardsSectionPaperless,
    tCustomerRewardsSectionPaperlessPoints,
    tCustomerRewardsSectionExplainer,
    tCustomerRewardsSectionFriend,
    tCustomerRewardsSectionFriendPoints,
    tCustomerRewardsSectionLearnMore,
    tCustomerRewardsSectionManWavingWithCoin,
    tCustomerRewardsSectionPoints,
    tCustomerRewardsSectionRewards,
    tCustomerRewardsSectionRewardsError,
  } = translate(customerRewardsSectionTranslations);

  // If the premise is inactive we don't want the customer to be able to redeem rewards for that account
  if (premise.status === PremiseStatus.INACTIVE) {
    return null;
  }

  if (requestMonitor.isWaiting || offerSnapshotQuery.isPending) {
    return (
      <CustomerRewardsCard>
        <PortalCardHeader>
          <PortalCardTitle>{tCustomerRewardsSectionRewards}</PortalCardTitle>
        </PortalCardHeader>
        <RhCircularProgress />
      </CustomerRewardsCard>
    );
  }

  if (
    offerSnapshotQuery.isError ||
    error ||
    !rewards ||
    rewards.balance === undefined
  ) {
    return (
      <CustomerRewardsCard>
        <PortalCardHeader>
          <PortalCardTitle>{tCustomerRewardsSectionRewards}</PortalCardTitle>
        </PortalCardHeader>
        <RhTypography variant="subtitle1" color="textSecondary">
          {tCustomerRewardsSectionRewardsError}
        </RhTypography>
      </CustomerRewardsCard>
    );
  }

  const {
    tCustomerRewardsSectionLifetime,
    tCustomerRewardsSectionMoreDetails,
    tCustomerRewardsSectionValue,
  } = translateJsx({
    tCustomerRewardsSectionLifetime: {
      lifetime: separateNumber(rewards?.lifetime ?? 0),
    },
    tCustomerRewardsSectionMoreDetails: {
      linkChunk: (content: ReactNode) => (
        <RhAnchor
          href={brandInformation.urlsFaqsRewards}
          target="_blank"
          rel="noopener noreferrer"
        >
          {content}
        </RhAnchor>
      ),
    },
    tCustomerRewardsSectionValue: {
      value: formatCurrency(rewards?.value ?? 0),
    },
  });

  const showAutopayReward =
    !offerSnapshotQuery.data.isAutoPayPaperlessDiscountOffer;
  const showPaperlessReward =
    !offerSnapshotQuery.data.isAutoPayPaperlessDiscountOffer;

  return (
    <CustomerRewardsCard>
      <PortalCardHeader>
        <PortalCardTitle>{tCustomerRewardsSectionRewards}</PortalCardTitle>
      </PortalCardHeader>

      <StyledRewardsContainer>
        <RewardsCard $noShadow>
          <PointsContainer>
            <StyledTextContainer>
              <BalanceText variant="h1" component="span">
                {separateNumber(rewards.balance ?? 0)}
              </BalanceText>
              <RhTypography variant="body1" component="span">
                {tCustomerRewardsSectionPoints}
              </RhTypography>

              <RhTypography variant="body1">
                {tCustomerRewardsSectionValue}
              </RhTypography>
              <RhTypography variant="body2" color="textSecondary">
                {tCustomerRewardsSectionLifetime}
              </RhTypography>
            </StyledTextContainer>
            <StyledIllustrationContainer>
              <CoinMan
                title={tCustomerRewardsSectionManWavingWithCoin}
                aria-hidden
              />
            </StyledIllustrationContainer>
          </PointsContainer>
          <RedeemRewards />
        </RewardsCard>
        <ActionsContainer>
          <StyledUnlockTextContainer variant="body1">
            {tCustomerRewardsSectionExplainer}
          </StyledUnlockTextContainer>
          <StyledListPoints>
            {showAutopayReward ? (
              <li>
                <RhTypography variant="body1" fontWeight={FontWeight.Semibold}>
                  {tCustomerRewardsSectionAutoPay}
                </RhTypography>
                <RhTypography variant="body2" color="textSecondary">
                  {tCustomerRewardsSectionAutoPayPoints}
                </RhTypography>
              </li>
            ) : null}
            {showPaperlessReward ? (
              <li>
                <RhTypography variant="body1" fontWeight={FontWeight.Semibold}>
                  {tCustomerRewardsSectionPaperless}
                </RhTypography>
                <RhTypography variant="body2" color="textSecondary">
                  {tCustomerRewardsSectionPaperlessPoints}
                </RhTypography>
              </li>
            ) : null}

            <li>
              <RhTypography variant="body1" fontWeight={FontWeight.Semibold}>
                {tCustomerRewardsSectionFriend}
              </RhTypography>
              <RhTypography variant="body2" color="textSecondary">
                {tCustomerRewardsSectionFriendPoints}
              </RhTypography>
            </li>
          </StyledListPoints>
          {isRewardsPage ? (
            <RhTypography variant="body1">
              {tCustomerRewardsSectionMoreDetails}
            </RhTypography>
          ) : (
            <RhRouterLink to={rewardsPath()}>
              <RhTypography component="span" variant="body1">
                {tCustomerRewardsSectionLearnMore}
              </RhTypography>
            </RhRouterLink>
          )}
        </ActionsContainer>
      </StyledRewardsContainer>
    </CustomerRewardsCard>
  );
};

import { IdType, UUIDType } from "@common/types/apiTypes";
import { CommonLocale } from "@common/types/commonLocaleTypes";
import { KeysMatch } from "@common/types/KeysMatch";
import {
  EarlyTerminationFee,
  OrderStatus,
  OrderType,
} from "@common/types/orderTypes";
import dayjs from "dayjs";

export enum ContractRate {
  Fixed = "Fixed",
  Variable = "Variable",
}

export class Order {
  public readonly id: IdType;
  private readonly termsOfService: Record<CommonLocale, string>;
  public readonly averageRateAt2000Kwh: number;
  public readonly termsOfServiceEn: string;
  public readonly termsOfServiceEs: string;
  public readonly termsOfServiceVersion: string | null;
  public readonly description: string;
  public readonly dunsNumber: string;
  public readonly daysUntilOrderExpires: number | null;
  public readonly earlyTerminationFee?: EarlyTerminationFee | null;
  public readonly endDate: string;
  public readonly energyRate: number;
  public readonly expectedEndDate?: string | null;
  public readonly hasContractExpiration: boolean;
  public readonly isTimeOfUse: boolean;
  public readonly offersnapshotId: string;
  public readonly offerSnapshotUuid: UUIDType;
  public readonly offerSnapshotSolarEligible: boolean;
  public readonly orderNumber: string;
  public readonly renewalToken: string | null;
  public readonly solarBuybackEnergyRate: string;
  public readonly solarEligible: boolean;
  public readonly solarGenerationCapped: boolean;
  public readonly startDate: string;
  public readonly status: OrderStatus;
  public readonly termMonths: number;
  public readonly title: string;
  public readonly utilityName?: string;
  public readonly uuid: UUIDType;
  public readonly welcomePacketGeneratedAt: string | null;

  constructor(order: OrderType) {
    this.averageRateAt2000Kwh = parseFloat(order.averageRateAt2000Kwh);
    this.termsOfService = {
      en: order.termsOfServiceEn,
      es: order.termsOfServiceEs,
    };
    this.termsOfServiceEn = order.termsOfServiceEn;
    this.termsOfServiceEs = order.termsOfServiceEs;
    this.termsOfServiceVersion = order.termsOfServiceVersion;
    this.daysUntilOrderExpires = order.daysUntilOrderExpires;
    this.description = order.description;
    this.dunsNumber = order.dunsNumber;
    this.earlyTerminationFee = order.earlyTerminationFee;
    this.endDate = order.endDate;
    this.energyRate = order.energyRate;
    this.expectedEndDate = order.expectedEndDate;
    this.hasContractExpiration = order.hasContractExpiration;
    this.id = order.id;
    this.isTimeOfUse = order.isTimeOfUse;
    this.offersnapshotId = order.offersnapshotId;
    this.offerSnapshotUuid = order.offerSnapshotUuid;
    this.offerSnapshotSolarEligible = order.offerSnapshotSolarEligible;
    this.orderNumber = order.orderNumber;
    this.renewalToken = order.renewalToken;
    this.solarBuybackEnergyRate = order.solarBuybackEnergyRate;
    this.solarEligible = order.solarEligible;
    this.solarGenerationCapped = order.solarGenerationCapped;
    this.startDate = order.startDate;
    this.status = order.status;
    this.termMonths = order.termMonths;
    this.title = order.title;
    this.utilityName = order.utilityName;
    this.uuid = order.uuid;
    this.welcomePacketGeneratedAt = order.welcomePacketGeneratedAt;
  }

  get contractEndDate() {
    return this.expectedEndDate;
  }

  // Also known as Month to Month or m2m
  get isMonthToMonth(): boolean {
    return Boolean(this.termMonths && this.termMonths < 2);
  }

  get contractRate(): ContractRate {
    return this.isMonthToMonth ? ContractRate.Variable : ContractRate.Fixed;
  }

  get remainingMonths(): number {
    return this.earlyTerminationFee?.monthsRemaining || 0;
  }

  get cancellationFee(): number {
    return this.earlyTerminationFee?.feeAmount || 0;
  }

  get isSolarBuybackPlan(): boolean {
    return this.solarEligible;
  }

  get renewalStartDate(): string {
    const today = dayjs();

    // If the order has expired or is month to month, use tomorrow as the start date
    if (dayjs(this.endDate) < today || this.isMonthToMonth) {
      return today.add(1, "day").toString();
    }
    return this.endDate;
  }

  termsOfServiceLink(locale: CommonLocale): string {
    return this.termsOfService[locale];
  }
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const dummyKeysCheckDoNotDeleteOrChange: KeysMatch<OrderType, Order> =
  undefined;

import {
  StyledBillTipsCard,
  StyledTipsList,
} from "@portal-account/components/BillTipsCard/BillTipsCard.styled";
import { Skeleton } from "@portal-shared/components/Skeleton/Skeleton";
import React from "react";

export const BillTipsCardSkeleton = () => (
  <StyledBillTipsCard>
    <Skeleton />
    <StyledTipsList>
      <Skeleton $height="10em" />
      <Skeleton $height="10em" />
      <Skeleton $height="10em" />
    </StyledTipsList>
  </StyledBillTipsCard>
);

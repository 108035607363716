import { ExternalPaymentMethod } from "@common/models/ExternalPaymentMethod.model";
import { PaymentMethod } from "@common/models/PaymentMethod.model";
import {
  ExternalPaymentMethodType,
  PaymentMethodType,
  PaymentMethodTypes,
} from "@common/types/paymentMethodTypes";
import { PaymentHistoryType } from "@common/types/premiseTypes";
import {
  formatCurrency,
  formatMonthDayYear,
  separateWordByUppercase,
} from "@common/utils/dataFormatters";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useMediaQuery, useTheme } from "@mui/material";
import { MyAccountTable } from "@portal-account/components/MyAccountTable/MyAccountTable";
import { paymentHistoryTableTranslations } from "@portal-account/components/PaymentHistoryTable/PaymentHistoryTable.en.i18n";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import React, { useMemo } from "react";

interface PaymentHistoryTableProps {
  paymentHistory: PaymentHistoryType[];
}

export const PaymentHistoryTable = (props: PaymentHistoryTableProps) => {
  const { paymentHistory } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(RhythmBreakpoints.XS));

  const { translate } = useTranslations();
  const {
    tPaymentHistoryTablePaymentDate,
    tPaymentHistoryTableAmountPaid,
    tPaymentHistoryTablePaymentId,
    tPaymentHistoryTablePaymentMethod,
  } = translate(paymentHistoryTableTranslations);

  const sortedPayments = useMemo(
    () =>
      paymentHistory.sort(
        (a, b) =>
          dayjs(b.effectiveDate).valueOf() - dayjs(a.effectiveDate).valueOf()
      ),
    [paymentHistory]
  );

  const columnHelper = createColumnHelper<PaymentHistoryType>();

  const paymentDateColumn = columnHelper.display({
    cell: ({ row }) => <b>{formatMonthDayYear(row.original.effectiveDate)}</b>,
    header: tPaymentHistoryTablePaymentDate,
    id: "paymentDate",
  });

  const amountPaidColumn = columnHelper.display({
    cell: ({ row }) => <b>{formatCurrency(row.original.amount)}</b>,
    header: tPaymentHistoryTableAmountPaid,
    id: "amountPaid",
  });

  const paymentMethodColumn = columnHelper.display({
    cell: ({ row }) => {
      const showMaskedAccountNumber =
        row.original.paymentMethodSnapshot.type ===
          PaymentMethodTypes.CreditCard ||
        row.original.paymentMethodSnapshot.type === PaymentMethodTypes.ACH;

      const paymentMethod = showMaskedAccountNumber
        ? new PaymentMethod(
            row.original.paymentMethodSnapshot as PaymentMethodType
          ).companyNameAndNumber
        : separateWordByUppercase(
            new ExternalPaymentMethod(
              row.original.paymentMethodSnapshot as ExternalPaymentMethodType
            ).type
          );

      return paymentMethod;
    },
    header: tPaymentHistoryTablePaymentMethod,
    id: "paymentMethod",
  });

  const paymentIdColumn = columnHelper.display({
    cell: ({ row }) => row.original.number,
    header: tPaymentHistoryTablePaymentId,
    id: "paymentId",
  });

  const columns = !isMobile
    ? [
        paymentDateColumn,
        amountPaidColumn,
        paymentMethodColumn,
        paymentIdColumn,
      ]
    : [
        paymentDateColumn,
        paymentMethodColumn,
        amountPaidColumn,
        paymentIdColumn,
      ];

  if (!paymentHistory) {
    return null;
  }

  return (
    <MyAccountTable<PaymentHistoryType>
      columns={columns}
      data={sortedPayments}
      rowId="effectiveDate"
    />
  );
};

import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { PaymentExtension } from "@portal-account/components/PaymentExtension/PaymentExtension";
import { PaymentExtensionUnableToCollect } from "@portal-account/components/PaymentExtensionUnableToCollect/PaymentExtensionUnableToCollect";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { usePremiseAccountSummaryQuery } from "@portal-account/queries/usePremiseAccountSummaryQuery";
import { usePremisePaymentMethodsQuery } from "@portal-account/queries/usePremisePaymentMethodsQuery";
import { PortalPageLayout } from "@portal-shared/components/PortalPageLayout/PortalPageLayout";
import { Error500Page } from "@portal-shared/pages/Error500Page/Error500Page";
import React from "react";

export const PaymentExtensionPage = () => {
  const { premise } = usePremiseFromContext();

  const accountSummaryQuery = usePremiseAccountSummaryQuery({
    premiseId: premise.id,
  });

  const premiseMethodsQuery = usePremisePaymentMethodsQuery({
    premiseId: premise.id,
  });

  if (accountSummaryQuery.isPending || premiseMethodsQuery.isPending) {
    return (
      <PortalPageLayout>
        <RhCircularProgress />
      </PortalPageLayout>
    );
  }

  if (accountSummaryQuery.isError) {
    return (
      <PortalPageLayout>
        <Error500Page />
      </PortalPageLayout>
    );
  }

  if (premise.isNoCheckOrAch && premise.isNoCreditOrDebitCard) {
    return <PaymentExtensionUnableToCollect />;
  }

  const accountSummary = accountSummaryQuery.data;

  return (
    <PaymentExtension
      disconnectionDueDate={
        premise.latestDisconnectNotice?.disconnectionDate || ""
      }
      pastDueBalance={accountSummary.pastDueBalance}
      paymentMethods={premiseMethodsQuery.data?.paymentMethods || []}
      premiseId={premise.id}
    />
  );
};

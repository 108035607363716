import { IdType } from "@common/types/apiTypes";
import { invoiceApi } from "@portal-account/api/invoiceApi";
import { invoiceQueryKeys } from "@portal-account/queryKeys/invoiceQueryKeys";
import { useQuery } from "@tanstack/react-query";

interface UseInvoiceOrdersQueryProps {
  invoiceId: IdType;
}

export const useInvoiceOrdersQuery = (props: UseInvoiceOrdersQueryProps) => {
  const { invoiceId } = props;

  return useQuery({
    queryFn: () => invoiceApi.orders(invoiceId),
    queryKey: invoiceQueryKeys.orders(invoiceId),
  });
};

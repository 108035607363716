import {
  OKTA_PASSWORD_REQUIREMENTS_ERROR,
  RhApiError,
} from "@common/types/errorTypes";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { useEnrollCookies } from "@enroll-utils/hooks/useEnrollCookies";
import { PortalPasswordField } from "@portal-account/components/PortalPasswordField/PortalPasswordField";
import { createPasswordPageTranslations } from "@portal-account/pages/CreatePasswordPage/CreatePasswordPage.en.i18n";
import {
  StyledCreatePasswordPageCaption,
  StyledCreatePasswordPurpleSection,
  StyledCreatePasswordPurpleSectionCustomerInfoContainer,
  StyledCreatePasswordPurpleSectionSubtitle,
  StyledCreatePasswordPurpleSectionText,
  StyledCreatePasswordPurpleSectionTextContainer,
} from "@portal-account/pages/CreatePasswordPage/CreatePasswordPage.styled";
import { analyticsEnableSendCustomerLoginEvent } from "@portal-account/slices/analyticsSlice";
import { LoggedOutFieldsLayout } from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutForm } from "@portal-shared/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal-shared/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { LoggedOutPageLayout } from "@portal-shared/components/LoggedOutPageLayout/LoggedOutPageLayout";
import { SESSION_STORAGE_CUSTOMER_KEY } from "@portal-shared/constants/customer.constant";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { ReactComponent as HighFives } from "@portal-shared/images/HighFives.svg";
import { portalAuthClient } from "@portal-shared/services/PortalAuthClient.service";
import { CustomerSessionStorageType } from "@portal-shared/types/customerTypes";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { useSessionStorage } from "usehooks-ts";

interface SignUpCreatePasswordFormValues {
  password: string;
}

export const CreatePasswordPage = () => {
  const dispatch = useDispatch();
  const flash = useRhFlash();
  const [searchParams] = useSearchParams();
  const activationToken = searchParams.get("activationToken") || undefined;
  const existingStateToken = searchParams.get("stateToken") || "";
  const [customerFromStorage] =
    useSessionStorage<CustomerSessionStorageType | null>(
      SESSION_STORAGE_CUSTOMER_KEY,
      null
    );

  const [stateToken, setStateToken] = useState<string>(existingStateToken);

  const { translate, translateJsx } = useTranslations();

  const {
    tCreatePasswordPageInvalidPassword,
    tCreatePasswordPagePasswordMinimumRequirements,
    tCreatePasswordPagePassword,
    tCreatePasswordPageCreatePassword,
    tCreatePasswordPageTwoFriendsHighFiving,
    tCreatePasswordPageUnknownOktaIssue,
    tCreatePasswordPageCreateYourPassword,
    tCreatePasswordPageStayInformed,
    tCreatePasswordPageSitTight,
  } = translate(createPasswordPageTranslations);
  const { removeCookie } = useEnrollCookies();

  const {
    tCreatePasswordPageThanks,
    tCreatePasswordPageAccountNumber,
    tCreatePasswordPageRequestedService,
  } = translateJsx({
    tCreatePasswordPageAccountNumber: {
      accountNumber: customerFromStorage?.accountNumber,
    },
    tCreatePasswordPageRequestedService: {
      startDate: formatMonthDayYear(customerFromStorage?.serviceStartDate),
    },
    tCreatePasswordPageThanks: { firstName: customerFromStorage?.firstName },
  });

  const createPassword = ({ password }: SignUpCreatePasswordFormValues) => {
    return portalAuthClient
      .createPassword({
        activationToken,
        password,
        stateToken,
      })
      .then(() => {
        dispatch(analyticsEnableSendCustomerLoginEvent());
        removeCookie("rhProspectUuid");
        sessionStorage.removeItem(SESSION_STORAGE_CUSTOMER_KEY);
      })
      .catch((error: RhApiError) => {
        setStateToken(error.data.stateToken || "");

        let errorMessage: string;

        switch (error.data.errorCode) {
          case OKTA_PASSWORD_REQUIREMENTS_ERROR: {
            errorMessage = tCreatePasswordPageInvalidPassword;
            break;
          }
          default: {
            errorMessage = tCreatePasswordPageUnknownOktaIssue;
          }
        }

        flash.error(errorMessage);

        return {
          password: [errorMessage],
        };
      });
  };

  const showPurpleSection =
    customerFromStorage &&
    customerFromStorage.accountNumber &&
    customerFromStorage.firstName &&
    customerFromStorage.serviceStartDate;

  return (
    <LoggedOutPageLayout>
      {showPurpleSection ? (
        <StyledCreatePasswordPurpleSection>
          <HighFives title={tCreatePasswordPageTwoFriendsHighFiving} />
          <StyledCreatePasswordPurpleSectionTextContainer>
            <RhTypography variant="h3" fontWeight="Bold">
              {tCreatePasswordPageThanks}
            </RhTypography>
            <StyledCreatePasswordPurpleSectionSubtitle>
              {tCreatePasswordPageSitTight}
            </StyledCreatePasswordPurpleSectionSubtitle>
            <StyledCreatePasswordPurpleSectionCustomerInfoContainer>
              <StyledCreatePasswordPurpleSectionText>
                {tCreatePasswordPageAccountNumber}
              </StyledCreatePasswordPurpleSectionText>
              <StyledCreatePasswordPurpleSectionText>
                {tCreatePasswordPageRequestedService}
              </StyledCreatePasswordPurpleSectionText>
            </StyledCreatePasswordPurpleSectionCustomerInfoContainer>
          </StyledCreatePasswordPurpleSectionTextContainer>
        </StyledCreatePasswordPurpleSection>
      ) : null}
      <LoggedOutPageHeader
        headerText={tCreatePasswordPageCreateYourPassword}
        subHeaderText={tCreatePasswordPageStayInformed}
      />
      <Form<SignUpCreatePasswordFormValues>
        onSubmit={createPassword}
        render={({ handleSubmit }) => (
          <LoggedOutForm
            onSubmit={handleSubmit}
            submitButtonText={tCreatePasswordPageCreatePassword}
            showProgress
          >
            <LoggedOutFieldsLayout dense>
              <PortalPasswordField name="password">
                {tCreatePasswordPagePassword}
              </PortalPasswordField>
              <StyledCreatePasswordPageCaption variant="caption" align="center">
                {tCreatePasswordPagePasswordMinimumRequirements}
              </StyledCreatePasswordPageCaption>
            </LoggedOutFieldsLayout>
          </LoggedOutForm>
        )}
      />
    </LoggedOutPageLayout>
  );
};

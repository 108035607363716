// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable @calm/react-intl/missing-formatted-message */
import { isProduction } from "@common/services/getEnvApplicationSettings.service";
import { RhBorderedDialog } from "@design-system/components/RhBorderedDialog/RhBorderedDialog";
import { PortalAccordion } from "@portal-shared/components/PortalAccordion/PortalAccordion.styled";
import { useLocaleAtom } from "@portal-shared/hooks/useLocaleAtom";
import { useShowI18nKeysAtom } from "@portal-shared/hooks/useShowI18nKeysAtom";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";

interface PortalDevToolProps {
  handleClose: () => void;
}

interface GlobalDevToolsProps {
  onSubmit: () => void;
}
const GlobalDevTools = (props: GlobalDevToolsProps) => {
  const { onSubmit } = props;
  const [locale, setLocale] = useLocaleAtom();
  const [showI18nKeys, setShowI18nKeys] = useShowI18nKeysAtom();

  const { register, handleSubmit } = useForm({
    defaultValues: {
      locale,
      showI18nKeys,
    },
  });

  const handleFormSubmit = handleSubmit((values) => {
    setLocale(values.locale);
    setShowI18nKeys(values.showI18nKeys);
    onSubmit();
  });

  return (
    <div>
      <form onSubmit={handleFormSubmit}>
        <fieldset>
          Language Preference
          <ul>
            <li>
              <label>
                English
                <input type="radio" value="en" {...register("locale")} />
              </label>
            </li>
            <li>
              <label>
                Spanish
                <input type="radio" value="es" {...register("locale")} />
              </label>
            </li>
          </ul>
        </fieldset>
        <label>
          Show i18n keys
          <input type="checkbox" {...register("showI18nKeys")} />
        </label>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

export const PortalDevTool = (props: PortalDevToolProps) => {
  const { handleClose } = props;

  useEffect(() => {
    if (isProduction()) {
      handleClose();
    }
  }, [isProduction]);

  return (
    <RhBorderedDialog open onClose={handleClose}>
      <h1>Portal Dev Tools</h1>

      <PortalAccordion open>
        <summary>Global</summary>
        <GlobalDevTools onSubmit={handleClose} />
      </PortalAccordion>
    </RhBorderedDialog>
  );
};

import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface AnalyticsStateType {
  sendCustomerLoginEvent: boolean;
}

// Handlers

export const enableSendCustomerLoginEventHandler: CaseReducer<
  AnalyticsStateType,
  PayloadAction
> = (state) => ({
  ...state,
  sendCustomerLoginEvent: true,
});

export const disableSendCustomerLoginEventHandler: CaseReducer<
  AnalyticsStateType,
  PayloadAction
> = (state) => ({
  ...state,
  sendCustomerLoginEvent: false,
});

export const customerLoggedOutHandler: CaseReducer<
  AnalyticsStateType,
  PayloadAction
> = (state) => ({
  ...state,
  sendCustomerLoginEvent: false,
});

// Reducer
export const initialAnalyticsState: AnalyticsStateType = {
  sendCustomerLoginEvent: false,
};

const reducers = {
  disable: disableSendCustomerLoginEventHandler,
  enable: enableSendCustomerLoginEventHandler,
};

export const analyticsSlice = createSlice({
  initialState: initialAnalyticsState,
  name: "analytics",
  reducers,
});

export const analyticsDisableSendCustomerLoginEvent =
  analyticsSlice.actions.disable;

export const analyticsEnableSendCustomerLoginEvent =
  analyticsSlice.actions.enable;

export const analyticsSliceName = analyticsSlice.name;
export const analyticsReducer = analyticsSlice.reducer;

import { IdType } from "@common/types/apiTypes";
import { premiseApi } from "@portal-account/api/premiseApi";
import { premiseQueryKeys } from "@portal-account/queryKeys/premiseQueryKeys";
import { useQuery } from "@tanstack/react-query";

interface UseEnergyInvoicesQueryProps {
  premiseId: IdType;
  queryOptions?: {
    enabled?: boolean;
  };
}

export const useEnergyInvoicesQuery = (props: UseEnergyInvoicesQueryProps) => {
  const { premiseId, queryOptions: { enabled = true } = {} } = props;

  return useQuery({
    enabled,
    queryFn: () => premiseApi.energyInvoices(premiseId),
    queryKey: premiseQueryKeys.energyInvoices(premiseId),
  });
};

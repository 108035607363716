import { StyledButton } from "@design-system/components/RhButton/RhButton.styled";
import { Button, ButtonProps } from "@mui/material";
import React from "react";
import { Link as RouterLink } from "react-router-dom";

export interface RhLinkButtonProps
  extends Omit<React.HTMLProps<HTMLAnchorElement>, "size"> {
  color?: ButtonProps["color"];
  "data-tracking-click": {
    [key: string]: string;
    event: string;
  };
  fullWidth?: ButtonProps["fullWidth"];
  href: string;
  size?: "small" | "medium" | "large";
  variant?: ButtonProps["variant"];
}

export const RhLinkButton = (props: RhLinkButtonProps) => {
  // for some reason typescript complaints if I use RhButton
  // even if the button props and RhButton props are the same
  const RhButtonAsMuiButton = StyledButton as typeof Button;

  const {
    children,
    className,
    color,
    "data-tracking-click": dataTrackingClick,
    disabled,
    fullWidth = true,
    href,
    onClick,
    size = "small",
    target = "_self",
    variant = "contained",
    rel,
  } = props;
  const isExternalLink = href.startsWith("http");

  return (
    <RhButtonAsMuiButton
      className={className}
      color={color}
      component={RouterLink}
      data-tracking-click={JSON.stringify(dataTrackingClick)}
      disableElevation
      aria-disabled={disabled}
      disabled={disabled}
      fullWidth={fullWidth}
      onClick={onClick}
      rel={isExternalLink ? "noopener noreferrer" : rel}
      role="button"
      size={size}
      target={target}
      to={href}
      variant={variant}
    >
      {children}
    </RhButtonAsMuiButton>
  );
};

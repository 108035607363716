import { IdType } from "@common/types/apiTypes";
import { premiseApi } from "@portal-account/api/premiseApi";
import { premiseQueryKeys } from "@portal-account/queryKeys/premiseQueryKeys";
import { useQuery } from "@tanstack/react-query";

interface UseActivePaymentExtensionQueryProps {
  premiseId: IdType;
}

export const useActivePaymentExtensionQuery = (
  props: UseActivePaymentExtensionQueryProps
) => {
  const { premiseId } = props;

  return useQuery({
    queryFn: () => premiseApi.paymentExtensionsActive(premiseId),
    queryKey: premiseQueryKeys.paymentExtensionsActive(premiseId),
  });
};

import { IdType } from "@common/types/apiTypes";
import { invoiceApi } from "@portal-account/api/invoiceApi";
import { invoiceQueryKeys } from "@portal-account/queryKeys/invoiceQueryKeys";
import { useQuery } from "@tanstack/react-query";

interface UseBillBreakdownCompareQueryProps {
  invoiceId: IdType;
}

export const useBillBreakdownCompareQuery = (
  props: UseBillBreakdownCompareQueryProps
) => {
  const { invoiceId } = props;

  return useQuery({
    queryFn: () => invoiceApi.compare(invoiceId),
    queryKey: invoiceQueryKeys.compare(invoiceId),
  });
};

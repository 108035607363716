import { RewardNames } from "@common/types/rewardsTypes";
import { rewardsHistoryTableRewardTranslations } from "@portal-account/components/RewardsHistoryTable/RewardsHistoryTable.rewards.en.i18n";
import { useTranslations } from "@portal-shared/hooks/useTranslations";

export const useRewardNames = () => {
  const { translate } = useTranslations();

  const {
    tRewardsHistoryTableRewardAutoPay,
    tRewardsHistoryTableRewardCampaignPromo,
    tRewardsHistoryTableRewardETFCredit,
    tRewardsHistoryTableRewardOfferPromo,
    tRewardsHistoryTableRewardOnFlow,
    tRewardsHistoryTableRewardPaperless,
    tRewardsHistoryTableRewardReferee,
    tRewardsHistoryTableRewardReferral,
    tRewardsHistoryTableRewardRhythmRewards,
    tRewardsHistoryTableAverageBillingOptIn,
    tRewardsHistoryTableRewardCourtesyCredit,
    tRewardsHistoryTableETFWaiver,
    tRewardsHistoryTableFeeWaiver,
    tRewardsHistoryTableLateFeeWaiver,
    tRewardsHistoryTableReRateCredit,
  } = translate(rewardsHistoryTableRewardTranslations);

  return {
    [RewardNames.AutoPay]: tRewardsHistoryTableRewardAutoPay,
    [RewardNames.AveragedBillingOptIn]: tRewardsHistoryTableAverageBillingOptIn,
    [RewardNames.CampaignPromo]: tRewardsHistoryTableRewardCampaignPromo,
    [RewardNames.CourtesyCredit]: tRewardsHistoryTableRewardCourtesyCredit,
    [RewardNames.eBill]: tRewardsHistoryTableRewardPaperless,
    [RewardNames.ETFCredit]: tRewardsHistoryTableRewardETFCredit,
    [RewardNames.ETFWaiver]: tRewardsHistoryTableETFWaiver,
    [RewardNames.FeeWaiver]: tRewardsHistoryTableFeeWaiver,
    [RewardNames.LateFeeWaiver]: tRewardsHistoryTableLateFeeWaiver,
    [RewardNames.OfferPromo]: tRewardsHistoryTableRewardOfferPromo,
    [RewardNames.OnFlow]: tRewardsHistoryTableRewardOnFlow,
    [RewardNames.Paperless]: tRewardsHistoryTableRewardPaperless,
    [RewardNames.Referee]: tRewardsHistoryTableRewardReferee,
    [RewardNames.Referral]: tRewardsHistoryTableRewardReferral,
    [RewardNames.ReRateCredit]: tRewardsHistoryTableReRateCredit,
    [RewardNames.RhythmRewards]: tRewardsHistoryTableRewardRhythmRewards,
  };
};

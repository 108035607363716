import { ReactComponent as ChevronDown } from "@design-system/icons/ChevronDown.svg";
import { Menu } from "@mui/material";
import {
  StyledAccountMenuBackdrop,
  StyledChevronIconContainer,
  StyledClickableContainer,
  StyledMenuCtaContainer,
  StyledMenuItemSpacer,
  StyledPopupAnchor,
  StyledStyledMenuItem,
} from "@portal-account/components/MyAccountHeader/AccountMenu/AccountMenu.styled";
import { myAccountHeaderTranslations } from "@portal-account/components/MyAccountHeader/MyAccountHeader.en.i18n";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React, { Children, FC, useState } from "react";

const POPUP_ANCHOR_ID = "AccountMenu__PopupAnchor";

interface AccountMenuProps {
  customerName: string | undefined;
}

export const AccountMenu: FC<React.PropsWithChildren<AccountMenuProps>> = ({
  customerName,
  children,
}) => {
  const [anchorEl, setAnchorEl] = useState<
    HTMLAnchorElement | HTMLElement | null
  >(null);
  const { translate } = useTranslations();

  const { tMyAccountHeaderMyAccount } = translate(myAccountHeaderTranslations);

  const openMenu = () => {
    setAnchorEl(document.getElementById(POPUP_ANCHOR_ID));
  };
  const closeMenu = () => {
    setAnchorEl(null);
  };
  const menuItems = Children.map(children, (child, index) => {
    return (
      Boolean(child) && (
        <StyledStyledMenuItem
          // eslint-disable-next-line react/no-array-index-key
          key={`AccountMenu__MenuItem-${index}`}
          onClick={() => setAnchorEl(null)}
        >
          <StyledMenuItemSpacer>{child}</StyledMenuItemSpacer>
        </StyledStyledMenuItem>
      )
    );
  });

  const customerLoading = customerName === undefined;

  return (
    <>
      <StyledAccountMenuBackdrop
        data-testid="RhHeader__Backdrop"
        open={Boolean(anchorEl)}
        style={{
          opacity: 0.25,
        }}
        onClick={closeMenu}
      />

      <StyledClickableContainer
        aria-controls="AccountMenu__AccountMenu"
        aria-haspopup="true"
        key="accountMenuLink"
        onClick={openMenu}
      >
        <StyledMenuCtaContainer $customerLoading={customerLoading}>
          <div data-testid="AccountMenu__dropdown_customerName">
            {customerName ?? tMyAccountHeaderMyAccount}
          </div>
          <StyledChevronIconContainer>
            <ChevronDown height="10px" width="10px" />
          </StyledChevronIconContainer>
        </StyledMenuCtaContainer>
        <StyledPopupAnchor id={POPUP_ANCHOR_ID} />
      </StyledClickableContainer>
      <Menu
        anchorEl={anchorEl}
        id="AccountMenu__AccountMenu"
        open={Boolean(anchorEl)}
        anchorOrigin={{
          horizontal: "right",
          vertical: "bottom",
        }}
        transformOrigin={{
          horizontal: "right",
          vertical: "top",
        }}
        keepMounted
        onClose={closeMenu}
        elevation={1}
        disableAutoFocusItem
      >
        {menuItems}
      </Menu>
    </>
  );
};

import { useDeviceStatusTranslations } from "@portal-account/hooks/useDeviceStatus.en.i18n";
import { DeviceStatus } from "@portal-account/types/devicesTypes";
import { useTranslations } from "@portal-shared/hooks/useTranslations";

export const useDeviceStatus = () => {
  const { translate } = useTranslations();

  const {
    tDeviceSelectorOffline,
    tDeviceSelectorOnline,
    tDeviceSelectorUnavailable,
  } = translate(useDeviceStatusTranslations);

  return {
    [DeviceStatus.Offline]: tDeviceSelectorOffline,
    [DeviceStatus.Online]: tDeviceSelectorOnline,
    [DeviceStatus.Unavailable]: tDeviceSelectorUnavailable,
  };
};

import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const StyledAllRightsReservedContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: ${rhSpacingPx(4)};

  @media (min-width: ${RhythmBreakpoints.SM}px) {
    max-width: 100%;
  }
`;

export const StyledAllRightsReservedLoggedOut = styled(RhTypography)`
  &.MuiTypography-root {
    max-width: 300px;
  }
`;

export const StyledUnauthenticatedContainer = styled.div`
  bottom: 0;
  margin-top: ${rhSpacingPx(14)};
  padding-bottom: ${rhSpacingPx(2)};
  padding-top: ${rhSpacingPx(2)};
  width: 100vw;
`;

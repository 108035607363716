import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { generateValidationErrorCollector } from "@common/forms/validationErrorCollector";
import { RhIconRadioField } from "@design-system/components/RhIconRadioField/RhIconRadioField";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { ReactComponent as BankIcon } from "@design-system/icons/BankIcon.svg";
import { ReactComponent as CreditCardsIcon } from "@design-system/icons/CreditCardsIcon.svg";
import { SignUpPageLayout } from "@portal-enroll/components/SignUpPageLayout/SignUpPageLayout";
import { useSignUpFlow } from "@portal-enroll/hooks/useSignUpFlow";
import { AddCardOrBankAccountZuoraForm } from "@portal-enroll/pages/SignUpPaymentPage/AddCardOrBankAccountZuoraForm";
import { signUpPaymentPageTranslations } from "@portal-enroll/pages/SignUpPaymentPage/SignUpPaymentPage.en.i18n";
import {
  StyledPaymentMethods,
  StyledSkipPaymentContainer,
} from "@portal-enroll/pages/SignUpPaymentPage/SignUpPaymentPage.styled";
import { selectProspectId } from "@portal-enroll/selectors/signUpSelectors";
import { ActionType } from "@portal-enroll/services/segment.service";
import { SignUpStoreState } from "@portal-enroll/slices/signUpSlice";
import { LoggedOutFieldsLayout } from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutForm } from "@portal-shared/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal-shared/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { isRequired } from "@portal-shared/forms/validators";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React, { useState } from "react";
import { Form } from "react-final-form";
import { useSelector } from "react-redux";

export interface SignUpPaymentFormValues {
  billingPaymentMethodId: string | null;
}

enum ActivePaymentFormType {
  ACH = "ACH",
  CreditDebit = "CreditDebit",
}

type PaymentType = keyof typeof ActivePaymentFormType | null;

interface SignUpSelectPaymentFormValues {
  paymentType: PaymentType;
}

const SignUpSelectPaymentFormValidator =
  generateValidationErrorCollector<SignUpSelectPaymentFormValues>({
    paymentType: [isRequired],
  });

export const SignUpPaymentPage = () => {
  const { signUpClickNextStepHandler, trackEvent } = useSignUpFlow();
  const [activePaymentForm, setActivePaymentForm] =
    useState<ActivePaymentFormType | null>(null);
  const { autopay } = useSelector<SignUpStoreState, { autopay: boolean }>(
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ({ signUp: { autopay } }) => ({ autopay })
  );
  const prospectId = useSelector(selectProspectId);

  const { translate } = useTranslations();
  const {
    tSignUpPaymentPageBankAccount,
    tSignUpPaymentPageCreditOrDebit,
    tSignUpPaymentPagePreferredPaymentMethodAutopayFalse,
    tSignUpPaymentPageSkipPaymentInfo,
    tSignUpPaymentPageSubmitCta,
  } = translate(signUpPaymentPageTranslations);

  const { featureFlagClient } = useFeatureFlagClient();
  const { portalZuoraAchDigitalEnrollment } = featureFlagClient.getFlags([
    ["portalZuoraAchDigitalEnrollment", false],
  ]);

  if (!prospectId) {
    return null;
  }

  const skipPaymentSteps = () => {
    trackEvent({ action: ActionType.click, label: "noPaymentInfo" });
    signUpClickNextStepHandler({
      nextStep: "summary",
      signUpData: { billingPaymentMethodId: null },
      track: true,
    });
  };

  const handleBackButtonClick = () => {
    setActivePaymentForm(null);
  };

  const onSubmit = ({ paymentType }: SignUpSelectPaymentFormValues) => {
    switch (paymentType) {
      case ActivePaymentFormType.CreditDebit: {
        return setActivePaymentForm(ActivePaymentFormType.CreditDebit);
      }
      case ActivePaymentFormType.ACH: {
        return setActivePaymentForm(ActivePaymentFormType.ACH);
      }
      default:
    }
  };

  const getPaymentMethodForm = () => {
    if (activePaymentForm === null) {
      return null;
    }

    if (
      activePaymentForm === ActivePaymentFormType.CreditDebit ||
      (Boolean(portalZuoraAchDigitalEnrollment.value) &&
        activePaymentForm === ActivePaymentFormType.ACH)
    ) {
      return (
        <AddCardOrBankAccountZuoraForm
          isAddBank={activePaymentForm === ActivePaymentFormType.ACH}
        />
      );
    }
    return null;
  };

  return (
    <SignUpPageLayout
      handleBackButtonClick={
        activePaymentForm ? handleBackButtonClick : undefined
      }
    >
      {!activePaymentForm && (
        <>
          <LoggedOutPageHeader
            headerText={tSignUpPaymentPagePreferredPaymentMethodAutopayFalse}
          />
          <Form<SignUpSelectPaymentFormValues>
            initialValues={{ paymentType: null }}
            onSubmit={onSubmit}
            validate={SignUpSelectPaymentFormValidator}
            render={({ handleSubmit }) => (
              <LoggedOutForm
                onSubmit={handleSubmit}
                submitButtonText={tSignUpPaymentPageSubmitCta}
              >
                <LoggedOutFieldsLayout>
                  <StyledPaymentMethods>
                    <RhIconRadioField
                      value={ActivePaymentFormType.ACH}
                      icon={<BankIcon />}
                      label={tSignUpPaymentPageBankAccount}
                      name="paymentType"
                    />
                    <RhIconRadioField
                      value={ActivePaymentFormType.CreditDebit}
                      icon={<CreditCardsIcon />}
                      label={tSignUpPaymentPageCreditOrDebit}
                      name="paymentType"
                    />
                  </StyledPaymentMethods>
                  {!autopay && (
                    <StyledSkipPaymentContainer>
                      <RhTypography
                        component="a"
                        color="primary"
                        onClick={skipPaymentSteps}
                      >
                        {tSignUpPaymentPageSkipPaymentInfo}
                      </RhTypography>
                    </StyledSkipPaymentContainer>
                  )}
                </LoggedOutFieldsLayout>
              </LoggedOutForm>
            )}
          />
        </>
      )}
      {getPaymentMethodForm()}
    </SignUpPageLayout>
  );
};

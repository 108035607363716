import { formatCurrency } from "@common/utils/dataFormatters";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { AutoPayInfo } from "@portal-account/components/AutoPayInfo/AutoPayInfo";
import { BillInvoices } from "@portal-account/components/BillInvoices/BillInvoices";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { AccountSummary } from "@portal-account/models/AccountSummary.model";
import {
  StyledAmountDue,
  StyledAutoPayInfoContainer,
  StyledBillInvoicesContainer,
  StyledBillSummaryContainer,
  StyledHeader,
} from "@portal-account/pages/PayBillPage/BillSummary/BillSummary.styled";
import { translations } from "@portal-account/pages/PayBillPage/PayBillPage.en.i18n";
import { useInvoiceHistoryQuery } from "@portal-account/queries/useInvoiceHistoryQuery";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import dayjs from "dayjs";
import React from "react";

interface BillSummaryProps {
  accountSummary: AccountSummary | null;
}

export const BillSummary = ({ accountSummary }: BillSummaryProps) => {
  const { premise } = usePremiseFromContext();

  const invoiceHistoryQuery = useInvoiceHistoryQuery({
    premiseId: premise.id,
  });

  const { translate } = useTranslations();
  const {
    tPayBillPageTitlePayBill,
    tPayBillPageAmountDue,
    tPayBillPagePastDue,
  } = translate(translations);

  const invoices = invoiceHistoryQuery.data?.results || [];

  const getInvoiceToPay = () => {
    const today = dayjs().startOf("day");

    return invoices.find((invoice) => {
      const invoiceDueDate = dayjs(invoice.dueDate).startOf("day");

      return invoiceDueDate.isSame(today) || invoiceDueDate.isAfter(today);
    });
  };

  return (
    <StyledBillSummaryContainer>
      <StyledHeader variant="h1">{tPayBillPageTitlePayBill}</StyledHeader>
      <StyledAutoPayInfoContainer>
        <AutoPayInfo
          autopayEnabled={premise.autopay}
          invoiceToPay={getInvoiceToPay()}
          billingPaymentMethodId={premise.billingPaymentMethodId}
        />
      </StyledAutoPayInfoContainer>
      <StyledBillInvoicesContainer>
        <BillInvoices invoices={invoiceHistoryQuery.data?.results || []} />
      </StyledBillInvoicesContainer>
      <StyledAmountDue>
        <RhTypography variant="subtitle1">{tPayBillPageAmountDue}</RhTypography>
        <RhTypography variant="h3">
          {formatCurrency(accountSummary?.totalBalance ?? 0)}
        </RhTypography>
      </StyledAmountDue>
      <StyledAmountDue>
        <RhTypography variant="body1">{tPayBillPagePastDue}</RhTypography>
        <RhTypography variant="body1" color="error">
          {formatCurrency(accountSummary?.pastDueBalance ?? 0)}
        </RhTypography>
      </StyledAmountDue>
    </StyledBillSummaryContainer>
  );
};

import { IdType } from "@common/types/apiTypes";
import { premiseApi } from "@portal-account/api/premiseApi";
import { premiseQueryKeys } from "@portal-account/queryKeys/premiseQueryKeys";
import { useQuery } from "@tanstack/react-query";

interface UsePortalAttachedDevicesProps {
  premiseId: IdType;
  queryOptions?: {
    enabled?: boolean;
  };
}

export const usePremiseAttachedDevicesQuery = (
  props: UsePortalAttachedDevicesProps
) => {
  const { premiseId, queryOptions: { enabled = true } = {} } = props;

  const query = useQuery({
    enabled,
    queryFn: () => premiseApi.attachedDevices(premiseId),
    queryKey: premiseQueryKeys.attachedDevices(premiseId),
  });

  return query;
};

import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { useRhAnnouncement } from "@design-system/components/RhAnnouncement/useRhAnnouncement";
import { SignInForm } from "@portal-account/components/SignInForm/SignInForm";
import { SignInGoToGuestPay } from "@portal-account/components/SignInGoToGuestPay/SignInGoToGuestPay";
import { SignInPageTranslations } from "@portal-account/pages/SignInPage/SignInPage.en.i18n";
import {
  SignInOptions,
  Skyline,
  StyledColumn,
  StyledForgotPasswordLink,
  StyledPageContainer,
  StyledTitle,
} from "@portal-account/pages/SignInPage/SignInPage.styled";
import { SignInWithGoogleButton } from "@portal-account/pages/SignInPage/SignInWithGoogleButton";
import { forgotPasswordPath } from "@portal-account/routes/routePaths";
import { IRONHIDE_SIGN_IN_URL } from "@portal-enroll/constants/urls.constant";
import { googleAnalyticsDataAtom } from "@portal-shared/components/GoogleAnalyticsTracking/GoogleAnalyticsTracking.atoms";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { useSetAtom } from "jotai";
import { RESET } from "jotai/utils";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

export const SignInPage = () => {
  const { translate, translateJsx } = useTranslations();
  const setGoogleAnalyticsDataAtom = useSetAtom(googleAnalyticsDataAtom);

  useEffect(() => {
    setGoogleAnalyticsDataAtom(RESET);
  }, [setGoogleAnalyticsDataAtom]);

  const {
    tSignInPageForgotPassword,
    tSignInPageHtmlDescription,
    tSignInPageHtmlTitle,
    tSignInPageLogIn,
  } = translate(SignInPageTranslations);

  const { tSignInPageAreYouAnEntrustCustomer } = translateJsx({
    tSignInPageAreYouAnEntrustCustomer: {
      link: (message: string) => (
        <a href={IRONHIDE_SIGN_IN_URL} rel="noreferrer">
          {message}
        </a>
      ),
    },
  });

  const { announceNotice, clearAnnouncement } = useRhAnnouncement();

  const { featureFlagClient } = useFeatureFlagClient();
  const { portalGoogleAuth } = featureFlagClient.getFlags([
    ["portalGoogleAuth", false],
  ]);

  useEffect(() => {
    announceNotice(<div>{tSignInPageAreYouAnEntrustCustomer}</div>);
    return clearAnnouncement;
  }, []);

  return (
    <>
      <Helmet>
        <title>{tSignInPageHtmlTitle}</title>
        <meta name="description" content={tSignInPageHtmlDescription} />
        <meta name="robots" content="index" />
      </Helmet>
      <StyledPageContainer>
        <StyledColumn>
          <SignInOptions>
            <StyledTitle variant="h1">{tSignInPageLogIn}</StyledTitle>

            <SignInForm />

            {portalGoogleAuth.value ? <SignInWithGoogleButton /> : null}
            <StyledForgotPasswordLink to={forgotPasswordPath()}>
              {tSignInPageForgotPassword}
            </StyledForgotPasswordLink>
          </SignInOptions>
        </StyledColumn>
        <StyledColumn>
          <SignInGoToGuestPay />
        </StyledColumn>
      </StyledPageContainer>
      <Skyline />
    </>
  );
};

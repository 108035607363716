import { ReactComponent as InformationIcon } from "@design-system/icons/InformationIcon.svg";
import {
  StyledNoContractsCardContent,
  StyledNoContractsCardContentMessage,
} from "@portal-account/components/MyAccountProductAddOnsCard/NoContractsCardContent.styled";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import React from "react";

export const NoContractsCardContent = () => {
  const { t } = useRhIntl();

  return (
    <StyledNoContractsCardContent>
      <InformationIcon
        height={16}
        width={16}
        fill="black"
        style={{
          marginRight: "1.5ch",
        }}
      />
      <StyledNoContractsCardContentMessage>
        {t("MyAccount.ProductAddOns.NoContractsCardContent.message")}
      </StyledNoContractsCardContentMessage>
    </StyledNoContractsCardContent>
  );
};

import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { PaymentMethodsSection } from "@portal-account/components/PaymentMethodsSection/PaymentMethodsSection";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import {
  PortalCard,
  PortalCardHeader,
  PortalCardTitle,
} from "@portal-shared/components/PortalCard/PortalCard.styled";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import React from "react";

export const MyAccountPaymentMethodsCard = () => {
  const { t } = useRhIntl();
  const { premise } = usePremiseFromContext();

  const title = t("MyAccountPaymentMethodsCard.title");

  return (
    <PortalCard>
      <PortalCardHeader>
        <PortalCardTitle>{title}</PortalCardTitle>
      </PortalCardHeader>
      {premise.isNoCheckOrAch && premise.isNoCreditOrDebitCard ? (
        <RhTypography variant="body1">
          {t("MyAccountPaymentMethodsCard.unableToCollect")}
        </RhTypography>
      ) : (
        <PaymentMethodsSection />
      )}
    </PortalCard>
  );
};

import { useCustomerFromContext } from "@portal-account/components/CustomerProvider/useCustomerFromContext";
import { selectedDevicesWithPremiseAtom } from "@portal-account/components/DevicePremiseSelector/DevicePremiseSelector.atoms";
import { DevicesEnrollPageLayout } from "@portal-account/components/DevicesEnrollPageLayout/DevicesEnrollPageLayout";
import { DevicesEnrollSummary } from "@portal-account/components/DevicesEnrollSummary/DevicesEnrollSummary";
import { devicesEnrollStepPath } from "@portal-account/routes/routePaths";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { useAtomValue } from "jotai";
import React from "react";
import { Navigate } from "react-router-dom";

export const DevicesEnrollSummaryPage = () => {
  const customer = useCustomerFromContext();
  const selectedDevicesWithPremise = useAtomValue(
    selectedDevicesWithPremiseAtom
  );
  const { translateJsx } = useTranslations();

  if (!selectedDevicesWithPremise.length) {
    return <Navigate to={devicesEnrollStepPath("choose-your-device-brand")} />;
  }

  const { tDevicesEnrollSummaryPageTitle } = translateJsx({
    tDevicesEnrollSummaryPageTitle: {
      name: customer?.firstName,
    },
  });

  return (
    <DevicesEnrollPageLayout
      step="summary"
      title={tDevicesEnrollSummaryPageTitle}
    >
      <DevicesEnrollSummary />
    </DevicesEnrollPageLayout>
  );
};

import { PremiseStatus } from "@common/enums/premise.enum";
import { RhLinkButton } from "@design-system/components/RhLinkButton/RhLinkButton";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import { useCustomerFromContext } from "@portal-account/components/CustomerProvider/useCustomerFromContext";
import { DeviceConnectionActivityCard } from "@portal-account/components/DeviceConnectionActivityCard/DeviceConnectionActivityCard";
import { DeviceDemandResponseActivityCard } from "@portal-account/components/DeviceDemandResponseActivityCard/DeviceDemandResponseActivityCard";
import { DeviceFAQsCard } from "@portal-account/components/DeviceFAQsCard/DeviceFAQsCard";
import { DeviceInformationCard } from "@portal-account/components/DeviceInformationCard/DeviceInformationCard";
import { selectedDevicePremiseAtom } from "@portal-account/components/DevicePremiseSelector/DevicePremiseSelector.atoms";
import { DeviceSelector } from "@portal-account/components/DeviceSelector/DeviceSelector";
import { devicesSelectorAtom } from "@portal-account/components/DeviceSelector/DeviceSelector.atoms";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { devicesPageTranslations } from "@portal-account/pages/DevicesPage/DevicesPage.en.i18n";
import {
  StyledAddNewContainer,
  StyledContainer,
  StyledDeviceSelectorWrapper,
  StyledPageHeader,
  StyledPageTitle,
} from "@portal-account/pages/DevicesPage/DevicesPage.styled";
import { NoAttachedDevices } from "@portal-account/pages/DevicesPage/NoAttachedDevices";
import { useDeviceEligibilityInformationQuery } from "@portal-account/queries/useDeviceEligibilityInformationQuery";
import { usePremiseAttachedDevicesQuery } from "@portal-account/queries/usePremiseAttachedDevicesQuery";
import { devicesEnrollStepPath } from "@portal-account/routes/routePaths";
import { Skeleton } from "@portal-shared/components/Skeleton/Skeleton";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { Error500Page } from "@portal-shared/pages/Error500Page/Error500Page";
import { useAtom, useSetAtom } from "jotai";
import React, { useEffect } from "react";
import { useMediaQuery } from "usehooks-ts";

export const DevicesPage = () => {
  const isMobile = useMediaQuery(`(max-width: ${RhythmBreakpoints.XS}px)`);
  const { premise } = usePremiseFromContext();
  const customer = useCustomerFromContext();
  const { translate } = useTranslations();
  const [selectedDeviceToShow, setSelectedDeviceToShow] =
    useAtom(devicesSelectorAtom);
  const setSelectedDevicePremise = useSetAtom(selectedDevicePremiseAtom);
  const {
    data: premiseAttachedDevices,
    error,
    isPending,
  } = usePremiseAttachedDevicesQuery({
    premiseId: premise.id,
  });
  const { data: deviceEligibilityInformationData, isPending: dataIsPending } =
    useDeviceEligibilityInformationQuery({
      customerId: customer.id,
    });

  const {
    tDevicesPageAddNewButton,
    tDevicesPageTitlePlural,
    tDevicesPageTitleSingular,
  } = translate(devicesPageTranslations);

  useEffect(() => {
    if (premiseAttachedDevices && !selectedDeviceToShow) {
      setSelectedDeviceToShow(premiseAttachedDevices.thermostats[0]);
      setSelectedDevicePremise({
        premiseId: premise.id,
      });
    }
  }, [
    premiseAttachedDevices,
    selectedDeviceToShow,
    setSelectedDeviceToShow,
    setSelectedDevicePremise,
    premise.id,
  ]);

  if (dataIsPending || isPending) {
    return (
      <StyledContainer>
        <Skeleton $height="40px" $width="100%" />
        <Skeleton $height="200px" $width="100%" />
        <Skeleton $height="200px" $width="100%" />
      </StyledContainer>
    );
  }

  if (error) {
    return <Error500Page />;
  }

  const attachedDevicesCount = premiseAttachedDevices
    ? premiseAttachedDevices.thermostats.length
    : 0;

  const activeOrPendingPremisesOnly = customer.premises
    .filter(
      (customerPremise) => customerPremise.status !== PremiseStatus.INACTIVE
    )
    .filter((customerPremise) => customerPremise.id !== premise.id);

  if (!selectedDeviceToShow || attachedDevicesCount === 0) {
    return (
      <NoAttachedDevices
        credit={premise.formattedThermostatManagementMonthlyDiscount}
      />
    );
  }

  return (
    <StyledContainer>
      <StyledPageHeader>
        <StyledPageTitle variant="h1">
          {attachedDevicesCount > 1
            ? tDevicesPageTitlePlural
            : tDevicesPageTitleSingular}
        </StyledPageTitle>
        {isMobile ? (
          <StyledAddNewContainer>
            <RhLinkButton
              data-tracking-click={{
                event:
                  "Customer going to choose your thermostat device brand page",
              }}
              label={tDevicesPageAddNewButton}
              variant="outlined"
              href={devicesEnrollStepPath("choose-your-device-brand")}
              size="small"
              fullWidth={false}
            >
              {tDevicesPageAddNewButton}
            </RhLinkButton>
          </StyledAddNewContainer>
        ) : null}
      </StyledPageHeader>
      <StyledDeviceSelectorWrapper>
        <DeviceSelector devices={premiseAttachedDevices.thermostats} />
        {!isMobile ? (
          <StyledAddNewContainer>
            <RhLinkButton
              data-tracking-click={{
                event:
                  "Customer going to choose your thermostat device brand page",
              }}
              label={tDevicesPageAddNewButton}
              variant="outlined"
              href={devicesEnrollStepPath("choose-your-device-brand")}
              size="medium"
              fullWidth={false}
            >
              {tDevicesPageAddNewButton}
            </RhLinkButton>
          </StyledAddNewContainer>
        ) : null}
      </StyledDeviceSelectorWrapper>
      <DeviceInformationCard
        device={selectedDeviceToShow}
        credit={premise.formattedThermostatManagementMonthlyDiscount}
        premiseCount={activeOrPendingPremisesOnly.length}
      />
      <DeviceConnectionActivityCard
        deviceId={selectedDeviceToShow.smartThermostatId}
        deviceEligibilityHours={
          deviceEligibilityInformationData?.maxThermostatOfflinePeriodHours
        }
        premiseId={premise.id}
      />
      <DeviceDemandResponseActivityCard
        deviceEligibilityEvents={
          deviceEligibilityInformationData?.maxThermostatOptOutEventsAllowed
        }
        deviceId={selectedDeviceToShow.smartThermostatId}
        premiseId={premise.id}
      />
      <DeviceFAQsCard />
    </StyledContainer>
  );
};

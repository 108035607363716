import { deviceDemandResponseActivityTableTranslations } from "@portal-account/components/DeviceDemandResponseActivityTable/DeviceDemandResponseActivityTable.en.i18n";
import { DeviceDemandResponseActivityEventStatusTypes } from "@portal-account/types/devicesTypes";
import { useTranslations } from "@portal-shared/hooks/useTranslations";

export const useDeviceDemandResponseActivityEvent = () => {
  const { translate } = useTranslations();

  const {
    tDeviceDemandResponseActivityTableApproved,
    tDeviceDemandResponseActivityTableCancelled,
    tDeviceDemandResponseActivityTableDraft,
    tDeviceDemandResponseActivityTableFinished,
    tDeviceDemandResponseActivityTableOptedOut,
    tDeviceDemandResponseActivityTablePhaseChanged,
    tDeviceDemandResponseActivityTableReady,
    tDeviceDemandResponseActivityTableScheduled,
    tDeviceDemandResponseActivityTableSent,
    tDeviceDemandResponseActivityTableStarted,
  } = translate(deviceDemandResponseActivityTableTranslations);

  return {
    [DeviceDemandResponseActivityEventStatusTypes.Approved]:
      tDeviceDemandResponseActivityTableApproved,
    [DeviceDemandResponseActivityEventStatusTypes.Cancelled]:
      tDeviceDemandResponseActivityTableCancelled,
    [DeviceDemandResponseActivityEventStatusTypes.Draft]:
      tDeviceDemandResponseActivityTableDraft,
    [DeviceDemandResponseActivityEventStatusTypes.OptedOut]:
      tDeviceDemandResponseActivityTableOptedOut,
    [DeviceDemandResponseActivityEventStatusTypes.Ready]:
      tDeviceDemandResponseActivityTableReady,
    [DeviceDemandResponseActivityEventStatusTypes.Finished]:
      tDeviceDemandResponseActivityTableFinished,
    [DeviceDemandResponseActivityEventStatusTypes.PhaseChanged]:
      tDeviceDemandResponseActivityTablePhaseChanged,
    [DeviceDemandResponseActivityEventStatusTypes.Scheduled]:
      tDeviceDemandResponseActivityTableScheduled,
    [DeviceDemandResponseActivityEventStatusTypes.Sent]:
      tDeviceDemandResponseActivityTableSent,
    [DeviceDemandResponseActivityEventStatusTypes.Started]:
      tDeviceDemandResponseActivityTableStarted,
  };
};

/**
 * Returns an array of modals ordered by priority.
 * If multiple modals need to be shown, the modal with the highest priority
 * (earlier in the array) will be shown first.
 * Once the highest-priority modal is closed, the next modal in the sequence
 * will be displayed.
 */
export const getSharedModalsByPriority = () => {
  return ["offerSnapshotLearnMore", "offerSnapshotThermostatRequired"] as const;
};

import { RhCircularProgress } from "@design-system/components/RhCircularProgress/RhCircularProgress";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { CustomerRewardsSection } from "@portal-account/components/CustomerRewardsSection/CustomerRewardsSection";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { ReferAFriendSection } from "@portal-account/components/ReferAFriendSection/ReferAFriendSection";
import { RewardsHistoryTable } from "@portal-account/components/RewardsHistoryTable/RewardsHistoryTable";
import { rewardsPageTranslations } from "@portal-account/pages/RewardsPage/RewardsPage.en.i18n";
import {
  StyledDescriptionTextContainer,
  StyledRewardsPageContainer,
  StyledRewardsPageContainerInner,
} from "@portal-account/pages/RewardsPage/RewardsPage.styled";
import { PortalPageLayout } from "@portal-shared/components/PortalPageLayout/PortalPageLayout";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { usePortalOfferSnapshotQuery } from "@portal-shared/queries/usePortalOfferSnapshotQuery";
import React from "react";
import { Helmet } from "react-helmet-async";

export const RewardsPage = () => {
  const { translate } = useTranslations();

  const {
    tRewardsPageDescription,
    tRewardsPageRewards,
    tRewardsPageTitle,
    tRewardsPageDigitalDiscountDescription,
    tRewardsPageError,
  } = translate(rewardsPageTranslations);

  const { premise } = usePremiseFromContext();

  const offerSnapshotQuery = usePortalOfferSnapshotQuery({
    offerSnapshotUuid: premise.currentOrder?.offerSnapshotUuid ?? "",
    queryOptions: {
      enabled: Boolean(premise.currentOrder?.offerSnapshotUuid),
    },
  });

  if (offerSnapshotQuery.isPending) {
    return (
      <>
        <Helmet>
          <title>{tRewardsPageTitle}</title>
        </Helmet>
        <PortalPageLayout>
          <StyledRewardsPageContainer>
            <StyledRewardsPageContainerInner>
              <RhTypography variant="h1">{tRewardsPageRewards}</RhTypography>
            </StyledRewardsPageContainerInner>
            <RhCircularProgress />
          </StyledRewardsPageContainer>
        </PortalPageLayout>
      </>
    );
  }

  if (offerSnapshotQuery.isError) {
    return (
      <>
        <Helmet>
          <title>{tRewardsPageTitle}</title>
        </Helmet>
        <PortalPageLayout>
          <StyledRewardsPageContainer>
            <StyledRewardsPageContainerInner>
              <RhTypography variant="h1">{tRewardsPageRewards}</RhTypography>
            </StyledRewardsPageContainerInner>

            <RhTypography>{tRewardsPageError}</RhTypography>
          </StyledRewardsPageContainer>
        </PortalPageLayout>
      </>
    );
  }

  const offerSnapshot = offerSnapshotQuery.data;

  const subHeaderText = offerSnapshot.isAutoPayPaperlessDiscountOffer
    ? tRewardsPageDigitalDiscountDescription
    : tRewardsPageDescription;

  return (
    <>
      <Helmet>
        <title>{tRewardsPageTitle}</title>
      </Helmet>
      <PortalPageLayout>
        <StyledRewardsPageContainer>
          <StyledRewardsPageContainerInner>
            <RhTypography variant="h1">{tRewardsPageRewards}</RhTypography>
            <StyledDescriptionTextContainer>
              <RhTypography variant="subtitle1" color="textSecondary">
                {subHeaderText}
              </RhTypography>
            </StyledDescriptionTextContainer>
          </StyledRewardsPageContainerInner>
          <CustomerRewardsSection />
          <RewardsHistoryTable />
          <ReferAFriendSection />
        </StyledRewardsPageContainer>
      </PortalPageLayout>
    </>
  );
};

import { RhApiError } from "@common/types/errorTypes";
import { formatCurrency, formatKwh } from "@common/utils/dataFormatters";
import { noop } from "@common/utils/genericFunctions";
import { invoiceFileDownload } from "@common/utils/invoiceFileDownload";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import {
  StyledInvoicesRowElement,
  StyledLinkRow,
} from "@portal-account/components/BillingHistoryTable/BillingHistoryTable.styled";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { useTrackMyAccountEvents } from "@portal-account/hooks/useTrackMyAccountEvents";
import { Invoice } from "@portal-account/models/Invoice.model";
import { MyAccountEvents } from "@portal-account/services/segment.service";
import { useRhIntl } from "@portal-shared/hooks/useRhIntl";
import React from "react";

interface BillingHistoryTableProps {
  invoices: Invoice[];
}

export const BillingHistoryTable = ({ invoices }: BillingHistoryTableProps) => {
  const { premise } = usePremiseFromContext();
  const { t } = useRhIntl();
  const flash = useRhFlash();
  const track = useTrackMyAccountEvents();

  const handleDownload = (invoice: Invoice) => {
    track({
      event: MyAccountEvents.downloadBill,
      label: "Billing history download bill",
    });

    invoiceFileDownload({
      billingAccountNumber: premise.billingAccountNumber,
      invoiceDate: invoice.invoiceDate,
      invoiceId: invoice.id,
      premiseId: premise.id,
    })
      .then(noop)
      .catch((_error: RhApiError) => {
        flash.error(t("BillingHistory.errorDownloadingInvoice"));
      });
  };

  return (
    <>
      {invoices.map((invoice) => (
        <StyledLinkRow
          href="#"
          rel="noopener noreferrer"
          onClick={() => handleDownload(invoice)}
          target="_self"
          key={invoice.id}
        >
          <StyledInvoicesRowElement variant="body1">
            {invoice.formattedInvoiceDate}
          </StyledInvoicesRowElement>
          <StyledInvoicesRowElement variant="body1" color="textPrimary">
            {formatCurrency(invoice.amount)}
          </StyledInvoicesRowElement>
          {invoice.totalKwh && (
            <StyledInvoicesRowElement variant="body1" color="textSecondary">
              {formatKwh(Number(invoice.totalKwh), 0)}
            </StyledInvoicesRowElement>
          )}
        </StyledLinkRow>
      ))}
    </>
  );
};

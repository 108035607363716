import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import styled from "styled-components";

export const LoadingOverlayContainer = styled.div`
  background: ${CSSVariables.COLOR_WHITE};
  display: flex;
  height: 100vh;
  justify-content: center;
  padding-top: 20vh;
  position: fixed;
  top: 0;
  width: 100vw;
  z-index: 1000;
`;

export const LoadingOverlayWrapper = styled.div`
  height: 120px;
  width: 151px;
`;

import { StyledStepperIconContainer } from "@design-system/components/RhStepper/RhStepperIcon.styled";
import { StepIconProps } from "@mui/material";
import { devicesEnrollStepperTranslations } from "@portal-account/components/DevicesEnrollStepper/DevicesEnrollStepper.en.i18n";
import { devicesEnrollStepperIconMapping } from "@portal-account/components/DevicesEnrollStepper/DevicesEnrollStepperIconMapping";
import { useDevicesEnrollStepperSteps } from "@portal-account/components/DevicesEnrollStepper/useDevicesEnrollStepperSteps";
import { DevicesEnrollStepType } from "@portal-account/routes/routePaths";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

export const DevicesEnrollStepperIcon = ({
  completed,
  icon,
  active,
}: StepIconProps) => {
  const { translate } = useTranslations();
  const devicesEnrollStepperKeysInOrder = useDevicesEnrollStepperSteps();

  const { tDevicesEnrollStepperCompleted, tDevicesEnrollStepperThisStep } =
    translate(devicesEnrollStepperTranslations);

  // MUI recommends co-opting the icon prop if you need to lookup a custom SVG
  // that is: pass the icon NAME, then use that to look up your actual icon
  const iconName = icon as DevicesEnrollStepType;
  const stepText =
    devicesEnrollStepperKeysInOrder.find((key) => key.id === iconName)?.label ??
    "";

  const completedIconTitle = `${stepText} ${tDevicesEnrollStepperCompleted}`;
  const activeNumberTitle = `${tDevicesEnrollStepperThisStep}: ${stepText}`;
  const inactiveNumberTitle = `Step: ${stepText}`;

  const NumberIcon = devicesEnrollStepperIconMapping[iconName];
  const CompletedIcon = devicesEnrollStepperIconMapping.completed;

  let title;

  if (completed) {
    title = completedIconTitle;
  } else {
    title = active ? activeNumberTitle : inactiveNumberTitle;
  }

  return (
    <StyledStepperIconContainer $active={active} $completed={completed}>
      {completed ? (
        <CompletedIcon title={title} />
      ) : (
        <NumberIcon title={title} />
      )}
    </StyledStepperIconContainer>
  );
};

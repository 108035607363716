import { Security } from "@okta/okta-react";
import { portalAuthClient } from "@portal-shared/services/PortalAuthClient.service";
import React, { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";

interface SessionManagerProps {}

export const SessionManager = (
  props: PropsWithChildren<SessionManagerProps>
) => {
  const { children } = props;
  const navigate = useNavigate();
  const restoreOriginalUri = portalAuthClient.getRestoreOriginalUri(navigate);

  return (
    <Security
      oktaAuth={portalAuthClient.authClient}
      restoreOriginalUri={restoreOriginalUri}
    >
      {children}
    </Security>
  );
};

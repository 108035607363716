import { IdType } from "@common/types/apiTypes";
import { premiseApi } from "@portal-account/api/premiseApi";
import { BillingStatus } from "@portal-account/models/BillingStatus.model";
import { premiseQueryKeys } from "@portal-account/queryKeys/premiseQueryKeys";
import { useQuery } from "@tanstack/react-query";

interface UseBillingStatusProps {
  premiseId: IdType;
}

export const useBillingStatusQuery = (props: UseBillingStatusProps) => {
  const { premiseId } = props;

  return useQuery({
    queryFn: () => premiseApi.billingStatus(premiseId),
    queryKey: premiseQueryKeys.billingStatus(premiseId),
    select: (data) => new BillingStatus(data),
  });
};

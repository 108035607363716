import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { billEnergyUsageTemperatureCardTranslations } from "@portal-account/components/BillEnergyUsageTemperatureCard/BillEnergyUsageTemperatureCard.en.i18n";
import {
  StyledBillEnergyContainer,
  StyledBillEnergyDivider,
  StyledBillEnergyHeaderContainer,
} from "@portal-account/components/BillEnergyUsageTemperatureCard/BillEnergyUsageTemperatureCard.styled";
import { BillEnergyUsageTemperatureCardBody } from "@portal-account/components/BillEnergyUsageTemperatureCard/BillEnergyUsageTemperatureCardBody";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

interface BillEnergyUsageTemperatureCardProps {
  invoiceId: string;
}

export const BillEnergyUsageTemperatureCard = (
  props: BillEnergyUsageTemperatureCardProps
) => {
  const { invoiceId } = props;

  const { translate } = useTranslations();
  const {
    tBillEnergyUsageTemperatureCardEnergyUsage,
    tBillEnergyUsageTemperatureCardHeresHowMother,
  } = translate(billEnergyUsageTemperatureCardTranslations);

  return (
    <StyledBillEnergyContainer>
      <StyledBillEnergyHeaderContainer>
        <RhTypography variant="subtitle2" fontWeight="Bold">
          {tBillEnergyUsageTemperatureCardEnergyUsage}
        </RhTypography>
        <RhTypography>
          {tBillEnergyUsageTemperatureCardHeresHowMother}
        </RhTypography>
        <StyledBillEnergyDivider />
      </StyledBillEnergyHeaderContainer>
      <BillEnergyUsageTemperatureCardBody invoiceId={invoiceId} />
    </StyledBillEnergyContainer>
  );
};

import { SessionStorageItem } from "@common/utils/sessionStorage";
import { DevicesEnrollChooseBrandForm } from "@portal-account/components/DevicesEnrollChooseBrandForm/DevicesEnrollChooseBrandForm";
import { DevicesEnrollPageLayout } from "@portal-account/components/DevicesEnrollPageLayout/DevicesEnrollPageLayout";
import { RESIDEO_SESSION_STORAGE_KEY } from "@portal-account/components/DevicesEnrollSelectDevicesForm/constants";
import { devicesEnrollChooseBrandPageTranslations } from "@portal-account/pages/DevicesEnrollChooseBrandPage/DevicesEnrollChooseBrandPage.en.i18n";
import { ResideoDeviceListStorageType } from "@portal-account/types/devicesTypes";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React, { useEffect } from "react";
import { useSessionStorage } from "usehooks-ts";

export const DevicesEnrollChooseBrandPage = () => {
  const { translate } = useTranslations();

  const [visitedThermostatEnroll, setVisitedThermostatEnroll] =
    useSessionStorage(
      SessionStorageItem.VisitedThermostatEnrollmentPage,
      false
    );

  const [
    resideoDeviceListFromStorage,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    setResideoDeviceListFromStorage,
    removeResideoDeviceListFromStorage,
  ] = useSessionStorage<ResideoDeviceListStorageType | null>(
    RESIDEO_SESSION_STORAGE_KEY,
    null
  );

  useEffect(() => {
    if (!visitedThermostatEnroll) {
      setVisitedThermostatEnroll(true);
    }
  }, [visitedThermostatEnroll, setVisitedThermostatEnroll]);

  useEffect(() => {
    if (resideoDeviceListFromStorage) {
      removeResideoDeviceListFromStorage();
    }
  }, [resideoDeviceListFromStorage, removeResideoDeviceListFromStorage]);

  const {
    tDevicesEnrollChooseBrandPageDescription,
    tDevicesEnrollChooseBrandPageTitle,
  } = translate(devicesEnrollChooseBrandPageTranslations);

  return (
    <DevicesEnrollPageLayout
      description={tDevicesEnrollChooseBrandPageDescription}
      step="choose-your-device-brand"
      title={tDevicesEnrollChooseBrandPageTitle}
    >
      <DevicesEnrollChooseBrandForm />
    </DevicesEnrollPageLayout>
  );
};

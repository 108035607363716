import { useOktaAuth } from "@okta/okta-react";
import { customerHomePath, rootPath } from "@portal-shared/routes/routePaths";
import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

interface SessionCheckProps {
  secured: boolean;
}

export const SessionCheck = (props: PropsWithChildren<SessionCheckProps>) => {
  const { children, secured } = props;
  const { authState, oktaAuth } = useOktaAuth();

  if (!authState) {
    // Wait for authState to be initialized
    // before deciding if we should render a redirect
    // or the component
    return null;
  }

  if (secured && !authState.isAuthenticated) {
    const originalUri = window.location.href.replace(
      window.location.origin,
      ""
    );

    oktaAuth.setOriginalUri(originalUri);

    return <Navigate to={rootPath()} />;
  }

  if (!secured && authState.isAuthenticated) {
    return <Navigate to={customerHomePath()} />;
  }

  return <>{children}</>;
};

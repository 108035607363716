import {
  DynamicLink,
  DynamicLinkProps,
} from "@common/components/DynamicLink/DynamicLink";
import {
  RhButton,
  RhButtonProps,
} from "@design-system/components/RhButton/RhButton";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { PaperProps } from "@mui/material";
import {
  StyledAtAGlanceItemContainer,
  StyledTitle,
} from "@portal-account/components/AtAGlanceCarousel/AtAGlanceItem.styled";
import React, { FC, PropsWithChildren, RefAttributes } from "react";

interface AtAGlanceItemProps {
  containerProps?: Omit<PaperProps, "elevation">;
  cta: {
    external?: boolean;
    linkTo: DynamicLinkProps["to"];
    onClick?: () => void;
    text: string;
    useButton?: boolean;
  };
  disableBorder?: boolean;
  disabled?: boolean;
  disabledTitle?: string;
  title: string;
}

const StyledRhButton = React.forwardRef((props: RhButtonProps, ref) => {
  return (
    <RhButton
      size="small"
      variant="outlined"
      color="secondary"
      style={{ marginTop: "0.5em", padding: "0.5em 1em" }}
      {...props}
      data-tracking-click={{
        event: "Customer clicked at a glance item",
      }}
    />
  );
});

const StyledRhTypography = React.forwardRef((props) => (
  <RhTypography variant="body2" color="secondary" component="a" {...props} />
));

export const AtAGlanceItem: FC<
  PropsWithChildren<AtAGlanceItemProps & RefAttributes<unknown>>
> = React.forwardRef((props) => {
  const {
    title,
    cta,
    disableBorder = false,
    disabledTitle,
    disabled = false,
    containerProps,
  } = props;
  const displayTitle = disabled ? disabledTitle : title;

  return (
    <StyledAtAGlanceItemContainer
      $disabled={disabled}
      $disableBorder={disableBorder}
      {...containerProps}
    >
      <StyledTitle variant="body1" color="textPrimary" $disabled={disabled}>
        {displayTitle}
      </StyledTitle>
      {!disabled && (
        <DynamicLink
          onClick={cta.onClick}
          to={cta.linkTo}
          external={cta.external ?? false}
          component={cta.useButton ? StyledRhButton : StyledRhTypography}
        >
          {cta.text}
        </DynamicLink>
      )}
    </StyledAtAGlanceItemContainer>
  );
});

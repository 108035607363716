import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { TEN_OR_ELEVEN_PHONE_DIGIT_NUMBER_REGARDLESS_OF_SYMBOLS } from "@common/constants/regex.constant";
import { formatPhoneNumber } from "@common/utils/dataFormatters";
import { useEnrollCookies } from "@enroll-utils/hooks/useEnrollCookies";
import { useAcquisitionCampaignQuery } from "@portal-enroll/queries/useAcquisitionCampaignQuery";
import { selectSignUpState } from "@portal-enroll/selectors/signUpSelectors";
import { setAcquisitionTfn } from "@portal-enroll/slices/signUpSlice";
import { brandInformation } from "@portal-shared/services/brandInformation.service";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

export const useAcquisitionCampaignFromQueryParam = () => {
  const { featureFlagClient } = useFeatureFlagClient();

  const {
    cookies: { rhCampaignTfn },
  } = useEnrollCookies();
  const { portalAcquisitionSlugTfn } = featureFlagClient.getFlags([
    ["portalAcquisitionSlugTfn", false],
  ]);

  const { acquisition } = useSelector(selectSignUpState);
  const queryEnabled =
    Boolean(portalAcquisitionSlugTfn.value) &&
    Boolean(acquisition.acquisitionSource);

  const acquisitionCampaignQuery = useAcquisitionCampaignQuery({
    enabled: queryEnabled,
    slug: acquisition.acquisitionSource,
  });
  const dispatch = useDispatch();

  const cookiePhoneNumber =
    TEN_OR_ELEVEN_PHONE_DIGIT_NUMBER_REGARDLESS_OF_SYMBOLS.test(
      rhCampaignTfn || ""
    )
      ? String(rhCampaignTfn)
      : brandInformation.phoneNumbersGeneralSales;

  useEffect(() => {
    let phone = cookiePhoneNumber;

    if (acquisitionCampaignQuery.data) {
      phone = acquisitionCampaignQuery.data.phone;
    }

    const formattedPhoneNumber = formatPhoneNumber(phone);

    dispatch(setAcquisitionTfn(formattedPhoneNumber));
  }, [acquisitionCampaignQuery.data, cookiePhoneNumber, dispatch]);
};

import { featureFlagsApi } from "@common/api/featureFlagsApi";
import {
  FeatureFlagMetric,
  FeatureFlagSet,
  FeatureFlagValues,
  FetchedFeatureFlags,
} from "@common/components/FeatureFlagClientProvider/FeatureFlagClientProvider.types";
import { captureException, withScope } from "@sentry/react";
import { decamelize } from "humps";

const recordFlagNotFound = (flagName: string, kebabFlagName: string) => {
  // eslint-disable-next-line no-console
  console.error(`Unable to find flag ${kebabFlagName}`);

  withScope((scope) => {
    const errorToCapture = new Error("Launchdarkly flag not found");
    const errorData = {
      errorDescription: `Unable to find ${kebabFlagName} flag, represented in React as '${flagName}'`,
    };

    errorToCapture.name = "Invalid Launchdarkly Flag Name";
    scope.setContext("Launchdarkly Flag Error", errorData);
    captureException(errorToCapture);
  });
};

export const checkAllFlagsExistsInLaunchDarkly = (
  fetchedFlags: FeatureFlagSet,
  flagNames: (keyof FeatureFlagValues)[]
) => {
  const flagNamesReturnedFromLaunchDarkly = Object.keys(fetchedFlags);

  flagNames.forEach((flagName: string) => {
    const kebabCaseFlagName = decamelize(flagName, {
      separator: "-",
    });

    if (!flagNamesReturnedFromLaunchDarkly.includes(flagName)) {
      recordFlagNotFound(flagName, kebabCaseFlagName);
    }
  });
};

export const fetchAllFlags = async (
  featureFlagUserId: string,
  custom: Record<string, unknown>,
  setIsFetchingFlags: (isFetching: boolean) => void
): Promise<FetchedFeatureFlags> => {
  setIsFetchingFlags(true);
  try {
    const allFlags = await featureFlagsApi.flags({
      custom,
      key: featureFlagUserId,
    });

    if (allFlags?.flags) {
      return allFlags;
    }

    return { flags: {} };
  } catch {
    return { flags: {} };
  } finally {
    setIsFetchingFlags(false);
  }
};

export const sendTrackMetric = async (
  featureFlagUserId: string,
  metric: FeatureFlagMetric
): Promise<boolean> => {
  try {
    await featureFlagsApi.metric.track(featureFlagUserId, metric);

    return true;
  } catch {
    return false;
  }
};

import { CSSVariables } from "@design-system/brandTheme/GlobalBrandedThemeStyles";
import { ModalWidths } from "@design-system/components/RhModal/RhModal.styled";
import { FontWeight } from "@design-system/enums/fontWeight.enum";
import { rhSpacingPx } from "@design-system/theme/spacing";
import { RhythmBreakpoints } from "@design-system/theme/style.constant";
import styled from "styled-components";

export const PlansComparisonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column nowrap;
  gap: 1.5rem;
  justify-content: center;
`;

export const PlansComparisonTitleContainer = styled.div`
  margin: 0 auto;
  max-width: 60ch;
  padding: 0;
  text-align: center;
`;

export const PLAN_NAME_MAX_WIDTH = "340px";

export const PlanFlex = styled.div`
  align-items: center;
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  justify-content: flex-start;

  @media screen and (min-width: 32rem) {
    gap: 2rem;
  }
`;

export const PlanPriceContainer = styled.div`
  align-items: baseline;
  display: flex;
  flex-direction: row;
  gap: ${rhSpacingPx(0.5)};
`;

export const PlanName = styled.h3`
  font-size: 0.8rem;
  font-weight: ${FontWeight.Regular};
  line-height: 1.2;
  margin: 0;
  max-width: 125px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media screen and (min-width: 36rem) {
    font-size: 1.1rem;
    max-width: ${PLAN_NAME_MAX_WIDTH};
  }
`;

export const PlanPrice = styled.p`
  font-size: 1rem;
  font-weight: ${FontWeight.Bold};
  margin: 0;
`;

export const PlanPricePerKwh = styled.p`
  font-size: 0.6rem;
  margin: 0;

  @media screen and (min-width: 32rem) {
    font-size: 0.8rem;
  }
`;

export const SelectedPlanContainer = styled.div`
  align-items: center;
  border: 2px solid ${CSSVariables.COLOR_SECONDARY_MAIN};
  border-radius: 6px;
  display: flex;
  gap: 1rem;
  padding: 0.5rem 1rem;
  width: 100%;

  > svg {
    display: none;
    filter: unset !important;
    height: auto;
    width: 50px;

    @media screen and (min-width: 24rem) {
      display: block;
      width: 75px;
    }
  }

  & > ${PlanPriceContainer} {
    display: none;
    @media (min-width: ${RhythmBreakpoints.XS}px) {
      display: flex;
    }
  }
`;

export const SelectedPlanContent = styled.div`
  flex: 1;
  & > ${PlanPriceContainer} {
    display: flex;
    @media (min-width: ${RhythmBreakpoints.XS}px) {
      display: none;
    }
  }
`;

export const EFLCell = styled.div`
  display: none;
  justify-content: flex-start;
  padding-right: 2rem;
  width: 3rem;

  svg {
    transform: translateX(5px);
  }

  /* To match us making the planName a link at 600px */
  @media screen and (min-width: 600px) {
    display: flex;
  }

  @media screen and (min-width: ${ModalWidths.md}) {
    padding-right: 0;
  }
`;

export const PriceCell = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  width: 4rem;
`;

export const ProviderLogoCell = styled.div`
  display: block;
  width: 50px;

  svg {
    height: auto;
    width: 100%;
  }

  @media screen and (min-width: 32rem) {
    width: 75px;
  }
`;

export const PlanUpdatedOn = styled.p`
  color: ${CSSVariables.COLOR_GREY_400};
  font-size: 0.65rem;
  margin: 0;

  @media screen and (min-width: 32rem) {
    font-size: 0.85rem;
  }
`;

export const PlanNameWrapper = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-basis: 150px;
  flex-flow: column nowrap;
  gap: 0.1rem;
  justify-content: flex-start;
`;

export const PlanListWrapper = styled.div`
  width: 100%;
`;
export const PlanListHeader = styled(PlanFlex)`
  border-bottom: 1px solid ${CSSVariables.COLOR_GREY_100};
  font-size: 0.7rem;
  padding-bottom: 0.5rem;
  text-align: left;
  width: 100%;

  @media screen and (min-width: 32rem) {
    font-size: 1rem;
  }
`;

export const PlanList = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: auto;
  max-height: 6rem;
  min-height: 6rem;
  overflow-y: auto;
  width: 100%;

  &.plansLoading {
    justify-content: center;

    > * {
      margin: auto;
    }
  }

  @media screen and (min-height: 35rem) {
    max-height: 10rem;
  }

  @media screen and (min-height: 44rem) {
    max-height: 16rem;
  }
`;

export const PlanRow = styled(PlanFlex)`
  border-top: 1px solid ${CSSVariables.COLOR_GREY_100};
  padding: 0.75rem 0;
  width: 100%;

  &:first-child {
    border-top: 0;
  }
`;

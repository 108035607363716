import {
  FormError,
  OKTA_PASSWORD_REQUIREMENTS_ERROR,
  OKTA_REQUEST_ERROR,
} from "@common/types/errorTypes";
import { handleAjaxCall } from "@common/utils/handleAjaxCall";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { PortalPasswordField } from "@portal-account/components/PortalPasswordField/PortalPasswordField";
import { resetPasswordPageTranslations } from "@portal-account/pages/ResetPasswordPage/ResetPasswordPage.en.i18n";
import { StyledResetPasswordPageCaption } from "@portal-account/pages/ResetPasswordPage/ResetPasswordPage.styled";
import { useResetPasswordPageParams } from "@portal-account/pages/ResetPasswordPage/useResetPasswordPageParams";
import { analyticsEnableSendCustomerLoginEvent } from "@portal-account/slices/analyticsSlice";
import { LoggedOutFieldsLayout } from "@portal-shared/components/LoggedOutFieldsLayout/LoggedOutFieldsLayout";
import { LoggedOutForm } from "@portal-shared/components/LoggedOutForm/LoggedOutForm";
import { LoggedOutPageHeader } from "@portal-shared/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { LoggedOutPageLayout } from "@portal-shared/components/LoggedOutPageLayout/LoggedOutPageLayout";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { portalAuthClient } from "@portal-shared/services/PortalAuthClient.service";
import { FORM_ERROR } from "final-form";
import React from "react";
import { Form } from "react-final-form";
import { useDispatch } from "react-redux";

interface ResetPasswordFormValues {
  password: string;
}

export const ResetPasswordPage = () => {
  const { token } = useResetPasswordPageParams();
  const flash = useRhFlash();
  const dispatch = useDispatch();

  const { translate } = useTranslations();

  const {
    tResetPasswordPageErrorResettingPassword,
    tResetPasswordPageInvalidPassword,
    tResetPasswordPageNextCTA,
    tResetPasswordPagePasswordMinimumRequirements,
    tResetPasswordPagePassword,
    tResetPasswordPageTitle,
  } = translate(resetPasswordPageTranslations);

  const resetPassword = async ({
    password,
  }: ResetPasswordFormValues): Promise<FormError | void> => {
    const [error] = await handleAjaxCall<void>(
      portalAuthClient
        .resetPassword({
          password,
          recoveryToken: token as string,
        })
        .then(() => {
          dispatch(analyticsEnableSendCustomerLoginEvent());
        })
    );

    if (error) {
      let errorMessage: string;

      switch (error.data.errorCode) {
        case OKTA_REQUEST_ERROR:
        case OKTA_PASSWORD_REQUIREMENTS_ERROR: {
          errorMessage = tResetPasswordPageInvalidPassword;
          break;
        }
        default: {
          errorMessage = tResetPasswordPageErrorResettingPassword;
          break;
        }
      }

      flash.error(errorMessage);

      return { [FORM_ERROR]: [errorMessage] };
    }
  };

  return (
    <LoggedOutPageLayout>
      <LoggedOutPageHeader headerText={tResetPasswordPageTitle} />
      <Form<ResetPasswordFormValues>
        onSubmit={resetPassword}
        render={({ handleSubmit }) => (
          <LoggedOutForm
            onSubmit={handleSubmit}
            submitButtonText={tResetPasswordPageNextCTA}
          >
            <LoggedOutFieldsLayout dense>
              <PortalPasswordField name="password">
                {tResetPasswordPagePassword}
              </PortalPasswordField>
              <StyledResetPasswordPageCaption variant="caption" align="center">
                {tResetPasswordPagePasswordMinimumRequirements}
              </StyledResetPasswordPageCaption>
            </LoggedOutFieldsLayout>
          </LoggedOutForm>
        )}
      />
    </LoggedOutPageLayout>
  );
};

import { IdType } from "@common/types/apiTypes";
import { DeviceWithPremise } from "@portal-account/types/devicesTypes";
import { atomWithReset } from "jotai/utils";

export const selectedDevicesWithPremiseAtom = atomWithReset<
  DeviceWithPremise[]
>([]);

export const selectedDevicePremiseAtom = atomWithReset<{
  premiseId: IdType | null;
}>({ premiseId: null });

import styled from "styled-components";

// eslint-disable-next-line no-restricted-syntax
export const StyledRhTextButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
`;

import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { EnergyUsageSectionV2 } from "@portal-account/components/EnergyUsageSectionV2/EnergyUsageSectionV2";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import React from "react";

export const EnergyUsageSectionHandler = () => {
  const { premise } = usePremiseFromContext();

  const { featureFlagClient } = useFeatureFlagClient();

  const { portalUsageChart } = featureFlagClient.getFlags([
    ["portalUsageChart", false],
  ]);

  if (
    !premise.currentOrder ||
    !premise.meter ||
    !premise.meter.isSmt ||
    !portalUsageChart.value
  ) {
    return null;
  }

  return <EnergyUsageSectionV2 />;
};

import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { useCustomerFromContext } from "@portal-account/components/CustomerProvider/useCustomerFromContext";
import { LoadingOverlay } from "@portal-account/components/LoadingOverlay/LoadingOverlay";
import { useDeviceEligibilityInformationQuery } from "@portal-account/queries/useDeviceEligibilityInformationQuery";
import { customerHomePath } from "@portal-shared/routes/routePaths";
import React from "react";
import { Navigate, Outlet } from "react-router-dom";

export const DeviceManagementOutlet = () => {
  const customer = useCustomerFromContext();
  const { featureFlagClient } = useFeatureFlagClient();
  const { portalDeviceManagement } = featureFlagClient.getFlags([
    ["portalDeviceManagement", false],
  ]);
  const {
    data: deviceEligibilityInformationData,
    isPending: dataIsPending,
    isError: dataIsError,
  } = useDeviceEligibilityInformationQuery({
    customerId: customer.id,
  });

  if (dataIsPending) {
    return <LoadingOverlay />;
  }

  if (
    portalDeviceManagement.value &&
    deviceEligibilityInformationData?.thermostatEligible &&
    !dataIsError
  ) {
    return <Outlet />;
  }

  return <Navigate to={customerHomePath()} />;
};

import { IdType } from "@common/types/apiTypes";
import { RhApiError } from "@common/types/errorTypes";
import { formatMonthDayYear } from "@common/utils/dataFormatters";
import { noop } from "@common/utils/genericFunctions";
import { useRhFlash } from "@design-system/hooks/useRhFlash";
import { documentHistoryTableTranslations } from "@portal-account/components/DocumentHistoryTable/DocumentHistoryTable.en.i18n";
import {
  StyledWelcomeKitCellText,
  StyledWelcomeKitDescription,
} from "@portal-account/components/DocumentHistoryTable/DocumentHistoryTable.styled";
import { useDocumentTypeNames } from "@portal-account/components/DocumentHistoryTable/useDocumentTypeNames";
import { MyAccountTable } from "@portal-account/components/MyAccountTable/MyAccountTable";
import { StyledDownloadLink } from "@portal-account/components/MyAccountTable/MyAccountTable.styled";
import { useTrackMyAccountEvents } from "@portal-account/hooks/useTrackMyAccountEvents";
import {
  ActionType,
  MyAccountEvents,
} from "@portal-account/services/segment.service";
import {
  DocumentHistoryType,
  DocumentType,
} from "@portal-account/types/documentHistoryTypes";
import { documentFileDownload } from "@portal-account/utils/documentFileDownload";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import { createColumnHelper } from "@tanstack/react-table";
import dayjs from "dayjs";
import React, { useCallback, useMemo } from "react";

interface DocumentHistoryTableProps {
  documentHistory: DocumentHistoryType[];
  premiseAccountNumber: IdType;
}

export const DocumentHistoryTable = (props: DocumentHistoryTableProps) => {
  const { documentHistory, premiseAccountNumber } = props;
  const track = useTrackMyAccountEvents();
  const { translate } = useTranslations();
  const documentTypeNames = useDocumentTypeNames();
  const flash = useRhFlash();
  const {
    tDocumentHistoryTableDocumentType,
    tDocumentHistoryTableGeneratedDate,
    tDocumentHistoryTableDownload,
    tDocumentHistoryTableDownloadError,
    tDocumentHistoryTableWelcomeKitDescription,
  } = translate(documentHistoryTableTranslations);

  const sortedDocuments = useMemo(
    () =>
      [...documentHistory].sort(
        (a, b) =>
          dayjs(b.generatedDate).valueOf() - dayjs(a.generatedDate).valueOf()
      ),
    [documentHistory]
  );

  const handleDocumentDownload = useCallback(
    (document: DocumentHistoryType) => {
      track({
        action: ActionType.click,
        documentDate: document.generatedDate,
        documentType: document.documentType,
        event: MyAccountEvents.downloadDocument,
        label: "Downloaded document",
        value: document.url,
      });

      documentFileDownload({
        documentType: document.documentType.toLowerCase(),
        generatedDate: document.generatedDate,
        premiseAccountNumber,
        url: document.url,
      })
        .then(noop)
        .catch((_error: RhApiError) => {
          flash.error(tDocumentHistoryTableDownloadError);
        });
    },
    [track]
  );

  const columnHelper = createColumnHelper<DocumentHistoryType>();

  const columns = [
    columnHelper.display({
      cell: ({ row }) => (
        <b>{formatMonthDayYear(row.original.generatedDate)}</b>
      ),
      header: tDocumentHistoryTableGeneratedDate,
      id: "generatedDate",
    }),
    columnHelper.display({
      cell: ({ row }) => {
        if (row.original.documentType === DocumentType.WelcomeKit) {
          return (
            <StyledWelcomeKitCellText>
              <span>{documentTypeNames[row.original.documentType]}</span>
              <StyledWelcomeKitDescription>
                {tDocumentHistoryTableWelcomeKitDescription}
              </StyledWelcomeKitDescription>
            </StyledWelcomeKitCellText>
          );
        }

        return documentTypeNames[row.original.documentType];
      },
      header: tDocumentHistoryTableDocumentType,
      id: "documentType",
    }),
    columnHelper.display({
      cell: ({ row }) => (
        <StyledDownloadLink
          onClick={() => handleDocumentDownload(row.original)}
        >
          {tDocumentHistoryTableDownload}
        </StyledDownloadLink>
      ),
      header: "",
      id: "download",
    }),
  ];

  return (
    <MyAccountTable<DocumentHistoryType>
      columns={columns}
      data={sortedDocuments}
      rowId="generatedDate"
    />
  );
};

import { IdType, UUIDType } from "@common/types/apiTypes";
import { premiseProductContractApi } from "@portal-account/api/premiseProductContractApi";
import { productContractQueryKeys } from "@portal-account/queryKeys/productContractQueryKeys";
import { useQuery } from "@tanstack/react-query";

export const useProductContractClaimOptionsQuery = (
  premiseId: IdType,
  productContractId: UUIDType
) => {
  return useQuery({
    queryFn: () =>
      premiseProductContractApi.claims.listOptions({
        premiseId,
        productContractId,
      }),
    queryKey: productContractQueryKeys.claims(premiseId, productContractId),
  });
};

import { FeatureFlagMetric } from "@common/components/FeatureFlagClientProvider/FeatureFlagClientProvider.types";
import { useFeatureFlagClient } from "@common/components/FeatureFlagClientProvider/useFeatureFlagClient";
import { ZuoraAddPaymentResponseType } from "@common/types/apiResponseTypes";
import { useSignUpFlow } from "@portal-enroll/hooks/useSignUpFlow";
import { signUpPaymentPageTranslations } from "@portal-enroll/pages/SignUpPaymentPage/SignUpPaymentPage.en.i18n";
import { StyledZuoraContainer } from "@portal-enroll/pages/SignUpPaymentPage/SignUpPaymentPage.styled";
import { LoggedOutPageHeader } from "@portal-shared/components/LoggedOutPageHeader/LoggedOutPageHeader";
import { LoggedOutZuoraAddBankAccountForm } from "@portal-shared/components/LoggedOutZuoraAddBankAccountForm/LoggedOutZuoraAddBankAccountForm";
import { LoggedOutZuoraCardForm } from "@portal-shared/components/LoggedOutZuoraCardForm/LoggedOutZuoraCardForm";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

interface AddCardOrBankAccountZuoraFormProps {
  isAddBank: boolean;
}

export const AddCardOrBankAccountZuoraForm = ({
  isAddBank,
}: AddCardOrBankAccountZuoraFormProps) => {
  const { translate } = useTranslations();
  const {
    tSignUpPaymentPageCreditCardPlease,
    tSignUpPaymentPageTakeCareOfThisNow,
  } = translate(signUpPaymentPageTranslations);

  const { signUpClickNextStepHandler } = useSignUpFlow();

  const {
    featureFlagClient: { trackMetric },
  } = useFeatureFlagClient();

  const handleZuoraSuccessResponse = (
    response: ZuoraAddPaymentResponseType
  ) => {
    trackMetric(FeatureFlagMetric.PortalProspectAddedPaymentMethod);

    signUpClickNextStepHandler({
      nextStep: "summary",
      signUpData: { billingPaymentMethodId: response.refId },
      track: true,
    });
  };

  return (
    <>
      <LoggedOutPageHeader
        headerText={tSignUpPaymentPageCreditCardPlease}
        subHeaderText={tSignUpPaymentPageTakeCareOfThisNow}
      />
      <StyledZuoraContainer>
        {isAddBank ? (
          <LoggedOutZuoraAddBankAccountForm
            onSuccess={handleZuoraSuccessResponse}
          />
        ) : (
          <LoggedOutZuoraCardForm onSuccess={handleZuoraSuccessResponse} />
        )}
      </StyledZuoraContainer>
    </>
  );
};

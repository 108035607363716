import { BillComparisonChart } from "@common/components/BillComparisonChart/BillComparisonChart";
import { RhTypography } from "@design-system/components/RhTypography/RhTypography";
import { billComparisonCardTranslations } from "@portal-account/components/BillComparisonCard/BillComparisonCard.en.i18n";
import { BillComparisonCardSkeleton } from "@portal-account/components/BillComparisonCard/BillComparisonCard.skeleton";
import {
  StyledBillComparisonCard,
  StyledBillComparisonChartContainer,
  StyledBillComparisonDivider,
  StyledBillComparisonTableContainer,
} from "@portal-account/components/BillComparisonCard/BillComparisonCard.styled";
import { BillComparisonTable } from "@portal-account/components/BillComparisonTable/BillComparisonTable";
import { COMPARISON_TABLE_HEADER_WIDTH_DESKTOP } from "@portal-account/components/BillComparisonTable/BillComparisonTable.styled";
import { useBillBreakdownCompareQuery } from "@portal-account/queries/useBillBreakdownCompareQuery";
import { useTranslations } from "@portal-shared/hooks/useTranslations";
import React from "react";

interface BillComparisonCardProps {
  invoiceId: string;
}

export const BillComparisonCard = (props: BillComparisonCardProps) => {
  const { invoiceId } = props;

  const { data, isPending, isError } = useBillBreakdownCompareQuery({
    invoiceId,
  });

  const { translate } = useTranslations();
  const {
    tBillComparisonCardHeresThisBill,
    tBillComparisonCardSeeExactly,
    tBillComparisonCardMonthlykWhUsage,
    tBillComparisonCardLastMonth,
    tBillComparisonCardLastYear,
    tBillComparisonCardThisMonth,
    tBillComparisonCardError,
  } = translate(billComparisonCardTranslations);

  if (isPending) {
    return <BillComparisonCardSkeleton />;
  }

  if (isError || !data.invoiceCompatible) {
    return (
      <StyledBillComparisonCard>
        <RhTypography>{tBillComparisonCardError}</RhTypography>
      </StyledBillComparisonCard>
    );
  }

  return (
    <StyledBillComparisonCard>
      <RhTypography variant="subtitle2" fontWeight="Bold">
        {tBillComparisonCardHeresThisBill}
      </RhTypography>
      <RhTypography>{tBillComparisonCardSeeExactly}</RhTypography>
      <StyledBillComparisonDivider />
      <StyledBillComparisonChartContainer>
        <BillComparisonChart
          comparisionData={data}
          tableHeaderWidth={COMPARISON_TABLE_HEADER_WIDTH_DESKTOP}
          xLabels={{
            lastMonth: tBillComparisonCardLastMonth,
            lastYear: tBillComparisonCardLastYear,
            thisMonth: tBillComparisonCardThisMonth,
          }}
          yTitle={tBillComparisonCardMonthlykWhUsage}
        />
      </StyledBillComparisonChartContainer>
      <StyledBillComparisonTableContainer>
        <BillComparisonTable comparisonData={data} />
      </StyledBillComparisonTableContainer>
    </StyledBillComparisonCard>
  );
};

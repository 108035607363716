import { IdType } from "@common/types/apiTypes";
import { DeviceBrands } from "@portal-account/types/devicesTypes";
import { ResideoOauthType } from "@portal-account/types/resideoOauthTypes";

export const customerQueryKeys = {
  BASE: ["customers"] as const,
  deviceEligibilityInformation: (customerId: IdType) => [
    ...customerQueryKeys.BASE,
    customerId,
    "deviceEligibilityInformation",
  ],
  getResideoOauthUrl: (deviceBrand: DeviceBrands | null) =>
    [...customerQueryKeys.BASE, deviceBrand] as const,
  listResideoDevices: ({ oauthCode, deviceBrand }: ResideoOauthType) => [
    ...customerQueryKeys.BASE,
    oauthCode,
    deviceBrand,
  ],
  me: () => [...customerQueryKeys.BASE, "me"] as const,
};

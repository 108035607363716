import { useAccountModals } from "@portal-account/components/AccountModalsManager/useAccountModals";
import { usePremiseFromContext } from "@portal-account/components/PremiseProvider/usePremiseFromContext";
import { usePortalCookies } from "@portal-account/hooks/usePortalCookies";
import { useEffect } from "react";

export const useRenewalReminderModal = () => {
  const { premise } = usePremiseFromContext();
  const {
    cookies: { rhRenewalReminderModal },
  } = usePortalCookies();

  const { addModal } = useAccountModals();

  useEffect(() => {
    if (premise.inRenewalWindow && rhRenewalReminderModal === "show") {
      addModal({
        renewalReminder: true,
      });
    }
  }, [addModal, premise, rhRenewalReminderModal]);

  return null;
};

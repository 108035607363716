import { IdType } from "@common/types/apiTypes";
import { invoiceApi } from "@portal-account/api/invoiceApi";
import { invoiceQueryKeys } from "@portal-account/queryKeys/invoiceQueryKeys";
import { useQuery } from "@tanstack/react-query";

interface UseInvoiceTemperatureQueryOptions {
  invoiceId: IdType;
}

export const useInvoiceTemperatureQuery = (
  options: UseInvoiceTemperatureQueryOptions
) => {
  const { invoiceId } = options;

  const query = useQuery({
    queryFn: () => invoiceApi.temperature(invoiceId),
    queryKey: invoiceQueryKeys.temperature(invoiceId),
  });

  return query;
};
